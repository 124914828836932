import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { from, Observable } from 'rxjs';
import { DomainService } from 'src/app/services/domain.service';

@Injectable({
  providedIn: 'root'
})
export class CheckoutResolver implements Resolve<any> {

  constructor(
    public common: DomainService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return from(this.getSetAcountDetails());
  }

  getSetAcountDetails() {
    return new Promise((resolve, reject) => {
      if(!this.common.useriddefault){
        setTimeout(() => {
          this.getSetAcountDetails();
        }, 200);
      }
      this.common.setHeader().finally(() => {
        // console.log('header')
        this.common.getUserAccountDetails(false).then((data) => {
          resolve(data)
        }).catch(err => {
          console.log(err);
          resolve(err)})
      })
    })

  }
}
