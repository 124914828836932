<title> Blogs | SOS</title>

<app-header></app-header>

<div class="container-fluid topPadding">
    <div class="row policyBanner m-0 align-items-center text-center">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <h1 class="bannerText m-2">Blogs</h1>
        </div>
    </div>
    
    <div class="row pt-5">
        <div class="col-md-12 col-lg-12 col-xl-12 text-center pb-5" routerLink="/main-blog">
            <img class="img-fluid" src="assets/images/blog group.png">
        </div>
    </div>
</div>

<app-footer></app-footer>
  