import { Component, OnInit } from '@angular/core';
import { DomainService } from 'src/app/services/domain.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  verifyToken: string;
  newPass: any;
  confirmPass: any;
  show: any;
  show1: any;
  constructor(public common: DomainService,
    public route: ActivatedRoute, public toastr: ToastrService, public router: Router) { }

  ngOnInit() {
    this.route.params.subscribe(routeParams => {
      this.verifyToken = routeParams.token;
      this.verifyPasswordLink();
    });
  }

  showHidePassword() {
    this.show = !this.show;
  }
  showHidePassword1() {
    this.show1 = !this.show1;
  }
  verifyPasswordLink() {
    var data = { 'token': this.verifyToken }
    this.common.verifyLink(data).subscribe(res => {
      this.toastr.success(res['message']);
    }, err => {
      this.toastr.error(err.error['message']);
      if (err.error['status'] == false) {
        this.router.navigate(['/forgot-password']);
      }
    });
  }

  createPassword() {
    if (this.newPass == this.confirmPass) {
      var data = { 'password': this.newPass, 'token': this.verifyToken }
      this.common.resetPassword(data).subscribe(res => {
        this.toastr.success(res['message']);
        this.router.navigate(['/']);
      }, err => {
        this.toastr.error(err.error['message']);
        setTimeout(() => { this.router.navigate(['/forgot-password']) }, 1000);
      });
    } else {
      this.toastr.error("Password does not match")
    }
  }
}
