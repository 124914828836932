import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable, from } from 'rxjs';
import { DomainService } from '../services/domain.service';

@Injectable({
  providedIn: 'root'
})
export class HomeResolverResolver implements Resolve<any> {

  constructor(
    public common: DomainService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return from(this.common.setHeader());
  }
}
