import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { RouterModule } from '@angular/router'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SortByComponent } from './sort-by/sort-by.component';
import { FilterByComponent } from './filter-by/filter-by.component';

import { MatRadioModule } from '@angular/material/radio';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { MobilefilterComponent } from './mobilefilter/mobilefilter.component';
import { ModalModule } from 'ngx-bootstrap/modal';

@NgModule({
  declarations: [HeaderComponent, FooterComponent, SortByComponent, FilterByComponent, MobilefilterComponent],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    MatRadioModule,
    Ng2SearchPipeModule,
    ModalModule.forRoot(),
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    SortByComponent,
    FilterByComponent,
    MobilefilterComponent,
  ]
})
export class SharedModule { }
