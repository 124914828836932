<title> Reset Password | SOS</title>

<app-header></app-header>

<div class="container-fluid topPadding" (click)="common.closeNav()">
    <div class="row policyBanner m-0 align-items-center text-center">
        <div class="col-12">
            <h1 class="bannerText m-2">Reset Password</h1>
        </div>
    </div>

    <div class="row justify-content-center pt-5">
        <div class="col-10 p-4" style="box-shadow: 0px 1px 8px #bbbbbb;">
            <form>
                <div class="form-group">
                    <label for="NPassword">New Password</label>
                    <input  [type]="show ? 'text' : 'password'" class="form-control" [(ngModel)]="newPass" name="NPassword">
                    <div class="form-group my-2">
                        <input type="checkbox" (click)="showHidePassword()"> Show Password
                    </div>
                </div>
                <div class="form-group">
                    <label for="CPassword">Confirm Password</label>
                    <input [type]="show1 ? 'text' : 'password'" class="form-control" [(ngModel)]="confirmPass" name="CPassword">
                    <div class="form-group my-2">
                        <input type="checkbox" (click)="showHidePassword1()"> Show Password
                    </div>
                </div>
                <div class="form-group">
                    <input type="button" class="f-sumbitBtn" value="Submit" (click)="createPassword()">
                </div>
            </form>
        </div>
    </div>
</div>

<app-footer></app-footer>