import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule, Router } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { HttpClientModule } from '@angular/common/http';
import { ShopByBrandComponent } from './shop-by-brand/shop-by-brand.component';
import { BrandComponent } from './brand/brand.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { LazyLoadImageModule, LAZYLOAD_IMAGE_HOOKS, ScrollHooks } from 'ng-lazyload-image';
import { In2NutritionComponent } from './in2-nutrition/in2-nutrition.component';
import { ScullyLibModule } from '@scullyio/ng-lib';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ImageLazyLoadModule } from '../directive/image-lazy-load.module';

const routes: Routes = [
  {
    path: 'shop-by-brands', component: ShopByBrandComponent
  },
  {
    path: 'brand/:slug', component: BrandComponent
  },
  {
    path: 'in2-nutrition', component: In2NutritionComponent
  },
]

@NgModule({
  declarations: [
    ShopByBrandComponent, 
    BrandComponent, 
    In2NutritionComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    SharedModule,
    LazyLoadImageModule,
    HttpClientModule,
    Ng2SearchPipeModule,
    ScullyLibModule,
    ImageLazyLoadModule,
    NgxSpinnerModule
  ],
  exports: [
    RouterModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [{ provide: LAZYLOAD_IMAGE_HOOKS, useClass: ScrollHooks }]
})
export class ShopByBrandModule {
  constructor(private router: Router) {
    this.router.errorHandler = (error: any) => {
      console.log('invalid file/route');
    }
  }
 }
