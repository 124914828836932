import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule, Router } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { HttpClientModule } from '@angular/common/http';
import { AllBlogsComponent } from './all-blogs/all-blogs.component';
import { MainBlogComponent } from './main-blog/main-blog.component';

const routes: Routes = [
  {
    path: 'blogs', component: AllBlogsComponent
  },
  {
    path: 'main-blog', component: MainBlogComponent
  }
]

@NgModule({
  declarations: [
    AllBlogsComponent,
    MainBlogComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    SharedModule,
    HttpClientModule
  ],
  exports: [
    RouterModule,
  ]
})
export class BlogModule { 
  constructor(private router: Router) {
    this.router.errorHandler = (error: any) => {
      console.log('invalid file/route');
    }
  }
}
