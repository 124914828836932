import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule, Router } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { HttpClientModule } from '@angular/common/http';
import { ProteinComponent } from './protein/protein.component';
import { PopularBrandsComponent } from './popular-brands/popular-brands.component';
import { IntraWorkoutsComponent } from './intra-workouts/intra-workouts.component';
import { GainersComponent } from './gainers/gainers.component';
import { PreWorkoutsComponent } from './pre-workouts/pre-workouts.component';
import { VitaminsAndHealthComponent } from './vitamins-and-health/vitamins-and-health.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { SnacksTrendingComponent } from './snacks-trending/snacks-trending.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ImageLazyLoadModule } from '../directive/image-lazy-load.module';

const routes: Routes = [
  {
    path: 'trending-in-protein', component: ProteinComponent
  },
  {
    path: 'popular-brands', component: PopularBrandsComponent
  },
  {
    path: 'trending-in-intra-workouts', component: IntraWorkoutsComponent
  },
  {
    path: 'trending-in-gainers', component: GainersComponent
  },
  {
    path: 'trending-in-pre-workouts', component: PreWorkoutsComponent
  },
  {
    path: 'trending-in-vitamins-and-health', component: VitaminsAndHealthComponent
  },
  {
    path: 'snacks-trending', component: SnacksTrendingComponent
  },
]

@NgModule({
  declarations: [
    ProteinComponent, 
    PopularBrandsComponent, 
    IntraWorkoutsComponent, 
    GainersComponent, 
    PreWorkoutsComponent, 
    VitaminsAndHealthComponent, SnacksTrendingComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    SharedModule,
    HttpClientModule,
    Ng2SearchPipeModule,
    ImageLazyLoadModule,
    NgxSpinnerModule
  ],
  exports: [
    RouterModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class TrendingInModule { 
  constructor(private router: Router) {
    this.router.errorHandler = (error: any) => {
      console.log('invalid file/route');
    }
  }
}
