import { NgModule } from '@angular/core';
import { Routes, RouterModule, Router } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ProductPageComponent } from './product-page/product-page.component';
import { BlogDetaliComponent } from './blog-detali/blog-detali.component';
import { HomeResolverResolver } from './home/home-resolver.resolver';
import { InfluencerComponent } from './influencer/influencer.component';

const routes: Routes = [
  { path: '', loadChildren: () => import('./maintain-page/maintain-page.module').then(mp => mp.MaintainPageModule) },

  // { path: '', component: HomeComponent, resolve: { homeResolverResolver: HomeResolverResolver  }, pathMatch: 'full' },
  // { path: 'product/:slug', component: ProductPageComponent },
  // { path: 'read-blog/:slug', component: BlogDetaliComponent },
  // { path: 'certificates', loadChildren: () => import('./certificate/certificate.module').then(c => c.CertificateModule) },
  // { path: 'policies', loadChildren: () => import('./policies/policies.module').then(p => p.PoliciesModule) },
  // // { path: 'about-us', loadChildren: () => import('./about-us/aboutus.module').then(ab => ab.AboutUsModule) },
  // { path: 'best-sellers', loadChildren: () => import('./combo-offers/combo-offers.module').then(co => co.ComboOffersModule) },
  // { path: 'crazy-deals', loadChildren: () => import('./crazy-deals/crazy-deals.module').then(cd => cd.CrazyDealsModule) },
  // { path: 'hot-deals', loadChildren: () => import('./hot-deals/hot-deals.module').then(cd => cd.HotDealsModule) },
  // { path: 'store-locator', loadChildren: () => import('./store-locator/store-locator.module').then(sl => sl.StoreLocatorModule) },
  // { path: 'partner-with-sos', loadChildren: () => import('./partner-with-sos/partner-with-sos.module').then(pwsos => pwsos.PartnerWithSosModule) },
  // { path: 'sold-out-products', loadChildren: () => import('./sold-out/sold-out.module').then(so => so.SoldOutModule) },
  // { path: 'thankyou', loadChildren: () => import('./thankyou/thankyou.module').then(ty => ty.ThankyouModule) },
  // { path: 'contact-us', loadChildren: () => import('./contact-us/contact-us.module').then(cu => cu.ContactUsModule) },
  // { path: 'newly-launched', loadChildren: () => import('./new-launched/new-launched.module').then(nl => nl.NewLaunchedModule) },
  // { path: 'blogs', loadChildren: () => import('./blog1/blog1.module').then(b1 => b1.Blog1Module) },
  // { path: 'prime-membership', loadChildren: () => import('./prime-membership/prime-membership.module').then(pm => pm.PrimeMembershipModule) },
  // { path: 'checkout-prime-membership', loadChildren: () => import('./checkout-prime-membership/checkout-primemembership.module').then(cpm => cpm.CheckoutPrimemembershipModule) },
  // { path: 'prime-user', loadChildren: () => import('./already-prime-user/already-prime-user.module').then(apu => apu.AlreadyPrimeUserModule) },
  // { path: 'faq', loadChildren: () => import('./faq/faq.module').then(f => f.FAQModule) },
  // { path: 'stack-deals', loadChildren: () => import('./stack-deals/stack-deals.module').then(sd => sd.StackDealsModule) },
  // { path: 'maintenance', loadChildren: () => import('./maintain-page/maintain-page.module').then(mp => mp.MaintainPageModule) },
  // { path: 'sos/:slug', component: InfluencerComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule],
  providers: [HomeResolverResolver]
})
export class AppRoutingModule {
  constructor(private router: Router) {
    this.router.errorHandler = (error: any) => {
      console.log('invalid file/route');
    }
  }

}
