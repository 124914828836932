
<app-header></app-header>


<div class="container-fluid topPadding"  (click)="common.closeNav()">
    <div class="row policyBanner m-0 align-items-center text-center">
        <div class="col-12 mb-1">
            <h1 class="bannerText pt-2">Shop By Brands</h1>
        </div>
    </div>

    <div class="row pt-3">
        <div class="col-6 d-flex justify-content-center" *ngFor="let i of brandgetall">
            <div class="card d-flex h-100 text-center border-0" style="cursor: pointer;width: 133px;" (click)="cardClick(i.id);">
            <div class="full-width-link" [id]="i.id"></div>
            <a href="{{commService.currentHost}}/brand/{{i.slug}}">
                <img width="133" height="133" class="card-img-top text-center img-fluid" src="{{i.brand_image}}" alt="{{i.altText}}" appLazyLoad onerror="this.src='assets/loader/200.gif'">
            </a>
            </div>
        </div>
    </div><br/>
    

    <hr>

</div>

<app-footer></app-footer>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.9)" size="medium" color="#fff" type="ball-atom">
    <p style="color: white"> Please wait... </p>
</ngx-spinner>