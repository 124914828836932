import { Component, OnInit } from "@angular/core";
import { DomainService } from "src/app/services/domain.service";
import { HttpHeaders } from "@angular/common/http";
import { CommonService } from "src/app/services/common.service";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "app-pre-workouts",
  templateUrl: "./pre-workouts.component.html",
  styleUrls: ["./pre-workouts.component.scss"],
})
export class PreWorkoutsComponent implements OnInit {
  trending3: any;
  httpOptions: { headers: HttpHeaders };

  constructor(
    public router: Router,
    public common: DomainService,
    public commService: CommonService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit() {
    this.spinner.show();
    this.producttrending3();
  }

  producttrending3() {
    var data = "all_product=1&shop_by_category=1&categoryId=4&subCategoryId=10";
    this.common.productget(data).subscribe(
      (res) => {
        this.common.sid_sortby = res["data"];
        this.common.filterbyALl(data, "brand");
        this.spinner.hide();
      },
      (err) => {
        this.spinner.hide();
      }
    );
  }

  getbrand(get) {
    get.brand.brand_name = get.brand.brand_name.replace(/ /g, "-");
    this.router.navigate([`/product/${get.slug}`]);
  }

  cardClick(id) {
    if (id) {
      document.getElementById(id).style.display = "block";
      setTimeout(() => {
        document.getElementById(id).style.display = "none";
      }, 300);
    }
  }
}
