<title> Shop by Category | SOS</title>


<app-header></app-header>

<div class="container-fluid topPadding" (click)="common.closeNav()"> 
    <div class="row policyBanner m-0 align-items-center text-center">
        <div class="col-12">
            <h1 class="bannerText m-2">Shop By Category</h1>
        </div>
    </div>


    <div class="row pt-3">
        <div class="col-6 col-sm-6 col-md-4 col-lg-3 mt-3 " *ngFor="let i of catShop">
            <div class="card d-flex h-100 border-0" (click)="catNameID(i)">
                <div class="card-body p-0 text-center">
                <img width="120" height="120" class="card-img-top img-fluid" src="{{i.category_image}}" alt="Card image cap" appLazyLoad onerror="this.src='assets/loader/200.gif'">
                </div>
            </div>
        </div>

    </div>

</div>

<app-footer></app-footer>