<nav class="navbar sticky-top navbar-light bg-light-sale" *ngIf="this.event_header">
	<div class="d-flex w-100 justify-content-center h-10">
		<span class="font-weight-bold align-middle">{{this.event_header}}</span>
	</div>
</nav>
<nav class="navbar sticky-top navbar-light bg-light">
	<div class="d-flex w-100 justify-content-sm-between justify-content-between ">

		<button class="navbar-toggler border-0" type="button" data-toggle="collapse" data-target=""
			aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
			(click)="openNav()">
			<span class="navbar-toggler-icon"></span>
		</button>


		<a class="navbar-brand" routerLink="/" id="logoImg">
			<img width="278" height="64" src="assets/images/Logo-Mobile.PNG" class="img-fluid" alt="logo"
				title="logo"></a>


		<div id="mySidenav" class="sidenav">
			<a class="closebtn" (click)="closeNav()">&times;</a>
			<div *ngIf="!common.userdetails" class="usercontain">
				<a class="loginLink" routerLink="/login-register">Login</a>
				<span style="color: white;font-size: 1.5rem;padding: 0px 10px;">/</span>
				<a class="loginLink" routerLink="/login-register">Register</a>
				<span class="d-block mb-5"></span>
			</div>

			<span class="nav-item dropdown" class="usercontain" *ngIf="common.userdetails">

				<!-- <span class="badge badge-pill badge-danger custom-css mb-0 mt-2 ml-2"
					*ngIf="common.userdetails.isPrimeMember">
					Prime
				</span> -->

				<a (click)="showOption()">Hello, {{common.userdetails.name}}&nbsp;&nbsp;&nbsp;
					<i class="fa fa-caret-down"></i>
				</a>
				<ul class="listCat" *ngIf="accMenu" style="padding: 0px 0px 15px 30px;">
					<li><a class="catLink" routerLink="/my-account"><span>My Account</span></a></li>
					<li><a class="catLink" (click)="logout()"><span>Logout</span></a></li>
				</ul>
			</span>

			<a class="redBtn" routerLink="/"><span>Home</span></a>
			<a routerLink="/shop-by-brands"><span>Shop By Brands</span></a>
			<a routerLink="/best-sellers"><span>Best Sellers</span></a>
			<a routerLink="/crazy-deals"><span>Crazy Deals</span></a>
			<!-- <a routerLink="/hot-deals"><span>Hot Deals</span></a> -->

			<!-- <a routerLink="/prime-membership"
				*ngIf="(common.userdetails == undefined || common.userdetails == null) || (common.userdetails && common.userdetails.isPrimeMember == false)">SOS
				Prime Membership</a>

			<a routerLink="/prime-user" *ngIf="common.userdetails && common.userdetails.isPrimeMember == true">SOS Prime
				Membership</a> -->

			<a routerLink="/brand/in2-nutrition" style="color: #c8a34b;font-size: 18px;"><span> <img
						style="width: 15%;" src="assets/images/64-64.png" alt=""> Nutrition</span>
			</a>

			<ul class="listCat">
				<!-- <li *ngFor="let i of catShop;let index=index">
					<a style="padding: 8px 8px 5px 25px!important;margin-right: 60px;"
						(click)="catNameID(i)">{{i.categoryName}}</a>
					<i *ngIf="!subMenu[index]" class="fa fa-plus iconCAT" aria-hidden="true"
						(click)="showSubMenu(index)"></i>
					<i *ngIf="subMenu[index]" class="fa fa-minus iconCAT" aria-hidden="true"
						(click)="hideSubMenu(index)"></i>
					<ul style="padding: 0 0 30px 0;" *ngIf="subMenu[index]">
						<li>
							<a class="subcatLink" *ngFor="let j of i.subCategories"
								(click)="getsubCatID(j.slug)">{{j.subCategoryName}}</a>
						</li>
					</ul>
				</li> -->


				<li *ngFor="let i of catShop;let index=index">
					<a href="{{commService.currentHost}}/category/{{i.slug}}"
						style="padding: 8px 8px 5px 25px!important;margin-right: 60px;"
						(click)="catNameID(i)"><span>{{i.categoryName}}</span></a>

					<i *ngIf="!subMenu[index]" class="fa fa-plus iconCAT" aria-hidden="true"
						(click)="showSubMenu(index)"></i>

					<i *ngIf="subMenu[index]" class="fa fa-minus iconCAT" aria-hidden="true"
						(click)="hideSubMenu(index)"></i>

					<ul style="padding: 0 0 30px 0;" *ngIf="subMenu[index]">
						<li>
							<a href="{{commService.currentHost}}/sub-category/{{j.slug}}"
								class="subcatLink" *ngFor="let j of i.subCategories"
								(click)="getsubCatID(j.slug)"><span>{{j.subCategoryName}}</span></a>
						</li>
					</ul>
				</li>

			</ul>

			<!-- <a routerLink="/store-locator">Store Locater</a>
			<a routerLink="/partner-with-sos">Partner With SOS</a> -->
			<!-- <a routerLink="/about-us"><span>About Us</span></a> -->
			<a routerLink="/contact-us"><span>Contact Us</span></a>
			<a routerLink="/certificates"><span>Certificates</span></a>
		</div>


		<i class="fa fa-shopping-cart cartIconMobile" (click)="gotocart()">
			<span class="badge badge-light" *ngIf='common.counterproduct'>{{common.counterproduct}}</span>
		</i>
	</div>

	<div class="row m-0 notForLargeDevices mt-0" id="searchbarWidth">
		<div class="col-md-12 ">
			<div id="searchboxoverlayMobile"></div>
			<input class="form-control form-control-sm  mr-3 searchBoxMobile" type="text" placeholder="Search"
				aria-label="Search" (click)="searchBybrandsMobile()" autocomplete="off" name="hidden">
			<div class="p-4 pb-5" id="searchboxMo">

				<div class="row p-2">
					<div class="col-10">
						<input class="form-custom-control border-bottom" type="text" placeholder="Search"
							aria-label="Search" id="getvalueMobile" (keyup)="getsearchMobile()"
							name="hidden" autocomplete="off">
					</div>
					<div class="col-2">
						<button type="button" class="btn btn-light border-0" (click)="closeSearchBox()"
							style="padding: unset;">
							<i class="fa fa-times"></i>
						</button>
					</div>
				</div>

				<div class="row m-0 pb-3 align-items-center searchover mb-2" *ngFor="let i of resultsearch"
					(click)="gotoproductpageMobile(i)">

					<div class="col-3">
						<img src="{{i.productVariantThumbnail}}" style="width: 68px;height: 68px;">
					</div>
					<div class="col-9">
						<p class="pt-2 m-0">{{i.variantName}}</p>
						<p class="card-text">
							<del style="color: black;"><b>₹{{i.variantPrice}}</b></del>&nbsp;
							<b style="color: #d31d28;">₹{{i.variantSalePrice}}</b>
							<span class="d-block"></span>
						</p>
						<p *ngIf="!i.activeVariant" class="soldOut">Sold Out</p>
					</div>
				</div>
				<div class="row m-0 searchover hover" *ngFor="let f of resultsearchBrandMobile"
					(click)="getbrand(f.slug)">

					<div class="col-3">
						<img src="{{f.brand_image}}" class="img-fluid p-2 cursor">
					</div>
					<div class="col-9">
						<p class="pt cursor">{{f.brand_name}}</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</nav>