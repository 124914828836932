<title> Popular Brands | SOS</title>

<app-header></app-header>

<div class="container-fluid topPadding" (click)="common.closeNav()">
  <div class="row policyBanner m-0 align-items-center text-center">
    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <h1 class="bannerText m-2">Popular Brands</h1>
    </div>
  </div>

  <div class="row pt-5">
    <div class="col-6" *ngFor="let i of pop1">
      <div class="card d-flex h-100 align-items-center border-0" style="cursor: pointer;" (click)="cardClick(i.id);">
        <div class="full-width-link" [id]="i.id"></div>
        <a href="{{commService.currentHost}}/brand/{{i.slug}}">
          <img width="133" height="133" class="img-fluid" src="{{i.brand_image}}">
        </a>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>