import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DomainService } from '../services/domain.service';
import { ToastrService } from 'ngx-toastr';
import { HttpHeaders } from '@angular/common/http';
import { CommonFunctions } from '../common.function';
import { CommonService } from '../services/common.service';
import { ScullyRoutesService } from '@scullyio/ng-lib';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { SwiperOptions } from 'swiper';
import { NgxSpinnerService } from 'ngx-spinner';

declare var $: any;
declare var gtag_view_item: Function;
declare var gtag_add_to_cart: Function;


@Component({
  selector: 'app-product-page',
  templateUrl: './product-page.component.html',
  styleUrls: ['./product-page.component.scss']
})
export class ProductPageComponent implements OnInit {

  config: SwiperOptions = {
    slidesPerView: 1,
    observer: true,
    observeParents: true,
    pagination: {
      el: '.swiper-pagination',
    },
  };

  commonConfig: SwiperOptions = {
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    autoplay: true,
    spaceBetween: 10,
    slidesPerView: 2.40,
    preloadImages: false,
    pagination: {
      clickable: true,
    },
    breakpoints: {
      640: {
        slidesPerView: 2.40,
        spaceBetween: 20,
      },
      768: {
        slidesPerView: 2.40,
        spaceBetween: 40,
      },
      1024: {
        slidesPerView: 2.40,
        spaceBetween: 20,
      },
      1920: {
        slidesPerView: 2.40,
        spaceBetween: 12,
      },
      2560: {
        slidesPerView: 2.40,
        spaceBetween: 12,
      },
    },
    observer: true,
    observeParents: true,
  }

  @ViewChild('rateProductPopUp') rateProductPopUp: ModalDirective;
  @ViewChild('rateProductEditPopUp') rateProductEditPopUp: ModalDirective;

  public particularID: string;
  public slug: string;
  public variantName: any;
  public productImage: any;
  public variantPrice: any;
  public variantSalePrice: any;
  public productDescription: any;
  public imgbox: any
  httpOptions: { headers: HttpHeaders; };
  getflavour: any;
  getproductfetch: any;
  similar_product: any;
  productVariantPictures: any;

  metaTagData$ = this.srs.getCurrent();
  mySwiper: any;

  allCustomersReviews: any[] = [];

  isReviewAlreadyExits: boolean = true;

  rate: any;
  description: any;
  editReviewArray: any[] = [];
  editDescription: any;
  editId: any;

  editRate: any;
  totlaCountRating: any;

  overallCounting: any[] = [];
  pageNo = 0;

  bannerOne: any;
  bannerTwo: any;
  brandProducts: any = [];
  brands: any = [];

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public common: DomainService,
    private toastr: ToastrService,
    public commService: CommonService,
    private srs: ScullyRoutesService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    this.spinner.show();
    this.common.setHeader().finally(() => {
      this.route.params.subscribe(routeParams => {
        this.slug = routeParams.slug;
      });
      this.getProductId();
    });

    this.getBannerImages();
    this.getAllBrands();
  }

  getBannerImages() {
    this.common.bannergetvalue().subscribe(res => {
      this.bannerOne = res['data'][9];
      this.bannerTwo = res['data'][10];
      // console.log(this.bannerOne, this.bannerTwo);
    });
  }

  getProductId() {
    // this.common.setHeader().finally(() => {
    this.common.particularProduct(this.slug).finally(() => {
      try {
        //console.log(this.common.getproductfetch.id);
        this.particularID = this.common.getproductfetch.id;

        if (this.commService.productSEO.length == 0) {
          this.commService.productSEOGet().finally(() => {
            this.productSEO();
          });
        } else {
          this.productSEO();
        }
        // console.log(this.common.getproductfetch);
        gtag_view_item(this.common.getproductfetch);
        this.common.fbEvent(3, { contents: [{ id: this.common.getproductfetch.id, quantity: 1 }] });

        this.common.flavour(this.particularID);
        this.getReviewRating();
        this.getReviewCountById();
        this.spinner.hide();
      } catch (error) {
        this.spinner.hide();
        this.router.navigate(["/"]);
      }
    });
    // });

  }

  getReviewRating() {
    this.pageNo = 0;
    this.common.getReviewRating(this.particularID).subscribe(res => {
      this.allCustomersReviews = res['data'];

      if (this.common['userdetails'] != undefined) {
        this.allCustomersReviews.forEach(ele => {
          if (ele.clientId === this.common.userdetails.userID) {
            this.isReviewAlreadyExits = false;
          }
        })
      }
    });
  }

  loadMoreReview() {
    this.pageNo = this.pageNo + 1;
    this.common.getReviewRatingForLoadMore(this.particularID, this.pageNo).subscribe(res => {

      if (this.pageNo == 1) {
        this.allCustomersReviews = [];
      }

      if (res['data'].length != 0) {
        res['data'].forEach(element => {
          this.allCustomersReviews.push(element);
          if (this.common['userdetails'] != undefined) {
            if (element.clientId === this.common.userdetails.userID) {
              this.isReviewAlreadyExits = false;
            }
          }
        });
      }
    })
  }

  getReviewCountById() {
    let data = { productVariantId: this.particularID }
    this.common.getReviewCountByProductId(data).subscribe(res => {
      this.overallCounting = [];
      this.overallCounting.push(res);
      this.totlaCountRating = res['CountRating'];
    });
  }

  productSEO() {
    let productIndex = CommonFunctions.findKeyValueIndex(this.commService.productSEO, 'id', this.particularID);
    if (productIndex) {
      this.commService.updateMetaTages(this.commService.productSEO[productIndex]['productName'], [
        { name: 'og:title', content: this.commService.productSEO[productIndex]['productName'] },
        { name: 'title', content: this.commService.productSEO[productIndex]['productName'] },
        { name: 'og:description', content: this.commService.productSEO[productIndex]['shortDescription'] },
        { name: 'description', content: this.commService.productSEO[productIndex]['shortDescription'] },
        { name: 'og:image', content: this.commService.productSEO[productIndex]['productThumbnail'] },
        { name: 'image', content: this.commService.productSEO[productIndex]['productThumbnail'] },
        { name: 'og:image:secure_url', content: this.commService.productSEO[productIndex]['productThumbnail'] },
      ]);
    } else {
      this.commService.updateMetaTages(this.common.getproductfetch['variantName'], [
        { name: 'og:title', content: this.common.getproductfetch['variantName'] },
        { name: 'title', content: this.common.getproductfetch['variantName'] },
        { name: 'og:description', content: '' },
        { name: 'description', content: '' }
      ]);
    }

    this.commService.createCanonicalURL();
  }

  /* productSEOGet() {
    return new Promise((resolve, reject) => {
      this.common.productSEOget().subscribe(res => {
        for (let index = 0; index < res['data'].length; index++) {
          const ele = res['data'][index];
          this.commService.productSEO.push({
            id: ele.id,
            productName: ele.meta_title,
            shortDescription: ele.shortDescription,
            productThumbnail: (ele.productVariantPictures.length > 0) ? ele.productVariantPictures[0].productVariantThumbnail : 'https://sourceofsupplements.com/assets/icons/SOS-Favicon.png;'
          })
        }
        resolve(true);
      }, err => {
        resolve(false);
      });
    });
  } */

  addtocart(getcartid, slug) {
    let getflavour = $('#getflavour').val();
    var data = { 'quantity': 1, 'productVariantId': getcartid, 'productFlavour': getflavour, 'deviceIdentifier': this.common.useriddefault };
    this.common.cartadd(data, this.httpOptions).subscribe(async (res: any) => {
      /* Gtag addtocart */
      await this.common.particularProduct(slug);
      let gtagAddCardData = {
        value: this.common.getproductfetch.variantSalePrice,
        items: [{
          item_id: this.common.getproductfetch.productSKU,
          item_name: this.common.getproductfetch.variantName,
          discount: (this.common.getproductfetch.variantPrice - this.common.getproductfetch.variantSalePrice),
          price: this.common.getproductfetch.variantPrice,
          quantity: this.common.getproductfetch.quantity
        }]
      };
      gtag_add_to_cart(gtagAddCardData);
      /* Gtag addtocart End */

      setTimeout(() => {
        this.common.fbEvent(0, { contents: [{ id: getcartid, quantity: data.quantity }] });

        this.common.cartdetails = res['data'];
        // console.log(this.common.cartdetails);
        this.common.GetCartDetails();
        // this.toastr.success(res['message']);
        this.router.navigate(['/cart']);
      }, 30);
    }, err => { });
  }

  similarproduct(get) {
    get.brand.brand_name = get.brand.brand_name.replace(/ /g, "-");
    this.router.navigate([]).then(result => { window.open(`/product/${get.slug}`, '_blank'); });
    this.common.particularProduct(get.slug);
    this.common.flavour(get.id);
  }

  RedirectCategory() {
    let slug = this.common.getproductfetch.category.slug;
    this.router.navigate([`category/${slug}`])
  }

  RedirectBrand() {
    let slug = this.common.getproductfetch.brand.slug;
    this.router.navigate([`brand/${slug}`])
  }

  getimagetoshow(getimg) {
    this.common.imgbox = getimg;
  }

  onRatingSet(rating: number) {
    this.rate = rating;
  }

  submitReview() {
    if (this.rate) {
      var data = {
        ratingStar: this.rate,
        productVariantId: this.particularID,
        status: 1,
        reviewDescription: this.description
      }

      this.common.addReview(data, this.common.httpOptions).subscribe(res => {
        if (res['message'] == 'Product Review has been Inserted Successfully') {
          this.toastr.success('Product Review Added Successfully!');
          this.getReviewRating();
          this.getReviewCountById();
          this.rateProductPopUp.hide();
        } else if (res['message'] == 'Product Review already inserted') {
          this.toastr.error('Your Review Already Exist!');
        }
      }, err => {
        this.toastr.error('Something Went Wrong!');
      })
    } else {
      this.toastr.error('Field is required');
    }
  }

  editReview(obj) {
    this.editReviewArray = [];
    this.editReviewArray.push(obj);
    this.editDescription = obj.reviewDescription;
    this.editId = obj.id;
    this.rateProductEditPopUp.show();
  }

  updateReview() {
    var data = { status: 1, reviewDescription: this.editDescription, id: this.editId, ratingStar: this.editRate }

    this.common.updateReview(data, this.common.httpOptions).subscribe(res => {
      if (res['message'] == 'Updated Review Successfully') {
        this.toastr.success('Product Review Update Successfully!');
        this.getReviewRating();
        this.rateProductEditPopUp.hide();
      }
    });
  }

  reviewRatingOpenPopup() {
    if (this.common['userdetails'] == undefined) {
      this.toastr.error('Login Required');
    } else {
      this.rateProductPopUp.show();
    }
  }

  onRatingEditSet(rating: number) {
    this.editRate = rating;
  }

  cardClick(id) {
    if (id) {
      document.getElementById(id).style.display = "block";
      setTimeout(() => {
        document.getElementById(id).style.display = "none";
      }, 300);
    }
  }

  bannerClick(id, link) {
    var data = {
      id: id
    }
    this.common.bannergetclick(data, this.common.httpOptions).subscribe(res => {
      window.open(link, '_blank');
    })
  }

  getAllBrands() {
    this.common.brandget('').subscribe(res => {
      this.brands = res['data'];
      // console.log(this.brands);
      this.getProductByBrand("Optimum Nutrition");
      this.getProductByBrand("Muscle Tech");
      this.getProductByBrand("Bubble Up Nutrition");
      this.getProductByBrand("Beyond Gummies");
      this.getProductByBrand("IN2 Nutrition");
      this.getProductByBrand("Labrada");
      this.getProductByBrand("bpi Sports");
      this.getProductByBrand("One Science");
      this.getProductByBrand("Source Of Supplements");
      // this.getProductByBrand("SOS");
    }, err => { });
  }

  getProductByBrand(name) {
    let fdata = this.brands.filter(f => f.brand_name.toLowerCase().includes(name.toLowerCase()))
    // console.log(fdata, name);
    if (fdata.length > 0) {
      this.common.productBySKUget('all_product=1&shop_by_brand=1&brandId=' + fdata[0].id).subscribe((res: any) => {
        this.brandProducts.push({ brandid: res['data'][0].brand, data: res['data'] });
        // console.log(this.brandProducts);
      }, err => { console.log(err) });
    }
  }

  findBrandByName(name) {
    let a = this.brandProducts.filter(f => f.brandid.brand_name.toLowerCase().includes(name.toLowerCase()));
    // console.log(id, a[0])
    return a
  }

  routingBrands(slug) {
    let url = window.location.origin + `/brand/${slug}`;
    window.open(url, "_blank");
  }

}
