import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DomainService } from 'src/app/services/domain.service';
import { CommonService } from 'src/app/services/common.service';
import { ScullyRoutesService } from '@scullyio/ng-lib';

@Component({
  selector: 'app-in2-nutrition',
  templateUrl: './in2-nutrition.component.html',
  styleUrls: ['./in2-nutrition.component.scss']
})
export class In2NutritionComponent implements OnInit {
  public sortBy: boolean;
  public filter: boolean;

  metaTagData$ = this.srs.getCurrent();

  constructor(public router: Router, public commService: CommonService, public route: ActivatedRoute, public common: DomainService,
    private srs: ScullyRoutesService
  ) { }

  ngOnInit() {
    this.commService.updateMetaTages('IN2 Nutrition, IN2 Whey Protein, Mass Gainers & More Online at SOS', [
      { name: 'og:title', content: 'IN2 Nutrition, IN2 Whey Protein, Mass Gainers & More Online at SOS' },
      { name: 'og:description', content: 'Buy IN2 Nutrition Supplements at best and lowest prices in India.  Shop IN2 Whey Protein, Mass Gainer, Bodybuilding Supplements. Find calories, carbs, and nutritional contents for in2.' },
      { name: 'description', content: 'Buy IN2 Nutrition Supplements at best and lowest prices in India.  Shop IN2 Whey Protein, Mass Gainer, Bodybuilding Supplements. Find calories, carbs, and nutritional contents for in2.' },
      { name: 'og:image', content: 'https://sourceofsupplements.com/assets/icons/SOS-Favicon.png' },
      { name: 'image', content: 'https://sourceofsupplements.com/assets/icons/SOS-Favicon.png' },
    ]);
    this.commService.createCanonicalURL();

    this.brandget();
  }

  showFilterBox() {
    this.filter = this.filter ? false : true;
    this.sortBy = false;
  }
  showSortBy() {
    this.sortBy = this.sortBy ? false : true;
    this.filter = false;
  }

  brandget() {
    /* var data = 'all_product=1&shop_by_brand=1&brandId=' + 25;
    this.common.filterbyALl(data, 'brand'); */
    let data = `slug=in2-nutrition`;
    this.common.brandget(data).subscribe(res => {
      //console.log(res);
      if (res && res['data']) {
        data = 'all_product=1&shop_by_brand=1&brandId=' + res['data'][0].id;
        this.common.filterbyALl(data, 'brand');
      }
    });

  }
  getbrand(get) {
    get.brand.brand_name = get.brand.brand_name.replace(/ /g, "-");
    this.router.navigate([`/product/${get.slug}`]);
  }

  cardClick(id){    
    if(id){
      document.getElementById(id).style.display = "block";
      setTimeout(() => {
        document.getElementById(id).style.display = "none";
      }, 300);
    }
  }

}
