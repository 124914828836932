import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs';
import { StorageService as newStorage } from './newstorage.service';
import { CommonFunctions } from '../common.function';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
declare var fbq: any;
declare var gtag_add_to_cart: Function;

interface FBContent {
  amount?: any,
  contents: [
    {
      id: string,
      quantity: any
    }
  ]
}

@Injectable({
  providedIn: 'root'
})

export class DomainService {

  public addtoCart = new Subject<any>();
  private codeventCallback = new Subject<string>(); // Source
  public codeventCallback$ = this.codeventCallback.asObservable(); // Stream
  public profile: any;
  public useriddefault: any;
  public cartdetails: any;
  public userdetails: any;
  public userDetailsObserver = new Subject<any>();
  public loginget: any = 'login';
  public guestdetail: any;
  public counterproduct: any;
  public getpromocode: any = 0;

  public sid_sortby: any;
  public sid_sortbyvalue: any;
  public newsLetter: any;
  public newsshow: any;
  public filterby: any

  public showbrand: any;
  public showcat: any;

  public getcatevalue: any
  public subgetcatevalue: any
  public sortbycat: any;
  public brandByCategory: any = [];

  public httpOptions: { headers: HttpHeaders }
  public productSlider: any;
  public getbrandvalue: any;
  public selectedCatID: string;
  public selectedBrandData: any;
  public selectedCategoryData: any;
  public flavourName: any;

  public loader: boolean = false;
  public getproductfetch: any;
  public productVariantPictures: any;
  public imgbox: any;
  public similar_product: any;
  public flavourID: any;
  public websiteConfig: any;
  public eventsList = ['AddToCart', 'InitiateCheckout', 'Purchase', 'ViewContent', 'Search', 'CompleteRegistration'];
  

  // Test PayU key and Salt
  // payUKey: any = "GjfoO8";
  // payUSaltKey: any = "4aQmYcMbcuPqTsefjbOHUORsxGtUO4ta";

  // Production PayU key and Salt
  payUKey: any = "TiuXoB";
  payUSaltKey: any = "nBGnU2ViAAG7SssqBLqOxWcv6CmjxMyI";

  // public domain: any = "https://sos.test-central.kenmarkserver.com/";
  public domain: any = "https://api.sourceofsupplements.com/";
  // public domain: any = "http://test-api.sourceofsupplements.com/";
  // public domain: any = "https://sos-cod-api.dc.kenmarkserver.com/";

  // public domain: any = "http://localhost:5008/";
  // public domain: any = "https://api.shopsos.in/";


  constructor(public http: HttpClient, public newStorage: newStorage, public toastr: ToastrService, public router: Router) {
    this.userDetailsObserver.subscribe(res => {
      this.userdetails = res;
      this.newStorage.deleteItem('Guestdetails');
      this.newStorage.updateItem('userdetails', res).finally(() => {
        if (!res['name']) { this.getUserAccountDetails(true); }
      });
    });
  }

  /* 🚀 Login With Mobile and OTP */

  getOtpViaMobile(data) {
    return this.http.post(this.domain + 'client/check', data);
  }

  loginOrRegisterViaMobile(data) {
    return this.http.post(this.domain + 'client/login/v1', data);
  }

  saveOrUpdateMyAccount(data, header) {
    return this.http.put(this.domain + 'client/update', data, header);
  }

  getUserMyAccountDetails(data, header) {
    return this.http.get(this.domain + 'client/myaccounts' + data, header);
  }

  /* 🚀 /Login With Mobile and OTP */

  removeCoupanCode(data) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      }),
      body: data
    }
    return this.http.delete(this.domain + 'client/remove-coupan', options)
  }

  signUp(data) {
    return this.http.post(this.domain + 'client/signup', data)
  }

  login(data) {
    return this.http.post(this.domain + 'client/login', data)
  }

  userupdate(data) {
    return this.http.put(this.domain + 'user', data)
  }

  silderget() {
    return this.http.get(this.domain + 'client/slider')
  }

  slidergetclick(data, header) {
    return this.http.put(this.domain + 'client/sliderCount', data, header)
  }

  bannergetclick(data, header) {
    return this.http.put(this.domain + 'client/bannerCount', data, header)
  }

  bannergetvalue() {
    return this.http.get(this.domain + 'client/banner')
  }

  productget(data) {
    return this.http.get(this.domain + 'client/trending?' + data)
  }

  productBySKUget(data) {
    return this.http.get(this.domain + 'client/skusorting/products?' + data)
  }

  productCrazyDealsGet(data) {
    return this.http.get(this.domain + 'client/crazyDeals?' + data)
  }

  productHotDealsGet(data) {
    return this.http.get(this.domain + 'client/hotDeals?' + data)
  }

  productBestSellerGet(data) {
    return this.http.get(this.domain + 'client/bestSeller' + data)
  }

  showincart() {
    return this.http.get(this.domain + 'client/showInCart')
  }

  soldoutProductget(data) {
    return this.http.get(this.domain + 'client/soldoutProducts?' + data)
  }

  productSEOget() {
    return this.http.get(this.domain + 'client/seo/product')
  }

  newlyget() {
    return this.http.get(this.domain + 'client/newlyLunched')
  }

  stackDeals() {
    return this.http.get(this.domain + 'client/stackDeals');
  }

  brandget(data) {
    return this.http.get(this.domain + 'client/brand?' + data)
  }

  brandSEOget() {
    return this.http.get(this.domain + 'client/seo/brands')
  }

  blogSEOget() {
    return this.http.get(this.domain + 'client/seo/blogs')
  }

  guestlogin(data) {
    return this.http.post(this.domain + 'client/guest', data)
  }

  partnerSOS(data) {
    return this.http.post(this.domain + 'client/franchise ', data)
  }

  cartget(data, header) {
    return this.http.get(this.domain + 'client/cart?' + data, header)
  }

  getPrepaidDiscount() {
    return this.http.get(this.domain + 'client/prepaid-discount', this.httpOptions)
  }

  cartadd(data, header) {
    return this.http.post(this.domain + 'client/cart', data, this.httpOptions)
  }

  cartupdate(data, header) {
    return this.http.put(this.domain + 'client/cart', data, header)
  }

  cartdelete(data) {
    return this.http.delete(this.domain + 'client/cart', data)
  }

  subscribeNL(data) {
    return this.http.post(this.domain + 'client/subscription', data)
  }

  shopbyCat(data = '') {
    return this.http.get(this.domain + 'client/category' + data)
  }

  categorySEOget() {
    return this.http.get(this.domain + 'client/seo/categories')
  }

  storelocator(data) {
    return this.http.get(this.domain + 'client/store-locator', data)
  }

  addaddress(data, header) {
    return this.http.post(this.domain + 'client/address', data, header)
  }

  getaddress(data, header) {
    return this.http.get(this.domain + 'client/address' + data, header)
  }

  putaddress(data, header) {
    return this.http.put(this.domain + 'client/address', data, header)
  }

  deleteaddress(header) {
    return this.http.delete(this.domain + 'client/address', header)
  }

  addorder(data, header) {
    return this.http.post(this.domain + 'client/order', data, header)
  }

  addIthink(data, header) {
    return this.http.post(this.domain + 'client/ithinkOrder?orderid=' + data, null, header)
  }

  guestaddIthink(data, guest_id) {
    return this.http.post(this.domain + 'client/ithinkOrder?orderid=' + data + '&guestId=' + guest_id, null)
  }

  getorder(header) {
    return this.http.get(this.domain + 'client/order', header)
  }

  orderstatus(data, header) {
    return this.http.put(this.domain + 'client/order-status', data, header)
  }

  getsearch(data) {
    return this.http.get(this.domain + 'client/search/product?' + data)
  }

  getsearchbyBrand(data) {
    return this.http.get(this.domain + 'client/search/brand?limit=8' + data)
  }

  savesearch(data) {
    return this.http.post(this.domain + 'client/search', data, this.httpOptions)
  }

  profileupdate(data) {
    return this.http.put(this.domain + 'client/profile', data, this.httpOptions)
  }

  profilepass(data) {
    return this.http.put(this.domain + 'client/profile/password', data, this.httpOptions)
  }

  couponcode(data) {
    return this.http.get(this.domain + 'client/coupan-code?' + data, this.httpOptions)
  }

  verifycouponcode(data) {
    return this.http.put(this.domain + 'client/verify-coupan-code', data, this.httpOptions)
  }

  repeatorder(data) {
    return this.http.post(this.domain + 'client/reorder', data, this.httpOptions)
  }

  filter_by(data) {
    return this.http.get(this.domain + 'client/filter?' + data)
  }

  productFlavour(data) {
    return this.http.get(this.domain + 'client/product-flavour?' + data)
  }

  similarProduct(data) {
    return this.http.get(this.domain + 'client/related-product?' + data)
  }

  resetLink(data) {
    return this.http.post(this.domain + 'client/reset-link', data)
  }

  verifyLink(data) {
    return this.http.post(this.domain + 'client/verify-link', data)
  }

  resetPassword(data) {
    return this.http.post(this.domain + 'client/reset-password', data)
  }

  subCatNav() {
    return this.http.get(this.domain + 'client/nav')
  }

  subCatProduct(data) {
    return this.http.get(this.domain + 'client/subCategory?' + data)
  }

  suCatSEOget() {
    return this.http.get(this.domain + 'client/seo/subcategory')
  }

  mobileNav(data) {
    return this.http.get(this.domain + 'client/nav_mobile?' + data)
  }

  pincode(data) {
    return this.http.post(this.domain + 'client/pincode', data)
  }

  OTP(data) {
    return this.http.post(this.domain + 'client/otp', data)
  }

  verifyOTP(data) {
    return this.http.post(this.domain + 'client/otp-verify', data)
  }

  blogsget() {
    return this.http.get(this.domain + 'client/blog')
  }

  getblogDetail(data) {
    return this.http.get(this.domain + 'client/blogs?slug=' + data)
  }

  newsLetterSubscribe(data) {
    return this.http.post(this.domain + 'client/newsletter', data, this.httpOptions)
  }

  getFAQQuestion() {
    return this.http.get(this.domain + 'client/faq', this.httpOptions);
  }

  // Review and Rating

  getReviewRating(productVariantId) {
    return this.http.get(this.domain + 'client/review/' + productVariantId)
  }

  addReview(data, header) {
    return this.http.post(this.domain + 'client/review', data, header)
  }

  updateReview(data, header) {
    return this.http.put(this.domain + 'client/review', data, header)
  }

  getReviewCountByProductId(data) {
    return this.http.post(this.domain + 'client/review/ratingcount', data)
  }

  getReviewRatingForLoadMore(productVariantId, pageNo) {
    return this.http.get(this.domain + 'client/review/' + productVariantId + '?page_number=' + pageNo)
  }

  /* SOS Prime Pricing Plan */
  getSOSPrimePricingPlan(data) {
    return this.http.get(this.domain + 'client/sosPrimeProduct', data)
  }

  /* Create Prime Order API */
  createPrimeOrder(data, header) {
    return this.http.post(this.domain + 'client/sosPrimeOrder', data, header);
  }
  /* Create Prime Order API */

  /* Prime Coupon Code */
  getCouponCodeAmount(data, header) {
    return this.http.post(this.domain + 'client/sosPrimeCoupan', data, header)
  }
  /* Prime Coupon Code */

  /* Influencer */
  getRedirectURLForInfluncer(data) {
    return this.http.post(this.domain + 'client/influencer/' + data, '');
  }
  /* /Influencer */

  // Sort by 
  sidsortfunction() {
    // sid sort method start
    if (this.sid_sortbyvalue == 'low') {
      this.sid_sortby.sort(function (a, b) { return a.variantSalePrice - b.variantSalePrice; })
    }
    else if (this.sid_sortbyvalue == 'high') {
      this.sid_sortby.sort(function (a, b) { return b.variantSalePrice - a.variantSalePrice; })
    }
    else if (this.sid_sortbyvalue == 'A') {
      this.sid_sortby.sort(function (a, b) {
        var nameA = a.variantName.toUpperCase(); // ignore upper and lowercase
        var nameB = b.variantName.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        // names must be equal
        return 0;
      });
    }
    else if (this.sid_sortbyvalue == 'Z') {
      this.sid_sortby.reverse(function (a, b) {
        var nameA = a.variantName.toUpperCase(); // ignore upper and lowercase
        var nameB = b.variantName.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        // names must be equal
        return 0;
      });
    }
    // sid sort method end
  }

  //start particular product
  particularProduct(slug) {
    return new Promise((resolve, reject) => {
      let data = 'slug=' + slug;
      this.productget(data).subscribe(res => {
        if (res['data'].length > 0) {
          this.getproductfetch = res['data'][0];
          this.productVariantPictures = this.getproductfetch.productVariantPictures;
          this.imgbox = this.getproductfetch.productVariantPictures[0].productVariantPhoto;
          this.relatedProduct();
        }
        resolve(true);
      }, err => {
        resolve(false);
      });
    });
    //end particular product 
  }

  //   related Product
  relatedProduct() {
    var data = 'related_product=' + this.getproductfetch.related_product + '&categoryId=' + this.getproductfetch.category.categoryId;
    this.similarProduct(data).subscribe(res => {
      this.similar_product = res['data'];
    }, err => {
      console.log(err.error);
    });
  }
  //  related Product

  //flavour strt
  flavour(getid) {
    let promise = new Promise((resolve, reject) => {
      var data = 'productVariantId=' + getid;
      this.productFlavour(data).subscribe(res => {
        if (res['data'].length > 0) {
          this.flavourName = res['data'];
          this.flavourID = res['data'][0].flavour_name;
          resolve(this.flavourID);
        } else {
          this.flavourName = '';
          resolve(this.flavourID = 'null');
        }
      }, err => {
        reject('Failed');
      });
    });
    return promise;
  }
  //flavour end

  // get cart detail api calling
  GetCartDetails() {
    this.setHeader().then((headerRes) => {
      if (headerRes) {
        this.newStorage.getItem('userdetails').then((userRes) => {
          this.newStorage.getItem('Subscribed').then((subscribedRes) => {
            if (userRes) {
              this.newsLetter = false;
              var data = 'deviceIdentifier=' + this.useriddefault;
              this.cartget(data, this.httpOptions).subscribe(res => {
                this.counterproduct = res['totalQuantity'];
                //this.codeventCallback.next(this.counterproduct);
              }, err => { })
            } else if (subscribedRes) {
              this.newsLetter = false;
              this.httpOptions = undefined;
              var data = 'deviceIdentifier=' + this.useriddefault;
              this.cartget(data, this.httpOptions).subscribe(res => {
                this.counterproduct = res['totalQuantity'];
              });
            } else {
              if (this.newsshow == 'sid') {
                this.newsLetter = false;
              } else {
                setTimeout(() => { this.newsLetter = true; }, 2000);
              }

              if (subscribedRes) {
                this.newsLetter = false;
              }
              this.httpOptions = undefined;
              var data = 'deviceIdentifier=' + this.useriddefault;
              this.cartget(data, this.httpOptions).subscribe(res => {
                this.counterproduct = res['totalQuantity'];
              }, err => { })
            }
          });
        });
      } else {
        setTimeout(() => { this.newsLetter = true; }, 2000);
        this.userdetails = undefined;
        var data = 'deviceIdentifier=' + this.useriddefault;
        this.cartget(data, this.httpOptions).subscribe(res => {
          this.counterproduct = res['totalQuantity'];
        }, err => { });
      }
    })

  }
  // end get cart detail api calling

  filterbyALl(getdata, gettype, soldout = false) {
    //console.log(getdata);
    var data = 'categoryId=' + this.selectedCatID;
    if (this.selectedCatID) {
      this.filter_by(data).subscribe(res => {
        try {
          //console.log(res['data'][0]);
          this.brandByCategory = res['data'][0];
        } catch (error) { }

      }, err => { })
    }

    let apiCall = this.getRouteForProduct(getdata);
    if (!soldout) {
      apiCall.subscribe(res => {
        this.sid_sortby = res['data'];
        this.filterby = this.sid_sortby;
        var getcategory: any = [];
        var getBrand: any = [];
        var subgetcategory: any = [];


        let promise = new Promise((resolve, reject) => {
          this.sid_sortby.forEach((itemss, index) => {
            if (!CommonFunctions.findKeyValueIndex(getBrand, 'brandId', itemss.brand.brandId)) {
              let brand = itemss.brand;
              brand['subCategory'] = itemss.subCategory.subCategoryId;
              brand['category'] = itemss.category.categoryId;
              getBrand.push(brand);
            }
            if (!CommonFunctions.findKeyValueIndex(getcategory, 'categoryId', itemss.category.categoryId)) {
              getcategory.push(itemss.category);
            }
            //console.log(itemss.subCategory.subCategoryId);
            if (!CommonFunctions.findKeyValueIndex(subgetcategory, 'subCategoryId', itemss.subCategory.subCategoryId)) {
              let subCategory = itemss.subCategory;
              subCategory['category'] = itemss.category.categoryId;
              subgetcategory.push(itemss.subCategory);
            }

            if (index == (this.sid_sortby.length - 1)) {
              resolve(true);
            }

          })
        });
        promise.finally(() => {
          this.getbrandvalue = getBrand;
          this.getcatevalue = getcategory;
          this.subgetcatevalue = subgetcategory;

          if (gettype == 'cat') {
            this.showcat = true;
            this.showbrand = false;
          } else if (gettype == 'brand') {
            this.showcat = false;
            this.showbrand = true;
          } else {
            this.showcat = true;
            this.showbrand = true;
          }
          this.loader = true;
        });
      }, err => { });
    } else {
      this.soldoutProductget(getdata).subscribe(res => {
        this.sid_sortby = res['data'];
        this.filterby = this.sid_sortby;
        var getcategory: any = [];
        var getBrand: any = [];
        var subgetcategory: any = [];


        let promise = new Promise((resolve, reject) => {
          this.sid_sortby.forEach((itemss, index) => {
            if (!CommonFunctions.findKeyValueIndex(getBrand, 'brandId', itemss.brand.brandId)) {
              let brand = itemss.brand;
              brand['subCategory'] = itemss.subCategory.subCategoryId;
              brand['category'] = itemss.category.categoryId;
              getBrand.push(brand);
            }
            if (!CommonFunctions.findKeyValueIndex(getcategory, 'categoryId', itemss.category.categoryId)) {
              getcategory.push(itemss.category);
            }
            //console.log(itemss.subCategory.subCategoryId);
            if (!CommonFunctions.findKeyValueIndex(subgetcategory, 'subCategoryId', itemss.subCategory.subCategoryId)) {
              let subCategory = itemss.subCategory;
              subCategory['category'] = itemss.category.categoryId;
              subgetcategory.push(itemss.subCategory);
            }

            if (index == (this.sid_sortby.length - 1)) {
              resolve(true);
            }

          })
        });
        promise.finally(() => {
          this.getbrandvalue = getBrand;
          this.getcatevalue = getcategory;
          this.subgetcatevalue = subgetcategory;

          if (gettype == 'cat') {
            this.showcat = true;
            this.showbrand = false;
          } else if (gettype == 'brand') {
            this.showcat = false;
            this.showbrand = true;
          }
          else {
            this.showcat = true;
            this.showbrand = true;
          }
          this.loader = true;
        });
      }, err => { });
    }
  }

  getRouteForProduct(getdata) {
    let url = this.router.url;

    if (url.includes('in2-nutrition') || url.includes('/brand/') || (url.includes('/category/')) || url.includes('/sub-category/')) {
      return this.productBySKUget(getdata);
    } else if (url.includes('/crazy-deals')) {
      return this.productCrazyDealsGet(getdata)
    } else if (url.includes('/hot-deals')) {
      return this.productHotDealsGet(getdata)
    } else if (url.includes('/best-sellers')) {
      return this.productBestSellerGet(getdata)
    } else {
      return this.productget(getdata);
    }
  }

  getSelectedBrand(row, type, parameter, mainArray = null) {
    //console.log(row);
    let brand_subcat = [];
    let promise = new Promise((resolve, reject) => {
      if (row.length > 0) {
        row.forEach((element, i) => {
          if (element.status) {
            let filter = null;
            if (!mainArray) {
              filter = this.sid_sortby.filter((a, b) => {
                if (type == 'brand') {
                  return (a[parameter] == element.data.brandId);
                } else if (type == 'category') {
                  return (a[parameter] == element.data.categoryId);
                } else if (type == 'subcategory') {
                  return (a.subCategory[parameter] == element.data);
                }
              });
            } else {
              filter = mainArray.filter((a, b) => {
                if (type == 'brand') {
                  return (a[parameter] == element.data.brandId);
                } else if (type == 'category') {
                  return (a[parameter] == element.data.categoryId);
                } else if (type == 'subcategory') {
                  return (a.subCategory[parameter] == element.data);
                }
              });
            }

            //console.log(filter);
            filter.forEach(ele => {
              brand_subcat.push(ele);
            });
            //console.log(brand_subcat);
          }
          if (i == (row.length - 1)) {
            resolve(brand_subcat);
          }
        });
      } else {
        resolve(brand_subcat);
      }

    });
    return promise;
  }

  getSelectedBrandSubcategory(selectedBrand) {
    return new Promise(function (resolve, reject) {
      let subCategories = [];
      try {
        selectedBrand.forEach((ele, index) => {
          if (!CommonFunctions.findKeyValueIndex(subCategories, 'subCategoryId', ele.subCategory.subCategoryId)) {
            subCategories.push({ subCategoryId: ele.subCategory.subCategoryId, subCategoryName: ele.subCategory.subCategoryName });
          }
          if (index === (selectedBrand.length - 1)) {
            resolve(subCategories);
          }
        });
      } catch (error) {
        reject(error);
      }

    });
  }

  testForSearch() {
    (<HTMLInputElement>document.getElementById('searchbox')).style.display = 'none';
    (<HTMLInputElement>document.getElementById('searchboxMo')).style.display = 'none';
  }

  ForgotPasswordLink(femail) {
    var promise = new Promise((resolve, reject) => {
      var data = { 'email': femail }
      this.resetLink(data).subscribe(res => {
        /* if (res['message'] == 'Please enter new password') {
          this.toastr.success('Reset password link has sent on your email id');
          resolve('success');
        } */
        if (res['status']) {
          this.toastr.success(res['message']);
          resolve(true);
        } else {
          resolve(false);
        }
      }, err => {
        this.toastr.error(err.error["message"]);
        //reject(err.error["message"]);
      });

    });

    return promise;
  }

  // start cart
  addtocart(getcartid, slug) {
    return new Promise((resolve, reject) => {
      this.setHeader().finally(() => {
        this.flavour(getcartid).then((message) => {
          if (message) {
            var data = { 'quantity': 1, 'productVariantId': getcartid, 'productFlavour': this.flavourID, 'deviceIdentifier': this.useriddefault };
            this.cartadd(data, this.httpOptions).subscribe(async (res: any) => {
              /* Gtag addtocart */
              await this.particularProduct(slug);
              let gtagAddCardData = {
                value: this.getproductfetch.variantSalePrice,
                items: [{
                  item_id: this.getproductfetch.productSKU,
                  item_name: this.getproductfetch.variantName,
                  discount: (this.getproductfetch.variantPrice - this.getproductfetch.variantSalePrice),
                  price: this.getproductfetch.variantPrice,
                  quantity: this.getproductfetch.quantity
                }]
              };
              gtag_add_to_cart(gtagAddCardData);
              /* Gtag addtocart End */

              this.fbEvent(0, {
                contents: [
                  {
                    "id": `${getcartid}`,
                    "quantity": data.quantity
                  }
                ]
              });
              this.GetCartDetails();
              // this.toastr.success(res['message']);
              resolve(true);
              this.router.navigate(['/cart']);
            }, err => {
              resolve(false);
            });
          }
        }).catch((message) => {
          console.log(message);
          resolve(false);
        });
      });
    });
  }

  // end cart
  closeNav() {
    document.getElementById("mySidenav").style.width = "0";
  }

  processCODData(data) {
    let count = 0;
    let newids = Object.values(data);
    if (newids[0] != null) {
      if (newids != undefined) {
        newids.forEach(element => {
          if (element['cod'] == "Y") {
            count++;
          }
        });
        if (count > 0) {
          this.codeventCallback.next("true");
        } else {
          this.codeventCallback.next("false");
        }
      }
    } else { this.codeventCallback.next("false"); }
  }

  setHeader() {
    return new Promise((resolve, reject) => {
      this.newStorage.getItem('userdetails').then((userRes) => {
        if (userRes) {
          this.userdetails = userRes;
          this.httpOptions = {
            headers: new HttpHeaders({
              "Content-Type": "application/json",
              "Authorization": this.userdetails.token,
            })
          }
          resolve(true);
        } else {
          this.userdetails = undefined;
          this.httpOptions = {
            headers: new HttpHeaders({
              "Content-Type": "application/json"
            })
          }
          resolve(false);
        }
      }).catch(err => {
        this.userdetails = undefined;
        resolve(false)
      });
    })
  }

  getUserAccountDetails(islogin = false) {
    return new Promise((resolve, reject) => {
      if (this.userdetails) {
        this.getUserMyAccountDetails('', this.httpOptions).subscribe(res => {
          if (res['data'] != null) {
            // this.userdetails = res['data']
            this.userDetailsObserver.next({
              'userID': res['data']['id'],
              'name': (res['data']['name']) ? res['data']['name'] : "...",
              'email': res['data']['email'],
              'token': this.userdetails.token,
              'isPrimeMember': res['data']['isPrimeMember']
            })
            setTimeout(() => {
              resolve(res);
            }, 1000);
          } else {
            resolve(false);
          }
        }, err => {
          this.logout();
          resolve(false);
        });
      } else {
        resolve(false)
      }
      // }
      // })
    });
  }

  logout() {
    return new Promise((resolve, reject) => {
      this.newStorage.deleteItem('userdetails');
      this.newStorage.deleteItem('Guestdetails');
      this.newStorage.deleteItem('guestID');
      this.loginget = 'logout';
      this.userdetails = undefined;
      resolve(true);
    })
  }

  getWebsiteConfig() {
    return new Promise((resolve, reject) => {
      this.http.get(this.domain + 'client/config').subscribe(res => {
        this.websiteConfig = res['data'];
        resolve(this.websiteConfig);
      }, err => {
        this.toastr.error(err.error["message"]);
        //reject(err.error["message"]);
        reject(false);
      });
    })

  }

  findConfig(name: string) {
    return this.websiteConfig.filter((f: any) => f.variable == name)
  }


  fbEvent(eventIndex: any, data: FBContent) {
    let eventTime = Number((Number(new Date()) / 1000).toFixed());
    let body = {
      "data": [
        {
          "event_name": this.eventsList[eventIndex],
          "event_time": eventTime,
          "action_source": "website",
          "user_data": {
            "em": [
              "7b17fb0bd173f625b58636fb796407c22b3d16fc78302d79f0fd30c2fc2fc068"
            ],
            "ph": [
              null
            ]
          },
          "contents": data.contents,
          content_type: 'product',

        }
      ]
    }
    if (data.amount) {
      body.data[0]["custom_data"] = {
        "currency": "INR",
        "value": `${data.amount}`
      }
      this.fbEventTrack(eventIndex, { ...body.data[0]["custom_data"], contents: body.data[0].contents, content_type: 'product' });
    } else {
      this.fbEventTrack(eventIndex, { contents: body.data[0].contents, content_type: 'product' });
    }

    this.http.post('https://graph.facebook.com/v16.0/239474045162527/events?access_token=EAAr92LZCey74BAJaleoVHiHeZBMEEjR1C1sRMNhm0etYfC8G51oRLe1ett2oVlaRZBkjPwH0v7IkSKTXJNiq5nFMPHGAPGEyKw2F8KwjJbr4FpbkHWUGD9RSIr5bZCyUFHhBEqV30ZBgPnrjnQ2LGYyZBeZAVNf9i7RPNWnwPtGC7dZArv13Fpwu', body).subscribe(res => {
      // console.log(res);
    })
  }

  fbEventTrack(eventIndex: any, data: any) {
    fbq('track', this.eventsList[eventIndex], data);
  }

  generatePhonePePaymentGatewayLink(data: any, header: any) {
    return this.http.post(this.domain + 'client/generatePaymentLink', data, header)
  }



}
