<div class="sidebar-title">Sort by:</div>
<ul class="listItem">
    <li>
        <input type="checkbox" (change)="gotosort('A')">
        <label class="checkLabel">A to Z</label>
    </li>
    <li>
        <input type="checkbox" (change)="gotosort('Z')">
        <label class="checkLabel">Z to A</label>
    </li>
    <li>
        <input type="checkbox" (change)="gotosort('low')">
        <label class="checkLabel">Low to High price</label>
    </li>
    <li>
        <input type="checkbox" (change)="gotosort('high')">
        <label class="checkLabel ">High to Low price</label>
    </li>
</ul>