import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DomainService } from 'src/app/services/domain.service';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageService as newStorage } from 'src/app/services/newstorage.service';
import { NgxSpinnerService } from "ngx-spinner";
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { CommonService } from 'src/app/services/common.service';
import { Analytics } from 'analytics';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

import * as sha from 'js-sha512';
import { ModalDirective } from 'ngx-bootstrap/modal';

// declare var $: any;
declare var gtag_report_conversion: Function;
declare var gtag_purchase: Function;

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit, AfterViewInit {

  /* 🚀 Checkout UI Variable */

  @ViewChild('ngOtpInput') ngOtpInputRef: any;

  analytics = Analytics({});

  @ViewChild('mobileOtp') mobileOtp: ModalDirective;
  @ViewChild('otpDialog') otpDialog: ModalDirective;

  math = Math;
  mobileSection: FormGroup;
  mobileOtpForLogin: any;
  userDetailsForm: FormGroup;
  isShowTabAfterLogin: boolean = false;
  orderNote: any = '';
  payUObj = { key: "", txnid: "", productinfo: "", amount: 0, email: "", firstname: "", lastname: "", surl: "", furl: "", phone: "", hash: "", salt: "" }
  txnId: any;
  displayDetails: any[] = [];
  isShowuserDetails: boolean = false;
  isCancelShowonCondition: boolean = true;
  public timerOn = true;
  timerlimit: boolean;
  timerCount: any;
  config = {
    allowNumbersOnly: true,
    length: 4,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: '',
    inputStyles: {
      'width': '50px',
      'height': '50px'
    }
  };
  codShow: boolean = false;
  payNowButton: string = "0";
  codNowButton: string = "0";
  otp: any;
  coupondiscount: any;
  yoursaving: boolean;
  getdiscountAmount: number;
  gettpriceamont: any = 0;

  isCODActiveForSpecificUsers: boolean = true;
  accountDetails: any;

  // isPrepaid = false;
  prepaidData: any;
  prepaidAmount: any;
  orderPrepaidAmount = 0;
  totalPayable = { prepaid: 0, cod: 0 }
  /* 🚀 Checkout UI Variable */

  constructor(
    public common: DomainService,
    public newStorage: newStorage,
    public toastr: ToastrService,
    public router: Router,
    private formBuilder: FormBuilder,
    public commService: CommonService,
    private gtmService: GoogleTagManagerService,
    private activateRoute: ActivatedRoute,
    private spinner: NgxSpinnerService,
  ) { }

  ngAfterViewInit(): void {
    this.getcartall().finally(() => {
      // console.log(this.common.cartdetails, this.getdiscountAmount);
      let contents: any = [];

      for (let a of this.common.cartdetails) {
        contents.push({ id: a.id, quantity: a.quantity });
      }
      this.common.fbEvent(1, { contents: contents, amount: this.getdiscountAmount }) // need to check
      // console.log(this.getdiscountAmount);

      this.common.codeventCallback$.subscribe(data => {
        if (data == 'true') {
          this.codShow = true;
          this.payNowButton = "42px";
          this.codNowButton = "0";
        } else {
          this.codShow = false;
          this.payNowButton = "0";
        }
      });

      this.newStorage.getItem('userdetails').then((userRes) => {
        if (userRes) {
          this.common.userdetails = userRes;
          // console.log(userRes, this.common.userdetails);
          this.getUserDetails().finally(() => {
            this.newStorage.getItem("PDA").then(dbres => {
              //console.log(this.getdiscountAmount, this.prepaidData.minAmount, this.prepaidData.maxAmount);
              if (this.totalPayable.prepaid > this.prepaidData.minAmount && this.totalPayable.prepaid < this.prepaidData.maxAmount) {
                if (dbres) {
                  this.orderPrepaidAmount = parseFloat(dbres.toString());
                  // this.checkPrepaidInput(true);
                }
              } else {
                console.log(`Total amount is small then ${this.prepaidData.minAmount}`);
              }
              this.common.setHeader().finally(() => {
                this.isSuccessDone();
              });
            });
          });
        }
      });

    });
  }

  /* 🚀 This Function will get called by default */
  ngOnInit() {
    // console.log('checkout')    
    this.formInitialize();
    this.activateRoute.data.subscribe(data => {
      if (data.checkoutResolve && data.checkoutResolve.data) {
        this.accountDetails = data.checkoutResolve.data
      }
    })

    this.CheckOutSEO();

  }

  /* 🚀 This Function will Check when payment success on that basis It will call Place Order */
  isSuccessDone() {
    this.activateRoute.queryParams.subscribe(params => {
      if (params['success'] == "true") {
        // if (params['status'] == "PAYMENT_SUCCESS") {
        this.getcartall();
        this.getUserDetails();
        this.orderNote = localStorage.getItem('OrderNote');
        // setTimeout(() => {
        this.placeOrder('payu', params['txnid'], params['cid']);
        // }, 500);
      } else {
        this.router.navigate(['/checkout']);
      }
    });
  }

  /* 🚀 This Function will final place order */
  placeOrder(value, txnId, cid) {
    var data = {};
    if (value == 'payu') {
      data = {
        'razorpayID': txnId,
        'deviceIdentifier': this.common.useriddefault,
        'clientId': cid,
        'totalShippingCost': '0',
        'isPrepaidDiscount': (this.orderPrepaidAmount > 0) ? true : false,
        'note': this.orderNote
      };

    } else if (value == 'COD') {
      data = {
        'razorpayID': 'COD',
        'deviceIdentifier': this.common.useriddefault,
        'clientId': cid,
        'totalShippingCost': '0',
        'isPrepaidDiscount': false,
        'note': this.orderNote
      };
    }

    if (data) {
      this.spinner.show();

      this.common.addorder(data, this.common.httpOptions).subscribe(res => {
        let contents: any = [];
        for (let a of this.common.cartdetails) {
          contents.push({ id: a.id, quantity: a.quantity });
        }
        if (value == 'payu') {
          this.common.fbEvent(2, { contents: contents, amount: this.totalPayable.prepaid })
        } else if (value == 'COD') {
          this.common.fbEvent(2, { contents: contents, amount: this.totalPayable.cod })
        }

        this.getcartall();
        // this.newStorage.deleteItem("PDA");
        this.newStorage.deleteItem("PDP");

        this.toastr.success(res['message']);

        // Event snippet for Purchase - KA conversion page 
        if (res['orderId']) {
          var items = [];
          for (let i = 0; i < this.common.cartdetails.length; i++) {
            const ele = this.common.cartdetails[i];
            let item = {
              item_id: ele.productSKU,
              item_name: ele.variantName,
              discount: (ele.variantPrice - ele.variantSalePrice),
              price: ele.variantPrice,
              quantity: ele.quantity
            }
            items.push(item);
          }

          // For Prepaid
          this.newStorage.getItem("PDA").then((dbres: any) => {
            if (dbres) {
              gtag_purchase({ "order_id": res['orderId'], "order_amount": dbres, "products": items })
              gtag_report_conversion(dbres, res['orderId']);
            }
            setTimeout(() => {
              this.newStorage.deleteItem("PDA");
            }, 500);
          })

          // For COD
          this.newStorage.getItem("DA").then((dbres: any) => {
            if (dbres) {
              gtag_purchase({ "order_id": res['orderId'], "order_amount": dbres, "products": items })
              gtag_report_conversion(dbres, res['orderId']);
            }
            setTimeout(() => {
              this.newStorage.deleteItem("DA");
            }, 500);
          })
        }
        // End Event snippet for Purchase - KA conversion page 

        var obj = { id: '', affiliation: '', revenue: 0, shipping: 0, tax: 0 };
        obj.id = txnId;
        obj.affiliation = 'Online Store - source of supplements';

        var products = [];

        this.common.cartdetails.forEach(element => {
          obj.revenue += element.variantSalePrice;

          var itemObj = {
            name: element.variantName,
            id: element.productVariantId,
            price: element.variantSalePrice,
            brand: "",
            category: "",
            variant: "",
            quantity: element.quantity,
            coupon: ''
          }

          products.push(itemObj);
        });

        this.gtmService.pushTag({ ecommerce: null });

        var purchaseObj = {
          'event': 'gtm.Purchase',
          'ecommerce': {
            'purchase': {
              'actionField': {
                'id': txnId,
                'affiliation': obj.affiliation,
                'revenue': obj.revenue,
                'tax': '0.00',
                'shipping': '0.00',
                'coupon': ''
              },
              'products': products
            }
          }
        }

        this.gtmService.pushTag(purchaseObj);
        localStorage.removeItem('OrderNote');
        this.orderNote = '';
        this.router.navigate(['/thankyou']);
      }, err => {
        this.spinner.hide();
        if (err['error']) {
          this.toastr.error(err['error'].error);
        } else {
          console.log(err)
        }
      });
      this.spinner.hide();


    }
    this.spinner.hide();
  }

  /* 🚀 This Function will Check login and address field if all good then shows PayU payment page for Payment */
  payuGateWay() {
    // this.getdiscountAmount = this.getdiscountAmount + (this.prepaidData ? this.prepaidData.prepaid_logisticAmount : 0);
    // console.log(this.getdiscountAmount);
    this.generateRandomTxnId(14);
    this.payUObj.txnid = this.txnId;
    let data = { "mobile": this.userDetailsForm.value.mobileNumber, "amount": this.totalPayable.prepaid, "txnid": this.txnId }
    this.common.generatePhonePePaymentGatewayLink(data, this.common.httpOptions).subscribe((res) => {
      if (res) {
        let testUrl = res['data'].instrumentResponse['redirectInfo'].url;
        window.open(testUrl, '_self');
      }
    });
  }


  /* PAYU payment Gateway */
  payuSubmit() {
    document.getElementById('payuSubmit').click()
  }

  payuOldGateWay(e: any) {

    if (this.common.userdetails && this.common.userdetails['userID']) {
      let address = this.userDetailsForm.value.address || null;
      let pinCode = this.userDetailsForm.value.pinCode || null;
      let city = this.userDetailsForm.value.city || null;
      let state = this.userDetailsForm.value.state || null;

      let mobileNumber = this.userDetailsForm.value.mobileNumber || null;
      let name = this.userDetailsForm.value.name || null;
      let email = this.userDetailsForm.value.email || null;

      if (address != null && pinCode != null && city != null && state != null && mobileNumber != null && name != null && email != null && this.isShowuserDetails == true) {

        this.newStorage.updateItem("DA", this.totalPayable.prepaid);

        localStorage.setItem('OrderNote', this.orderNote)
        this.generateRandomTxnId(14);
        this.payUObj.key = this.common.payUKey;
        this.payUObj.txnid = this.txnId;
        this.payUObj.productinfo = 'SOS';
        this.payUObj.amount = this.totalPayable.prepaid;
        this.payUObj.email = email;
        this.payUObj.firstname = name;
        this.payUObj.lastname = name;

        this.payUObj.surl = `${this.common.domain}client/getpaymentstatus?txnid=${this.txnId}&cid=${this.common.userdetails.userID}&success=true`;
        this.payUObj.furl = `${this.common.domain}client/getpaymentstatus?txnid=${this.txnId}&cid=${this.common.userdetails.userID}&success=false`;

        this.payUObj.phone = mobileNumber;
        this.payUObj.salt = this.common.payUSaltKey;

        let createSha = this.payUObj.key
          + '|' + this.payUObj.txnid
          + '|' + this.payUObj.amount
          + '|' + this.payUObj.productinfo
          + '|' + this.payUObj.firstname
          + '|' + this.payUObj.email
          + '|' + '||||||||||'
          + this.payUObj.salt;

        let check = sha.sha512(createSha);
        this.payUObj.hash = check;

        setTimeout(() => {
          e.target.submit();
        }, 1500);
      } else {
        this.toastr.error('Please Save User Details and Address');
      }
    } else {
      this.toastr.error('Login Required');
    }
  }

  /* 🚀 This Function will work when you place order with cash on delivery */
  cashonDelivery() {
    // this.getdiscountAmount = this.getdiscountAmount + (this.prepaidData ? this.prepaidData.cod_logisticAmount : 0);
    // console.log(this.getdiscountAmount);

    if (this.totalPayable.cod > this.prepaidData.minAmount && this.totalPayable.cod < this.prepaidData.maxAmount && this.isCODActiveForSpecificUsers) {
      this.timerOn = false;

      this.newStorage.updateItem("DA", this.totalPayable.cod);
      let mobileNumber = this.userDetailsForm.value.mobileNumber || null;
      let address = this.userDetailsForm.value.address || null;
      let pinCode = this.userDetailsForm.value.pinCode || null;
      let city = this.userDetailsForm.value.city || null;
      let state = this.userDetailsForm.value.state || null;

      let name = this.userDetailsForm.value.name || null;
      let email = this.userDetailsForm.value.email || null;

      if (this.common.userdetails && this.common.userdetails['userID']) {
        if (mobileNumber != null && address != null && pinCode != null && city != null && state != null && name != null && email != null && this.isShowuserDetails == true) {
          var data = { 'mobile_number': mobileNumber };
          this.common.OTP(data).subscribe(res => {
            if (res['message'] == 'otp sent') {
              this.timerOn = true;
              this.timer(180);
              this.otpDialog.show();
            }
          }, err => { console.log(err) });
          // this.placeOrder('COD', 'COD', this.common.userdetails.userID);
        } else {
          this.toastr.error('Please Save User Details and Address');
        }
      } else {
        this.toastr.error('Login Required');
      }
    } else {
      this.toastr.error('You are not eligible for COD, please choose a prepaid option');
    }
  }

  /* 🚀 This Function will get SEO information about the page */
  CheckOutSEO() {
    this.commService.updateMetaTages(this.commService.checkOUTSEO[0]['Title'], [
      { name: 'og:title', content: this.commService.checkOUTSEO[0]['Title'] }
    ]);

    this.commService.createCanonicalURL();
  }

  /* 🚀 This Function will change the flavour on specific product */
  getcartall() {
    return new Promise((resolve, reject) => {
      this.coupondiscount = 0;
      if (this.common.userdetails) {
        var data = 'deviceIdentifier=' + this.common.useriddefault;
        // console.log('call...')

        this.common.cartget(data, this.common.httpOptions).subscribe(res => {
          this.common.cartdetails = res['data'];
          this.common.counterproduct = res['totalQuantity'];
          this.gettpriceamont = 0;
          this.getdiscountAmount = 0;
          let getamount = 0;
          for (let i = 0; i <= this.common.cartdetails.length; i++) {
            if (this.common.cartdetails.length > i) {
              // console.log(this.common.cartdetails);
              if (this.common.userdetails && this.common.userdetails['isPrimeMember'] == true) {
                getamount = this.common.cartdetails[i].primePrice != null ? this.common.cartdetails[i].primePrice * this.common.cartdetails[i].quantity : this.common.cartdetails[i].variantSalePrice * this.common.cartdetails[i].quantity;
                this.gettpriceamont += this.common.cartdetails[i].primePrice != null ? this.common.cartdetails[i].primePrice * this.common.cartdetails[i].quantity : this.common.cartdetails[i].variantSalePrice * this.common.cartdetails[i].quantity;
              } else {
                getamount = this.common.cartdetails[i].variantSalePrice * parseInt(this.common.cartdetails[i].quantity.toString());
                this.gettpriceamont += this.common.cartdetails[i].variantSalePrice * parseInt(this.common.cartdetails[i].quantity.toString());
              }

              if (parseFloat(this.common.cartdetails[i].discount.toString()) > 0 && this.common.cartdetails[i].codeType.toUpperCase() == 'FLAT') {
                this.yoursaving = true;
                if (this.coupondiscount == 0) {
                  this.coupondiscount = parseFloat(this.common.cartdetails[i].discount.toString());
                  // console.log(this.coupondiscount, getamount);
                  getamount = getamount - this.coupondiscount;
                }
              } else if (parseFloat(this.common.cartdetails[i].discount.toString()) > 0 && this.common.cartdetails[i].codeType.toUpperCase() == 'PERCENTAGE') {
                this.yoursaving = true;
                let percent = parseFloat(this.common.cartdetails[i].discount.toString());
                this.coupondiscount = parseFloat(((this.common.cartdetails[i].variantSalePrice / 100) * percent).toFixed(2))
                // console.log(this.coupondiscount, getamount);
                getamount = getamount - this.coupondiscount;
              }

              this.getdiscountAmount = Math.round(this.getdiscountAmount += getamount);
              console.log(this.getdiscountAmount);
            }

            if (i == this.common.cartdetails.length) {
              resolve(true);
            }
          }
        }, err => { });
      } else {
        var data = 'deviceIdentifier=' + this.common.useriddefault + '&';
        this.common.cartget(data, this.common.httpOptions).subscribe(res => {
          this.common.cartdetails = res['data'];
          this.common.counterproduct = res['totalQuantity'];
          this.gettpriceamont = 0;
          this.getdiscountAmount = 0;
          let getamount;
          for (let i = 0; i <= this.common.cartdetails.length; i++) {
            if (this.common.cartdetails.length > i) {
              // console.log(this.common.cartdetails);
              getamount = this.common.cartdetails[i].variantSalePrice * this.common.cartdetails[i].quantity; //Price X Item
              this.gettpriceamont += this.common.cartdetails[i].variantPrice * this.common.cartdetails[i].quantity; //Price X Item
              if (parseFloat(this.common.cartdetails[i].discount.toString()) > 0 && this.common.cartdetails[i].codeType.toUpperCase() == 'FLAT') {
                this.yoursaving = true;
                this.coupondiscount = parseFloat(this.common.cartdetails[i].discount.toString());
                // console.log(this.coupondiscount, getamount);
                getamount = getamount - this.coupondiscount;
              } else if (parseFloat(this.common.cartdetails[i].discount.toString()) > 0 && this.common.cartdetails[i].codeType.toUpperCase() == 'PERCENTAGE') {
                this.yoursaving = true;
                let percent = parseFloat(this.common.cartdetails[i].discount.toString());
                this.coupondiscount = parseFloat(((this.common.cartdetails[i].variantSalePrice / 100) * percent).toFixed(2))
                // console.log(this.coupondiscount, getamount);
                getamount = getamount - this.coupondiscount;
              }
              this.getdiscountAmount = Math.round(this.getdiscountAmount += getamount);
            }

            if (i == this.common.cartdetails.length) {
              resolve(true);
            }
          }
        }, err => { resolve(false); })
      }
    })
  }

  /* 🚀 This Function will change the flavour on specific product */
  selectflavour(cartid, getid) {
    // this.common.setHeader().finally(() => {
    var data = { "id": cartid, "productFlavour": getid.target.value, 'deviceIdentifier': this.common.useriddefault }
    this.common.cartupdate(data, this.common.httpOptions).subscribe(res => {
      this.toastr.success(res['message']);
      this.getcartall();
    }, err => { });
    // });
  }

  /* 🚀 This Function will remove the product from cart */
  cartremove(cartid) {
    // this.common.setHeader().finally(() => {
    this.common.httpOptions["body"] = { 'deviceIdentifier': this.common.useriddefault, 'id': cartid };
    this.common.cartdelete(this.common.httpOptions).subscribe(res => {
      this.toastr.success(res['message']);
      this.getcartall();
    }, err => { });
    // });
  }

  /* 🚀 This Function will Handle Number Only */
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  /* 🚀 This Function will verify the OTP after user input the OTP */
  verifyOTP() {
    if (this.otp != undefined) {
      var data = { 'mobile_number': this.userDetailsForm.value.mobileNumber, 'otp': this.otp };
      this.common.verifyOTP(data).subscribe(res => {
        if (res['message'] == 'otp verified') {
          this.otpDialog.hide();
          if (this.common.counterproduct > 0) {
            this.placeOrder('COD', 'COD', this.common.userdetails.userID);
          } else {
            this.toastr.error("your cart is empty");
          }
        }
      }, err => { console.log(err); });
    } else {
      this.toastr.error('Please input four digit OTP.')
    }
  }

  /* 🚀 This Function will get User Input For OTP */
  onOtpChange(otp) {
    this.otp = otp;
  }

  /* 🚀 This Function will resend the OTP on same mobile number */
  resendOTP() {
    this.timerOn = false;
    let mobileNumber = this.userDetailsForm.value.mobileNumber || null;
    if (mobileNumber != null) {
      var data = { 'mobile_number': mobileNumber };
      this.common.OTP(data).subscribe(res => {
        if (res['message'] == 'otp sent') {
          this.timerOn = true;
          this.timer(180);
        }
      }, err => { console.log(err) });
    } else {
      this.toastr.error('Please Save or select Address.');
    }
  }

  /* 🚀 This Function will shows time for OTP */
  timer(remaining) {
    let m, s;
    try {
      this.timerlimit = true;
      m = Math.floor(remaining / 60);
      s = remaining % 60;

      m = m < 10 ? '0' + m : m;
      s = s < 10 ? '0' + s : s;
      this.timerCount = m + ':' + s;
      remaining -= 1;

      if (remaining >= 0 && this.timerOn) {
        setTimeout(() => {
          this.timer(remaining);
        }, 1000);
        return;
      }

      if (!this.timerOn) {
        return;
      }

      this.timerlimit = false;
    } catch (error) {
    }
  }

  /* 🚀 This Function will generate Random Id */
  generateRandomTxnId(length) {
    var randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var result = '';
    for (var i = 0; i < length; i++) {
      result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
    }
    let epoc = Number(new Date()).toString();
    this.txnId = result + epoc + this.common.userdetails.userID;
    return result;
  }

  /* 🚀 This Function will Initialize Form Field */
  formInitialize() {
    this.mobileSection = this.formBuilder.group({
      mobile: new FormControl('', Validators.compose([Validators.required, Validators.minLength(10), Validators.maxLength(10), Validators.pattern("^[0-9]*$")]))
    });

    this.userDetailsForm = this.formBuilder.group({
      name: new FormControl('', [Validators.minLength(3), Validators.maxLength(50), Validators.required]),
      email: new FormControl('', Validators.compose([Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")])),
      mobileNumber: new FormControl(''),
      address: new FormControl('', [Validators.required]),
      pinCode: new FormControl('', [Validators.required]),
      city: new FormControl('', [Validators.required]),
      state: new FormControl('', [Validators.required]),
      country: new FormControl('INDIA', [Validators.required])
    });
  }

  /* 🚀 This Function will validation mobile number and it will show OTP Modal PopUp */
  mobileLogin() {
    if (this.mobileSection.status == "INVALID") {
      this.toastr.error('Please Check Mobile');
      return false;
    }

    this.OTPHandle();
  }

  /* 🚀 This Function will give OTP */
  OTPHandle() {
    let body = { mobile_number: this.mobileSection.value.mobile }
    this.common.getOtpViaMobile(body).subscribe(res => {
      if (res) {
        this.mobileOtp.show();
        this.timer(180);
      }
    }, err => {
      this.toastr.error(err['error']['error'])
    });
  }

  /* 🚀 This Function will Store User Input */
  onOtpChangeMobileLogin(otp) {
    this.mobileOtpForLogin = otp;
  }

  /* 🚀 This Function will send OTP again if OTP is not received */
  resendOTPForMobile() {
    this.OTPHandle();
    this.timer(180);
  }

  /* 🚀 This Function will check If User Exits or Not based on It will login */
  submitOtp() {
    if (this.mobileOtpForLogin) {
      let data = { mobile_number: this.mobileSection.value.mobile, OTP: this.mobileOtpForLogin }
      this.common.loginOrRegisterViaMobile(data).subscribe(res => {
        if (res) {
          this.newStorage.updateItem('userdetails', {
            'userID': res['userID'],
            'token': 'Bearer ' + res['token'],
          }).finally(() => {
            this.common.userdetails = {
              'userID': res['userID'],
              'token': 'Bearer ' + res['token']
            }
            this.common.setHeader().finally(() => {
              this.mobileOtp.hide();
              this.toastr.success(res['message']);
              this.common.loginget = 'login';

              this.isShowTabAfterLogin = true;
              this.common.getUserAccountDetails(true).then((accountRes) => {
                this.accountDetails = accountRes['data'];
                this.getUserDetails();
              });
              this.getcartall();
            })
          })
        }
      }, err => {
        this.mobileOtp.hide();
        this.toastr.error(err['error']['error'])
        this.ngOtpInputRef.setValue('');
      });
    } else {
      this.toastr.error('Please Enter OTP');
    }
  }

  /* 🚀 This Function will save or update user detail and shipping address */
  saveOrUpdateMyAccountDetails() {
    if (this.userDetailsForm.status == "INVALID") {
      this.toastr.error('Fields is Required');
      return false;
    }

    let body = {
      name: this.userDetailsForm.value.name,
      email: this.userDetailsForm.value.email,
      address: this.userDetailsForm.value.address,
      city: this.userDetailsForm.value.city,
      pinCode: this.userDetailsForm.value.pinCode,
      state: this.userDetailsForm.value.state,
      id: this.common.userdetails.userID
    }

    if (this.common.userdetails) {
      this.common.saveOrUpdateMyAccount(body, this.common.httpOptions).subscribe(res => {
        if (res) {
          this.toastr.success(res['message']);
          this.common.userDetailsObserver.next({
            'userID': this.common.userdetails.userID,
            'name': this.userDetailsForm.value.name,
            'token': this.common.userdetails.token,
            'email': this.userDetailsForm.value.email,
            'isPrimeMember': this.common.userdetails.isPrimeMember
          })
          this.newStorage.updateItem('userdetails', this.common.userdetails);
          this.common.getUserAccountDetails().then((accountRes) => {
            this.accountDetails = accountRes['data'];
            this.getUserDetails();
          });
        }
      });
    }
  }

  /* 🚀 This Function will get City and State based on user Pin code */
  getUserDetailBasedOnPINCode() {
    var data = {
      'pincode': this.userDetailsForm.value.pinCode
    }
    this.common.pincode(data).subscribe(res => {
      if (res) {
        try {
          this.userDetailsForm.patchValue({
            city: res['result']['data'][this.userDetailsForm.value.pinCode].city_name,
            state: res['result']['data'][this.userDetailsForm.value.pinCode].state_name
          })
        } catch (error) {

        }
      }
    }, err => { });
  }

  /* 🚀 This Function will get User deatils */
  getUserDetails() {
    this.spinner.show();
    return new Promise(async (resolve, reject) => {
      if (this.accountDetails) {
        this.isShowTabAfterLogin = true;
        if (this.accountDetails['name'] && this.accountDetails['email'] && this.accountDetails['address'] &&
          this.accountDetails['pinCode'] && this.accountDetails['city'] && this.accountDetails['state']) {
          this.isCancelShowonCondition = true;
          this.displayDetails = [];
          this.displayDetails.push(this.accountDetails);
          this.isShowuserDetails = true;
        } else {
          this.isCancelShowonCondition = false;
          this.isShowuserDetails = false;
        }
        // console.log(this.common.userdetails);
        this.common.userDetailsObserver.next({
          'userID': this.common.userdetails.userID,
          'name': this.accountDetails['name'],
          'token': this.common.userdetails.token,
          'email': this.accountDetails['email'],
          'isPrimeMember': this.common.userdetails.isPrimeMember
        })

        this.userDetailsForm.patchValue({
          mobileNumber: this.accountDetails['mobile_number'],
          name: this.accountDetails['name'],
          email: this.accountDetails['email'],
          address: this.accountDetails['address'],
          pinCode: this.accountDetails['pinCode'],
          city: this.accountDetails['city'],
          state: this.accountDetails['state']
        });

        this.newStorage.getItem('couponcode').then((couponRes) => {
          if (couponRes) {
            this.yoursaving = true;
          }
        });

        this.isCODActiveForSpecificUsers = this.accountDetails['codActive'];
        await this.common.getWebsiteConfig();
        this.prepaidData = {
          discountPercentage: this.common.findConfig('prepaid_discounts_percent').length > 0 ? parseFloat(this.common.findConfig('prepaid_discounts_percent')[0].param) : 0,
          maxAmount: this.common.findConfig('cod_discount_max_amt').length > 0 ? parseFloat(this.common.findConfig('cod_discount_max_amt')[0].param) : 0,
          minAmount: this.common.findConfig('cod_discount_min_amt').length > 0 ? parseFloat(this.common.findConfig('cod_discount_min_amt')[0].param) : 0,
          cod_logisticAmount: this.common.findConfig('cod_logistics_amount').length > 0 ? parseFloat(this.common.findConfig('cod_logistics_amount')[0].param) : 0,
          prepaid_logisticAmount: this.common.findConfig('prepaid_logistics_amount').length > 0 ? parseFloat(this.common.findConfig('prepaid_logistics_amount')[0].param) : 0,
        }
        this.totalPayable.prepaid = this.getdiscountAmount + (this.prepaidData ? this.prepaidData.prepaid_logisticAmount : 0);
        this.totalPayable.cod = this.getdiscountAmount + (this.prepaidData ? this.prepaidData.cod_logisticAmount : 0);

        let total = parseFloat(((this.totalPayable.prepaid / 100) * this.prepaidData.discountPercentage).toFixed());

        this.totalPayable.prepaid -= total;
        this.newStorage.updateItem("PDA", parseFloat(total.toFixed(2)));
        this.newStorage.updateItem("PDP", parseFloat(this.prepaidData.discountPercentage.toFixed(2)));
        this.prepaidAmount = parseFloat(total.toFixed(2));

        // this.getdiscountAmount += this.prepaidData.logisticAmount;
        // console.log(this.getdiscountAmount, this.prepaidData.logisticAmount);
        console.log(this.totalPayable, this.getdiscountAmount, this.prepaidData);
        resolve(true);
        this.spinner.hide();
      } else {
        setTimeout(() => {
          this.spinner.hide();
          // this.getUserDetails();
        }, 1000);

      }
    });
  }

  editUserDetails() {
    this.isShowuserDetails = !this.isShowuserDetails;
    this.isCancelShowonCondition = true;
  }

  /* 🚀 Checkout Login Function */
  hideOtpModal() {
    this.timerlimit = false;
    this.timerCount = undefined;
    this.mobileOtp.hide();
    this.ngOtpInputRef.setValue('');
  }

}
