import { Component, OnInit } from '@angular/core';
import { StorageService as newStorage } from './services/newstorage.service';
import { Event, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { DomainService } from './services/domain.service';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import Analytics from 'analytics';
import { originalSourcePlugin } from '@analytics/original-source-plugin';
import googleAnalytics from '@analytics/google-analytics'

// import googleTagManager from '@analytics/google-tag-manager';
import { CommonService } from './services/common.service';

declare var Fingerprint: any

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Source-Of-Supplements';
  useriddefault: any;

  analytics = Analytics({});

  constructor(
    public newStorage: newStorage,
    public router: Router,
    public common: DomainService,
    public commService: CommonService,
    private gtmService: GoogleTagManagerService,
  ) {

  }

  async ngOnInit() {
    await this.common.getWebsiteConfig();
    // this.intializeGooglTagManagerId();
    this.googleAnalyticsTrakingId();
    var fp1 = new Fingerprint();
    this.common.useriddefault = fp1.get();
    this.newStorage.updateItem('sos', this.title);
    if (this.common.useriddefault) {
      this.newStorage.updateItem('defaultUserId', this.common.useriddefault);
    } else {
    }

    this.common.setHeader().finally(() => {
      this.checkAddress();
    });
    this.checkRoute();
    this.sourceOrigin();
    this.commService.productSEOGet().finally(() => {
      let itemIndex = 52;
      let data = { productVariantId: this.commService.productSEO[itemIndex].id }
      this.common.getReviewCountByProductId(data).subscribe(res => {
        this.commService.productLD.url = "https://sourceofsupplements.com/product/" + this.commService.productSEO[itemIndex].slug
        this.commService.productLD.description = this.commService.productSEO[itemIndex].shortDescription
        this.commService.productLD.itemReviewed.image = this.commService.productSEO[itemIndex].productThumbnail
        this.commService.productLD.itemReviewed.name = this.commService.productSEO[itemIndex].productName
        this.commService.productLD.itemReviewed.sameAs = this.commService.productLD.url

        this.commService.productLD.itemReviewed.aggregateRating.ratingValue = res['TotalAverageRating'].toString()
        this.commService.productLD.itemReviewed.aggregateRating.ratingCount = res['CountRating'].toString()
      });
    });
  }

  /* intializeGooglTagManagerId() {
    this.analytics = Analytics({
      app: 'source-of-supplements',
      plugins: [
        googleTagManager({
          containerId: 'GTM-57RVS7D'
        })
      ]
    })

    this.analytics.page();
  } */

  googleAnalyticsTrakingId() {
    this.analytics = Analytics({
      app: 'source-of-supplements',
      version: '0.1.2',
      plugins: [
        googleAnalytics({
          trackingId: 'UA-167502774-1'
        }),
      ]
    })
  }

  sourceOrigin() {
    Analytics({
      app: 'source-of-supplements',
      plugins: [
        originalSourcePlugin(),
      ]
    });
  }

  checkAddress() {
    this.newStorage.getItem('Guestdetails').then((res) => {
      if (res) {
        this.newStorage.deleteItem('Guestdetails');
        this.newStorage.deleteItem('guestID');
      }
    });
  }

  checkRoute() {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        const gtmTag = {
          event: 'page',
          pageName: event.url,

        };
        this.analytics.page();
        this.gtmService.pushTag(gtmTag);

        setTimeout(() => {
          this.common.GetCartDetails();
        }, 3000);
      }
    })
  }
}
