<app-header></app-header>

<div class="container-fluid topPadding">
    <div class="row policyBanner m-0 align-items-center text-center">
        <div class="col-12">
            <h1 class="bannerText m-2" *ngIf="blogDetails">{{blogDetails.blogName}}</h1>
        </div>
    </div>
</div>

<br>

<section id="main-container" class="main-content container inner BD">
    <div class="row">
        <div id="main-content" class="col-12">
            <div id="primary" class="content-area">
                <div id="content" class="site-content detail-post" role="main">
                    <article id="post-188"
                        class="post-188 post type-post status-publish format-standard has-post-thumbnail hentry category-dried-fruit category-vegetables">
                        <div class="top-info" *ngIf="blogDetails">
                            <div *ngIf="content" [innerHtml]="content"></div>
                            <div class="entry-meta">
                                <div class="meta" style="color: #d31d28!important;
                                font-weight: 400;">
                                    <span class="author"><a routerLink="/blogs"
                                            title="Posts by admin" rel="author">{{blogDetails.blogAuthor}}</a></span>
                                    <span class="space">&nbsp;|&nbsp;</span>
                                    <span class="date">{{blogDetails.createdAt | date}}</span>
                                </div>
                            </div>
                        </div>
                    </article>
                </div>
            </div>
        </div>
    </div>
    <div style="margin-top: 10px;" *ngIf="tagsList && tagsList.length>0">
        <label class="label-tag" *ngFor="let tag of tagsList">{{tag}}</label>
    </div>
</section>

<app-footer></app-footer>


<ngx-spinner bdColor="rgba(0, 0, 0, 0.9)" size="medium" color="#fff" type="ball-atom">
  <p style="color: white"> Please wait... </p>
</ngx-spinner>