<title> Blogs | SOS</title>

<app-header></app-header>

<div class="container-fluid topPadding">
  <div class="row policyBanner m-0 align-items-center text-center">
    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <h1 class="bannerText m-2">Blogs</h1>
    </div>
  </div>

  <div class="row pb-5 pt-3">
    <div class="col-md-12 col-lg-12 col-xl-12 text-center">
      <img class="img-fluid" src="assets/images/blog.png">
    </div>
  </div>

  <div class="row pt-5 justify-content-center">

    <div class="col-md-12 col-lg-12 col-xl-12 text-center">
      <h1 style="color: black;">Title 1: New Products</h1>
      <p style="color: black;">19.03.20 | 11:11</p>
    </div>

    <div class="col-md-12 col-lg-10 col-xl-10 text-center pb-5 px-0">
      <div class="card">
        <div class="card-body">
          <div class="card-text">
            <p style="font-size: 0.8rem;">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
              Bibendum
              est ultricies integer quis.
              Iaculis urna id volutpat lacus laoreet. Mauris vitae ultricies leo
              integer malesuada. Ac odio tempor orci dapibus ultrices in.
              Egestas
              diam in arcu cursus euismod.
              Dictum fusce ut placerat orci nulla. Tincidunt ornare massa eget
              egestas purus viverra accumsan in nisl. Tempor id eu nisl nunc mi
              ipsum faucibus. Fusce id velit ut
              tortor pretium. Massa ultricies mi quis hendrerit dolor magna
              eget.
              Nullam eget felis eget nunc lobortis. Faucibus ornare suspendisse
              sed nisi. Sagittis eu volutpat
              odio facilisis mauris sit amet massa. Erat velit scelerisque in
              dictum non consectetur a erat. Amet nulla facilisi morbi tempus
              iaculis urna. Egestas purus viverra
              accumsan in nisl. Feugiat in ante metus dictum at tempor commodo.
              Convallis tellus id interdum velit laoreet. Proin sagittis nisl
              rhoncus mattis rhoncus urna neque
              viverra. Viverra aliquet eget sit amet tellus cras adipiscing enim
              eu. Ut faucibus pulvinar elementum integer enim neque volutpat ac
              tincidunt. Dui faucibus in ornare
              quam. In iaculis nunc sed augue lacus viverra vitae congue. Vitae
              tempus quam pellentesque nec nam aliquam sem et. Ut morbi
              tincidunt
              augue interdum. Sem
              fringilla ut morbi tincidunt augue. Morbi enim nunc faucibus a
              pellentesque sit amet porttitor eget. In est ante in nibh mauris.
              Nam aliquam sem et tortor consequat id
              porta nibh. Tue lacus viverra vitae congue. Vitae tempus quam
              pellentesque nec nam aliquam sem et. Ut morbi tincidunt augue
              interdum. Sem fringilla ut morbi
              tincidunt augue. Morbi enim nunc faucibus a pellentesque sit amet
              porttitor eget. In est ante in nibh mauris. Nam aliquam sem et
              tortor consequat id porta nibh.In est
              ante in nibh mauris. Nam aliquam sem et tortor consequat id porta
              nibh. Tue lacus viverra vitae congue. Vitae tempus quam
              pellentesque
              nec nam aliquam sem et.
              Ut morbi tincidunt augue interdum. Sem fringilla ut morbi
              tincidunt
              augue. Morbi enim nunc faucibus a pellentesque sit amet porttitor
              eget. In est ante in nibh mauris.
              Nam aliquam sem et tortor consequat id porta nibh.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-12 col-lg-10 col-xl-10 text-center pt-5 px-0">
      <div class="row justify-content-between m-0">
        <div class="col-md-5 col-lg-5 col-xl-5 pb-sm-2">
          <input type="text" class="leaveComment" placeholder="Leave a comment...">
        </div>
        <div class="col-md-5 col-lg-5 col-xl-5">
          <input type="text" class="leaveComment" placeholder="Enter your e-mail address">
        </div>
      </div>
    </div>

    <div class="col-md-12 col-lg-10 col-xl-10 pt-2 pb-5">

      <div class="row d-flex justify-content-center align-items-center py-3"
        style="background: whitesmoke;">
        <div class="col-lg-1 col-xl-1">
          <img src="assets/images/Avatar.png">
        </div>
        <div class="col-lg-2 col-xl-2">
          <p style="margin:0px;color: black;">by Name Surname</p>
        </div>
        <div class="col-lg-7 col-xl-7">
          <p style="margin: 0px;font-size: 0.6rem;">Lorem ipsum dolor sit amet,
            consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et magna...</p>
        </div>
        <div class="col-lg-2 col-xl-2 text-right">
          <p style="margin:0px;color: black;">2 weeks ago</p>
        </div>
      </div>

      <div class="row d-flex justify-content-center align-items-center py-3"
        style="background: #e8e8e8;">
        <div class="col-lg-1 col-xl-1">
          <img src="assets/images/Avatar.png">
        </div>
        <div class="col-lg-2 col-xl-2">
          <p style="margin:0px;color: black;">by Name Surname</p>
        </div>
        <div class="col-lg-7 col-xl-7">
          <p style="margin: 0px;font-size: 0.6rem;">Lorem ipsum dolor sit amet,
            consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et magna...</p>
        </div>
        <div class="col-lg-2 col-xl-2 text-right">
          <p style="margin:0px;color: black;">2 weeks ago</p>
        </div>
      </div>

      <div class="row d-flex justify-content-center align-items-center py-3"
        style="background: whitesmoke;">
        <div class="col-lg-1 col-xl-1">
          <img src="assets/images/Avatar.png">
        </div>
        <div class="col-lg-2 col-xl-2">
          <p style="margin:0px;color: black;">by Name Surname</p>
        </div>
        <div class="col-lg-7 col-xl-7">
          <p style="margin: 0px;font-size: 0.6rem;">Lorem ipsum dolor sit amet,
            consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et magna...</p>
        </div>
        <div class="col-lg-2 col-xl-2 text-right">
          <p style="margin:0px;color: black;">2 weeks ago</p>
        </div>
      </div>

      <div class="row d-flex justify-content-center align-items-center py-3"
        style="background: #e8e8e8;">
        <div class="col-lg-1 col-xl-1">
          <img src="assets/images/Avatar.png">
        </div>
        <div class="col-lg-2 col-xl-2">
          <p style="margin:0px;color: black;">by Name Surname</p>
        </div>
        <div class="col-lg-7 col-xl-7">
          <p style="margin: 0px;font-size: 0.6rem;">Lorem ipsum dolor sit amet,
            consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et magna...</p>
        </div>
        <div class="col-lg-2 col-xl-2 text-right">
          <p style="margin:0px;color: black;">2 weeks ago</p>
        </div>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>