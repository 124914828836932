import { Component, OnInit, OnDestroy } from '@angular/core';
import { DomainService } from 'src/app/services/domain.service';
import { Router } from '@angular/router';

interface selectData {
  status: boolean,
  data: []
}
interface selectSubCat {
  status: boolean,
  data: []
}

declare var $: any;
@Component({
  selector: 'app-filter-by',
  templateUrl: './filter-by.component.html',
  styleUrls: ['./filter-by.component.scss']
})
export class FilterByComponent implements OnInit, OnDestroy {

  loader: boolean = false;
  filter_subcategory: any;
  subCategories: any;

  selectedBrand: any;
  selectedCategory: any;
  brandList: selectData[] = [];
  categoryList: selectData[] = [];
  subCategoriesList: selectSubCat[] = [];

  constructor(
    public common: DomainService,
    public router: Router,
  ) { }

  ngOnInit() {
    this.loader = false;
    this.filter_subcategory = null;
    this.selectedBrand = null;
    this.selectedCategory = null;
    this.subCategories = null;
    this.subCategoriesList = [];
    this.brandList = [];
    this.categoryList = [];

    try {
      this.subCategories = this.common.subgetcatevalue;
      if (this.subCategories.length > 0) {
        this.subCategories.forEach(() => {
          this.subCategoriesList.push({ status: false, data: [] });
        });
      }
      if (this.common.showcat) {
        this.common.getbrandvalue.forEach((el, index) => {
          this.brandList.push({ status: false, data: [] });
          if (index == (this.common.getbrandvalue.length - 1)) {
            this.loader = true;
          }
        });
      } else if (this.common.showbrand) {
        this.common.getcatevalue.forEach((el, index) => {
          this.categoryList.push({ status: false, data: [] });
          if (index == (this.common.getcatevalue.length - 1)) {
            this.loader = true;
          }
        });
      }
    } catch (error) {
      setTimeout(() => {
        this.ngOnInit();
      }, 1000);
    }
  }

  ngOnDestroy() {
    this.common.subgetcatevalue = null;
    this.common.getbrandvalue = null;

    this.common.sortbycat = '';
    this.loader = false;
    this.filter_subcategory = null;
    this.selectedBrand = null;
    this.selectedCategory = null;
    this.subCategories = null;
    this.subCategoriesList = [];
    this.brandList = [];
    this.categoryList = [];

  }

  gotofilter(getval, index) {
    this.common.sortbycat = getval;
    this.subCategoriesList[index].data = getval;

    try {
      if (this.common.showcat) {
        if (this.common.selectedBrandData && this.common.selectedBrandData.length > 0) {
          this.common.filterby = this.common.selectedBrandData.filter((a, b) => {
            return (a.subCategory.subCategoryName == getval);
          });
          let status = false;
          this.subCategoriesList.forEach(el => {
            if (el.status) {
              status = true;
            }
          });
          if (status) {
            this.common.getSelectedBrand(this.subCategoriesList, 'subcategory', 'subCategoryName', this.common.selectedBrandData).then((res: []) => {
              this.common.filterby = res;
            });
          } else {
            this.common.getSelectedBrand(this.common.filterby, 'subcategory', 'subCategoryName').then((res: []) => {
              this.common.filterby = (res.length == 0) ? this.common.selectedBrandData : res;
            });
          }
        } else {
          this.common.getSelectedBrand(this.subCategoriesList, 'subcategory', 'subCategoryName').then((res: []) => {
            this.common.filterby = (res.length == 0) ? this.common.sid_sortby : res;
          });
        }
      } else {
        if (this.common.selectedCategoryData && this.common.selectedCategoryData.length > 0) {
          this.common.filterby = this.common.selectedCategoryData.filter((a, b) => {
            return (a.subCategory.subCategoryName == getval);
          });
          let status = false;
          this.subCategoriesList.forEach(el => {
            if (el.status) {
              status = true;
            }
          });

          if (status) {
            this.common.getSelectedBrand(this.subCategoriesList, 'subcategory', 'subCategoryName', this.common.selectedCategoryData).then((res: []) => {
              this.common.filterby = res;
            });
          } else {
            this.common.getSelectedBrand(this.common.filterby, 'subcategory', 'subCategoryName').then((res: []) => {
              this.common.filterby = (res.length == 0) ? this.common.selectedCategoryData : res;
            });
          }
        }
        else if (this.common.showbrand) {
          this.common.getSelectedBrand(this.subCategoriesList, 'subcategory', 'subCategoryName').then((res: []) => {
            this.common.filterby = (res.length == 0) ? this.common.sid_sortby : res;
          });
        }
      }


    } catch (error) {

    }
  }

  filterSubCategory(row, index) {
    this.categoryList[index].data = row;
    this.common.sortbycat = row.categoryName;
    this.filter_subcategory = row.categoryId.toString();

    let status = false;
    this.subCategoriesList.forEach(el => {
      if (el.status) {
        status = true;
      }
    });

    if (status) {
      this.common.getSelectedBrand(this.categoryList, 'category', 'categoryId').then((res: []) => {
        if (res) {
          this.common.filterby = (res.length == 0) ? this.common.sid_sortby : res;
          this.common.selectedCategoryData = (res.length == 0) ? this.common.sid_sortby : res;

          this.common.getSelectedBrand(this.subCategoriesList, 'subcategory', 'subCategoryName', this.common.selectedCategoryData).then((res: []) => {
            this.common.filterby = res;
          });
        }
      });
    } else {
      this.common.getSelectedBrand(this.categoryList, 'category', 'categoryId').then(res => {
        if (res) {
          this.selectedCategory = res;
          this.common.filterby = (this.selectedCategory.length == 0) ? this.common.sid_sortby : this.selectedCategory;
          this.common.selectedCategoryData = (this.selectedCategory.length == 0) ? this.common.sid_sortby : this.selectedCategory;
        }
      });
    }
  }

  filterbrand(row, index) {
    this.brandList[index].data = row;
    this.common.sortbycat = row.brand_name;
    this.filter_subcategory = row.subCategory.toString();

    let status = false;
    this.subCategoriesList.forEach(el => {
      if (el.status) {
        status = true;
      }
    });

    if (status) {
      this.common.getSelectedBrand(this.brandList, 'brand', 'brandId').then((res: []) => {
        if (res) {
          this.common.filterby = (res.length == 0) ? this.common.sid_sortby : res;
          this.common.selectedBrandData = (res.length == 0) ? this.common.sid_sortby : res;

          this.common.getSelectedBrand(this.subCategoriesList, 'subcategory', 'subCategoryName', this.common.selectedBrandData).then((res: []) => {
            this.common.filterby = res;
          });
        }
      });
    } else {
      this.common.getSelectedBrand(this.brandList, 'brand', 'brandId').then(res => {
        if (res) {
          this.selectedBrand = res;
          this.common.filterby = (this.selectedBrand.length == 0) ? this.common.sid_sortby : this.selectedBrand;
          this.common.selectedBrandData = (this.selectedBrand.length == 0) ? this.common.sid_sortby : this.selectedBrand;
        }
      });
    }
  }

}
