<title> Forgot Password | SOS</title>

<app-header></app-header>

<div class="container-fluid topPadding" (click)="common.closeNav()">
    <div class="row policyBanner m-0 align-items-center text-center">
        <div class="col-12">
            <h1 class="bannerText m-2">Forgot Password</h1>
        </div>
    </div>

    <div class="row justify-content-center pt-5" *ngIf="!stepsPage">
        <div class="col-10 p-4" style="box-shadow: 0px 1px 8px #bbbbbb;">
            <form autocomplete="off">
                <div class="form-group">
                    <label for="email" style="color:black;">Email Address</label>
                    <input type="email" class="form-control" [(ngModel)]="f_email"
                        placeholder="Please Enter Email Address" name="email">
                </div>
                <div class="form-group">
                    <input type="button" class="f-sumbitBtn" value="Recover My Password" (click)="sendLink()"
                        [disabled]="recovery">
                </div>
                <div class="form-group">
                    <p style="color: #000;">If your E-Mail ID is valid, you will receive a recovery link.</p>
                </div>
            </form>
        </div>
    </div>
</div>

<app-footer></app-footer>