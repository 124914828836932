import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
import { DomainService } from 'src/app/services/domain.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  public f_email: any;
  public stepsPage: boolean = false;
  public recovery: boolean = false;
  val: any;

  constructor(public common: DomainService, public toastr: ToastrService, public router: Router, public commService: CommonService) { }

  ngOnInit() {
    this.commService.updateMetaTages('Forget Password – Source of Supplements', [
      { name: 'og:title', content: 'Forget Password – Source of Supplements' },
      { name: 'title', content: 'Forget Password – Source of Supplements' },
      { name: 'og:description', content: 'Do you forget your password to log in to your account on Source of Supplements? Dont worry! You can easily reset your password through a link or OTP. ' },
      { name: 'description', content: 'Do you forget your password to log in to your account on Source of Supplements? Dont worry! You can easily reset your password through a link or OTP. ' },
    ]);
    this.commService.createCanonicalURL();

  }

  sendLink() {
    if (this.f_email != undefined && this.f_email != '') {
      this.recovery = true;
      this.common.ForgotPasswordLink(this.f_email).then(res => {
        if (res) {
          this.router.navigate(['/login-register']);
        }
      });
    } else {
      this.toastr.error("Email is required");
    }
  }

}
