import { Component, OnInit, ViewChild } from '@angular/core';
import { DomainService } from 'src/app/services/domain.service';

import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { StorageService as newStorage } from 'src/app/services/newstorage.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { CommonService } from 'src/app/services/common.service';
import { ModalDirective } from 'ngx-bootstrap/modal';

declare var $: any;

@Component({
  selector: 'app-login-and-register',
  templateUrl: './login-and-register.component.html',
  styleUrls: ['./login-and-register.component.scss']
})
export class LoginAndRegisterComponent implements OnInit {

  /* 🚀 Start For Mobile Login */

  @ViewChild('ngOtpInput') ngOtpInputRef:any;

  @ViewChild('mobileOtp') mobileOtp: ModalDirective;

  config = {
    allowNumbersOnly: true,
    length: 4,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: '',
    inputStyles: {
      'width': '50px',
      'height': '50px'
    }
  };

  otp: any;
  timerlimit: boolean;
  timerCount: any;
  public timerOn = true;
  mobileSection: FormGroup;

  /* 🚀 End For Mobile Login */

  constructor(public common: DomainService,
    public router: Router,
    private toastr: ToastrService,
    public newStorage: newStorage,
    private FormBuilder: FormBuilder,
    public commService: CommonService
  ) {

  }

  ngOnInit() {
    this.commService.updateMetaTages('Login-Register Source of Supplements | Nutrition Products', [
      { name: 'og:title', content: 'Login-Register Source of Supplements | Nutrition Products' },
      { name: 'title', content: 'Login-Register Source of Supplements | Nutrition Products' },
      { name: 'og:description', content: 'Follow the instructions to register and log in to your account on Source of Supplements.  It takes few minutes and helps to place and track your ordered products.' },
      { name: 'description', content: 'Follow the instructions to register and log in to your account on Source of Supplements.  It takes few minutes and helps to place and track your ordered products.' },
    ])
    this.commService.createCanonicalURL();

    this.formInitialize();
  }

  formInitialize() {
    this.mobileSection = this.FormBuilder.group({
      mobile: new FormControl('', Validators.compose([Validators.required, Validators.minLength(10), Validators.maxLength(10), Validators.pattern("^[0-9]*$")]))
    });
  }

  //  🚀 This Function will validation mobile number and it will show OTP Modal PopUp
  mobileLogin() {
    if (this.mobileSection.status == "INVALID") {
      this.toastr.error('Please Check Mobile');
      return false;
    }

    this.OTPHandle();
  }

  // 🚀 This Function will give OTP
  OTPHandle() {
    let body = { mobile_number: this.mobileSection.value.mobile }
    this.common.getOtpViaMobile(body).subscribe(res => {
      if (res) {
        this.mobileOtp.show();
        this.timer(180);
      }
    }, err => {
      this.toastr.error(err['error']['message'])
    });
  }

  //  🚀 This Function will Store User Input
  onOtpChange(otp) {
    this.otp = otp;
  }

  //  🚀 This Function will Start Timer
  timer(remaining) {
    let m, s;
    try {
      this.timerlimit = true;
      m = Math.floor(remaining / 60);
      s = remaining % 60;

      m = m < 10 ? '0' + m : m;
      s = s < 10 ? '0' + s : s;
      this.timerCount = m + ':' + s;
      remaining -= 1;

      if (remaining >= 0 && this.timerOn) {
        setTimeout(() => {
          this.timer(remaining);
        }, 1000);
        return;
      }

      if (!this.timerOn) {
        return;
      }

      this.timerlimit = false;
    } catch (error) {
    }
  }

  // 🚀 This Function will send OTP again if OTP is not received
  resendOTP() {
    this.OTPHandle();
    this.timer(180);
  }

  // 🚀 This Function will check If User Exits or Not based on It will login
  submitOtp() {
    if (this.otp) {
      let data = { mobile_number: this.mobileSection.value.mobile, OTP: this.otp }
      this.common.loginOrRegisterViaMobile(data).subscribe((res) => {
        if (res) {
          this.newStorage.updateItem('userdetails', {
            'userID': res['userID'],
            'token': 'Bearer ' + res['token']
          }).finally(() => {
            this.common.setHeader().finally(() => {
              this.common.getUserAccountDetails();
              this.mobileOtp.hide();
              this.toastr.success(res['message']);
              this.common.loginget = 'login';
              this.router.navigate(['/']);
            });
          });

        }
      }, err => {
        this.mobileOtp.hide();
        this.toastr.error(err['error']['error'])
        this.ngOtpInputRef.setValue('');
      });
    } else {
      this.toastr.error('Please Enter OTP');
    }
  }

  hideOtpModal() {
    this.timerlimit = false;
    this.timerCount = undefined;
    this.mobileOtp.hide();
    this.ngOtpInputRef.setValue('');
  }
}
