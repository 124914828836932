<title> Sub Category | SOS</title>

<app-header></app-header>

<div class="container-fluid topPadding" (click)="common.closeNav()">
    <div class="row policyBanner m-0 align-items-center text-center ">
        <div class="col-12">
            <h1 class="bannerText m-2">{{subcatName}}</h1>
        </div>
    </div>
    <div class="row ">
        <div class="col-12">
            <div class="row pt-2">
                <div class="col-6" *ngFor="let i of common.filterby">
                    <a href="{{commService.currentHost}}/product/{{i.slug}}">
                        <div class="card d-flex text-center border-0">
                            <div class="full-width-link" [id]="i.id"></div>
                            <div class="card-body p-0" (click)="getbrand(i); cardClick(i.id)">
                                <div class="discountCircle">
                                    <p class="discountText">{{(((i.variantPrice - i.variantSalePrice) / i.variantPrice) *
                                        100).toFixed(0)}}%</p>
                                </div>
                                <img width="270" height="270" class="card-img-top img-fluid"
                                    src="{{i.productVariantPictures[0].productVariantThumbnail}}"
                                    onerror="this.src='assets/loader/200.gif'" alt="Card image cap" appLazyLoad
                                    onerror="this.src='assets/loader/200.gif'">
                            </div>
                            <div class="card-footer w-100 p-0 bg-white border-0">
                                <p class="card-text variantName text-ellipsis" style="margin-bottom: 0px;">
                                    {{i.variantName}}</p>
                                <p class="card-text">
                                    <del style="color: black;"><b>₹{{i.variantPrice}}</b></del>&nbsp;
                                    <b style="color: #d31d28;">₹{{i.variantSalePrice}}</b>
                                    <input *ngIf="i.activeVariant" type="button" class="addCart" value="Add to Cart"
                                        (click)="common.addtocart(i.id, i.slug)">

                                    <!-- 🚀 Prime price -->
                                <!-- <p class="card-text variantName1 prime mb"><img src="assets/icons/crown.png" height="25"
                                        width="25">
                                    <strong>Prime Price: ₹{{i.primePrice?i.primePrice:'0'}} </strong></p> -->
                                <!-- 🚀 Prime price end-->
                                <p *ngIf="!i.activeVariant" class="soldOut">Sold Out</p>
                            </div>
                        </div>
                    </a>
                </div>

                <div class="col-lg-12" *ngIf="subcatName.length === 0">
                    <h3 class="text-center">Product Not Available</h3>
                </div>

            </div>
        </div>
    </div> <br />

    <div class="row policyBanner m-0 align-items-center text-center">
        <div class="col-12">
            <h1 class="bannerText m-2">About {{subcatName}}</h1>
        </div>
    </div>
    <div class="col-lg-12">
        <div class="row">
            <div class="col-12 whySOSback">
                <p [innerHtml]="subcatDescription"></p>
            </div>
        </div>
    </div>
    <hr>

    <app-mobilefilter></app-mobilefilter>
</div>

<app-footer></app-footer>


<ngx-spinner bdColor="rgba(0, 0, 0, 0.9)" size="medium" color="#fff" type="ball-atom">
    <p style="color: white"> Please wait... </p>
</ngx-spinner>