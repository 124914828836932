import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonFunctions } from '../common.function';
import { CommonService } from '../services/common.service';
import { DomainService } from '../services/domain.service';

@Component({
  selector: 'app-blog-detali',
  templateUrl: './blog-detali.component.html',
  styleUrls: ['./blog-detali.component.scss']
})
export class BlogDetaliComponent implements OnInit {

  blogId: any;
  slug: string;
  blogDetails: any;
  tagsList: any;
  content: any;
  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public common: DomainService,
    public commService: CommonService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.spinner.show();
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.slug = params.get('slug');
      console.log(this.slug)
      this.getBlogsDetail(this.slug);
      this.blogSEOGet().finally(() => {
        this.blogSEO();
      });
    });
  }

  blogSEO() {
    let blogIndex = CommonFunctions.findKeyValueIndex(this.commService.blogSEO, 'id', this.blogId);
    // console.log(brandIndex, this.commService.brandSEO[brandIndex]);
    if (blogIndex) {
      this.commService.updateMetaTages(this.commService.blogSEO[blogIndex]['title'], [
        { name: 'og:title', content: this.commService.blogSEO[blogIndex]['title'] },
        { name: 'title', content: this.commService.blogSEO[blogIndex]['title'] },
        { name: 'og:description', content: this.commService.blogSEO[blogIndex]['description'] },
        { name: 'description', content: this.commService.blogSEO[blogIndex]['description'] },
        { name: 'og:image:secure_url', content: this.commService.blogSEO[blogIndex]['img'] },
        { name: 'image', content: this.commService.blogSEO[blogIndex]['img'] },
      ]);
    } else {
      this.commService.updateMetaTages(this.blogDetails.blogName, [
        { name: 'og:title', content: this.blogDetails.blogName },
        { name: 'title', content: this.blogDetails.blogName },
        { name: 'og:description', content: this.blogDetails.blogName },
        { name: 'description', content: this.blogDetails.blogName },
        { name: 'og:image:secure_url', content: 'https://sourceofsupplements.com/assets/icons/SOS-Favicon.png' },
        { name: 'image', content: 'https://sourceofsupplements.com/assets/icons/SOS-Favicon.png' },
      ]);
    }
    this.commService.createCanonicalURL();
  }

  getBlogsDetail(slug) {
    this.common.getblogDetail(slug).subscribe(res => {
      if (res['data'].length > 0) {
        this.blogDetails = res['data'][0];
        this.blogId = res['data'][0].id;
        this.content = this.blogDetails.blogContent.replaceAll('<img', '<img class="img-fluid"');
        let tags = this.blogDetails['blogTags'];
        if (tags) {
          this.tagsList = tags.split(',');
        }
        console.log(this.tagsList);
      } else {
        this.spinner.hide();
        this.router.navigate(["/"]);
      }
      this.spinner.hide();
    }, err => { 
      this.spinner.hide();
    });
  }

  blogSEOGet() {
    return new Promise((resolve, reject) => {
      this.common.blogSEOget().subscribe(res => {
        console.log(res)
        for (let index = 0; index < res['data'].length; index++) {
          const ele = res['data'][index];
          if (ele['slug'] == this.slug) {
            this.blogId = ele.id;
          }
          this.commService.blogSEO.push({
            id: ele.id,
            title: ele.meta_title,
            description: ele.meta_description,
            img: ele.blogThumbnail
          })
        }
        resolve(true);
      }, err => {
        resolve(false);
      });
    });
  }

}
