<title> Trending in Protein | SOS</title>

<app-header></app-header>

<div class="container-fluid topPadding"  (click)="common.closeNav()">
  <div class="row policyBanner m-0 align-items-center text-center">
    <div class="col-12">
      <h1 class="bannerText m-2">Trending In Protein</h1>
    </div>
  </div>

    <div class="row">
    <div class="col-12">
      <div class="row pt-5">
        <div class="col-6" *ngFor="let id of common.filterby">
        
          <div class="card d-flex text-center border-0">
            <div class="full-width-link" [id]="id.id"></div>
            <div class="card-body p-0" (click)="cardClick(id.id)">
              <a href="{{commService.currentHost}}/product/{{id.slug}}">
              <div class="discountCircle">
                <p class="discountText">{{(((id.variantPrice - id.variantSalePrice) / id.variantPrice) * 100).toFixed(0)}}%</p>
              </div>
              <img width="270" height="270" class="card-img-top img-fluid" src="{{id.productVariantPictures.length>0?id.productVariantPictures[0].productVariantThumbnail:'assets/loader/200.gif'}}"
                alt="Card image cap" appLazyLoad onerror="this.src='assets/loader/200.gif'"></a>
            </div>
            <div class="card-footer w-100 p-0 bg-white border-0">
              <p class="card-text variantName text-ellipsis" style="margin-bottom: 0px;">{{id.variantName}}</p>
              <p class="card-text">
                <del style="color: black;"><b>₹{{id.variantPrice}}</b></del>&nbsp;
                <b style="color: #d31d28;">₹{{id.variantSalePrice}}</b>
                <input *ngIf="id.activeVariant" type="button" class="addCart" value="Add to Cart" (click)="common.addtocart(id.id, id.slug)">
               <!-- 🚀 Prime price -->
               <!-- <p class="card-text variantName1 prime mb"><img src="assets/icons/crown.png" height="25" width="25">
                <strong>Prime Price: ₹{{id.primePrice?id.primePrice:'0'}} </strong></p> -->
              <!-- 🚀 Prime price end-->
                <p *ngIf="!id.activeVariant" class="soldOut">Sold Out</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-mobilefilter></app-mobilefilter>

</div>

<app-footer></app-footer>


<ngx-spinner bdColor="rgba(0, 0, 0, 0.9)" size="medium" color="#fff" type="ball-atom">
  <p style="color: white"> Please wait... </p>
</ngx-spinner>