import { Component, OnInit, AfterViewInit, Inject } from '@angular/core';
import { DomainService } from 'src/app/services/domain.service';
import { StorageService as newStorage } from 'src/app/services/newstorage.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';

declare var $: any;


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, AfterViewInit {
  httpOptions: { headers: HttpHeaders; };
  resultsearch: any;
  public resultsearchBrand: any;
  resultsearchBrandMobile: any;
  dropdownMenu: any;
  catShop: any;
  subMenu = [];
  accMenu: boolean;
  subCatShop: any;
  subCatMenu: boolean;
  isSearchShow = true;
  categoryName: any;
  subCategoryName: any;
  id: any;
  brandname: any;
  event_header: any;

  constructor(public common: DomainService,
    public newStorage: newStorage,
    public router: Router,
    public route: ActivatedRoute,
    public toastr: ToastrService, public activateRoute: ActivatedRoute, public commService: CommonService) {
      this.setEventHeader();
  }

  async ngAfterViewInit() {

  }

  setEventHeader() {
    if (this.common.websiteConfig) {
      this.event_header = (this.common.websiteConfig.filter((f: any) => f.variable == "home_event_header"));
      this.event_header = this.event_header.length > 0 ? this.event_header[0].param : false;
      console.log(this.event_header);
    } else {
      setTimeout(() => {
        this.setEventHeader();
      }, 1000);
    }
  }

  ngOnInit() {
    this.categoryShopping();
  }

  gotocart() {
    if (this.common.counterproduct > 0) {
      this.router.navigate(['/cart']);
    }
    else {
      this.toastr.error("Your Cart Is Currently Empty");
    }
  }

  getsearch() {
    this.searchBybrands();
    var getvalu = (<HTMLInputElement>document.getElementById('getvalue')).value;
    if (getvalu.length >= 2) {
      let data = 'search_term=' + getvalu;
      this.common.getsearch(data).subscribe(res => {
        this.resultsearch = res['data'];
      }, err => { });
    } else {
      this.searchBybrands();
    }
  }

  getsearchMobile() {
    var getvaluMo = (<HTMLInputElement>document.getElementById('getvalueMobile')).value;

    if (getvaluMo.length >= 2) {
      // (<HTMLInputElement>document.getElementById('searchboxMo')).style.display = 'block';
      let data = 'search_term=' + getvaluMo;
      this.common.getsearch(data).subscribe(res => {
        this.resultsearchBrandMobile = [];
        this.resultsearch = res['data'];
      }, err => { });
    } else {
      var data = '';
      this.common.getsearchbyBrand(data).subscribe(res => {
        this.resultsearch = [];
        this.resultsearchBrandMobile = res['data'];
      }, err => { });
      // (<HTMLInputElement>document.getElementById('searchboxMo')).style.display = 'none';
    }
  }

  overlay() {
    (<HTMLInputElement>document.getElementById('searchboxoverlay')).style.display = 'none';
  }
  // commonCode() {
  //   try {

  //     if (this.isSearchShow) {
  //       document.onclick = function (e) {

  //           {
  //           (<HTMLInputElement>document.getElementById('searchboxoverlayMobile')).style.display = 'block';
  //           (<HTMLInputElement>document.getElementById('searchboxMo')).style.display = 'block';
  //           var data = '';
  //           this.common.getsearchbyBrand(data).subscribe(res => {
  //             this.resultsearch = [];
  //             this.resultsearchBrandMobile = res['data'];
  //           }, err => { });
  //         };

  //       };
  //     }
  //   } catch (error) { }
  // }
  overlayMobile() {
    (<HTMLInputElement>document.getElementById('searchboxoverlayMobile')).style.display = 'none';
    (<HTMLInputElement>document.getElementById('searchboxMo')).style.display = 'none';
  }

  searchBybrands() {
    (<HTMLInputElement>document.getElementById('searchboxoverlay')).style.display = 'block';
    var data = '';
    this.common.getsearchbyBrand(data).subscribe(res => {
      this.resultsearchBrand = res['data'];
    }, err => { });
  }

  searchBybrandsMobile() {
    (<HTMLInputElement>document.getElementById('searchboxMo')).style.display = 'block';
    (<HTMLInputElement>document.getElementById('searchboxMo')).style.display = 'block';
    document.getElementById("getvalueMobile").focus();
    var data = '';
    this.common.getsearchbyBrand(data).subscribe(res => {
      this.resultsearch = [];
      this.resultsearchBrandMobile = res['data'];
    }, err => { });
  }

  getbrand(slug) {
    // brandnameNew = brandnameNew.replace(/ /g, "-");
    this.router.navigate([`/brand/${slug}`]);
    (<HTMLInputElement>document.getElementById('searchboxMo')).style.display = 'none';
  }

  gotoproductpage(get) {
    get.brand.brand_name = get.brand.brand_name.replace(/ /g, "-");
    this.router.navigate([`/product/${get.slug}`]);
    this.common.particularProduct(get.productVariantID);
  }

  logout() {
    this.common.userdetails = null;
    this.common.loginget = 'logout';
    this.newStorage.deleteItem('userdetails').finally(() => {
      this.router.navigate(['/login-register']);
      this.common.GetCartDetails();
    });

  }

  loginreg() {
    this.router.navigate(['/login-register']);
  }

  gotoproductpageMobile(get) {

    var getvaluMo = (<HTMLInputElement>document.getElementById('getvalueMobile')).value;
    // console.log(getvaluMo, get);
    this.common.savesearch({ search_term: getvaluMo, pvid: get.productVariantID }).subscribe(res => {
      console.log(res);
    });

    (<HTMLInputElement>document.getElementById('searchboxMo')).style.display = 'none';
    get.brand.brand_name = get.brand.brand_name.replace(/ /g, "-");

    // don't remove below code
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    }
    this.router.navigate([`/product/${get.slug}`]); ///${get.brand.brand_name}
    this.common.particularProduct(get.productVariantID);
  }

  megaMenu() {
    this.dropdownMenu = this.dropdownMenu ? false : true;
  }

  categoryShopping() {
    this.common.subCatNav().subscribe(res => {
      this.catShop = res['data']
      this.subMenu = [];
      this.catShop.forEach(el => {
        this.subMenu.push(false)
      });
    }, err => { })
  }

  openNav() {
    document.getElementById("mySidenav").style.width = "320px";
  }

  closeNav() {
    document.getElementById("mySidenav").style.width = "0";
  }

  showSubMenu(index) {
    this.subMenu[index] = true;
  }

  hideSubMenu(index) {
    this.subMenu[index] = false;
  }

  showOption() {
    this.accMenu = this.accMenu ? false : true;
  }

  getsubCatID(slug) {
    // this.router.navigate(['/sub-category/' + slug]).then(res => {
    // });
    this.dropdownMenu = false;
    document.getElementById("mySidenav").style.width = "0";
  }

  showSubCat() {
    this.subCatMenu = true;
  }

  hideSubCat() {
    this.subCatMenu = false;
  }

  catNameID(get) {
    // this.router.navigate([`/category/${get.slug}`])
    this.dropdownMenu = false;
    document.getElementById("mySidenav").style.width = "0";
  }

  closeSearchBox() {
    (<HTMLInputElement>document.getElementById('searchboxMo')).style.display = 'none';
    (<HTMLInputElement>document.getElementById('getvalueMobile')).value = '';
  }

}
