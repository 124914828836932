import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ScullyRoutesService } from '@scullyio/ng-lib';
import { DomainService } from './domain.service';

@Injectable({
  providedIn: 'root'
})

export class CommonService {

  public productLD = {
    "@context": "http://schema.org",
    "@type": "Review",
    "url": "<data:blog.canonicalUrl/>",
    "publisher": {
      "@type": "Organization",
      "name": "Source of Supplements",
      "sameAs": "https://sourceofsupplements.com/"
    },
    "description": "",
    "inLanguage": "en",
    "itemReviewed": {
      "@type": "Product",
      "name": "",
      "sameAs": "<data:blog.canonicalUrl/>",
      "image": "",
      "aggregateRating": {
        "@type": "AggregateRating",
        "ratingValue": "5",
        "bestRating": "5",
        "ratingCount": "245"
      }
    }
  }
  public states = [
    'Andaman and Nicobar Islands',
    'Andhra Pradesh',
    'Arunachal Pradesh',
    'Assam',
    'Bihar',
    'Chandigarh',
    'Chhattisgarh',
    'Dadra & Nagar Haveli ',
    'Daman & Diu',
    'Delhi',
    'Goa',
    'Gujarat',
    'Haryana',
    'Himachal Pradesh',
    'Jammu and Kashmir',
    'Jharkhand',
    'Karnataka',
    'Kerala',
    'Ladakh',
    'Lakshadweep',
    'Madhya Pradesh',
    'Maharashtra',
    'Manipur',
    'Meghalaya',
    'Mizoram',
    'Nagaland',
    'Odisha',
    'Puducherry',
    'Punjab',
    'Rajasthan',
    'Sikkim',
    'Tamil Nadu',
    'Telangana',
    'Tripura',
    'Uttar Pradesh',
    'Uttarakhand',
    'West Bengal',
  ]
  public brandSEO = []
  public subCategorySEO = []
  public productSEO = []
  public categorySEO = []
  public blogSEO = []

  public checkOUTSEO = [
    {
      Title: "Checkout | SOS",
    }
  ]

  public carTSEO = [
    {
      Title: "Cart | SOS",
    }
  ]

  public certificate = [
    {
      "Name": "Bright Performance Nutrition",
      "image": "/assets/images/certi4.png",
      "brand": [{ "brandname": "Optimum Nutrition", "brandid": 17, "slug": "optimum-nutrition" }, { "brandname": "Isopure", "brandid": 10, "slug": "isopure" }, { "brandname": "BSN", "brandid": 47, "slug": "bsn" }, { "slug": "mutant", "brandname": "Mutant", "brandid": 24 }, { "slug": "ultimate-nutrition", "brandname": "Ultimate Nutrition", "brandid": 22 }]
    },
    {
      "Name": "Aleo International",
      "image": "/assets/images/certi1.png",
      "brand": [{ "brandname": "Kevin Levrone", "brandid": 11, "slug": "kevin-levrone" }, { "brandname": "Elite Labs", "brandid": 35, "slug": "elite-labs" }, { "brandname": "ABSN", "brandid": 36, "slug": "absn" }]
    },
    {
      "Name": "Shri Balaji Overseas",
      "image": "/assets/images/certi14.png",
      "brand": [{ "brandname": "Dymatize", "brandid": 6, "slug": "dymatize" }, { "brandname": "Muscletech", "brandid": 14, "slug": "muscle-tech" }, { "brandname": "Ronnie Coleman", "brandid": 3, "slug": "ronnie-coleman" }]
    },
    {
      "Name": "Muscle House India",
      "image": "/assets/images/certi7.png",
      "brand": [{ "brandname": "One Science", "brandid": 42, "slug": "one-science" }, { "brandname": "Dymatize", "brandid": 6, "slug": "dymatize" }, { "brandname": "Gaspari Nutrition", "brandid": 39, "slug": "gaspari-nutrition" }]
    },
    {
      "Name": "Calyxa Nutrition LLP",
      "image": "/assets/images/certi5.png",
      "brand": [{ "brandname": "Grainic", "brandid": 52, "slug": "grainic" }]
    },
    // { 
    //   "Name": "NutritionLab Private Limited", 
    //   "image": "/assets/images/certi8.png", 
    //   "brand": [{ "brandname": "Well Being Nutrition", "brandid": 55, "slug": "well-being-nutrition" }] 
    // }, 
    {
      "Name": "Arena Impex",
      "image": "/assets/images/certi3.png",
      "brand": [{ "brandname": "Ronnie Coleman", "brandid": 3, "slug": "ronnie-coleman" }, { "brandname": "Rule One", "brandid": 21, "slug": "rule-one" }]
    },
    {
      "Name": "Alpino Healthfoods",
      "image": "/assets/images/certi2.png",
      "brand": [{ "brandname": "Alpino", "brandid": 4, "slug": "alpino" }]
    },
    {
      "Name": "Unlimited Nutrition",
      "image": "/assets/images/certi11.png",
      "brand": [{ "brandname": "IN2 Nutrition", "brandid": 25, "slug": "in2-nutrition" }, { "brandname": "Muscle Pharm", "brandid": 13, "slug": "muscle-pharm" }]
    },
    {
      "Name": "Grace Enterprises",
      "image": "/assets/images/certi6.png",
      "brand": [{ "brandname": "Ultimate Nutrition", "brandid": 22, "slug": "ultimate-nutrition" }, { "brandname": "Xtend", "brandid": 23, "slug": "xtend" }, { "brandname": "Cellucor", "brandid": 5, "slug": "cellucor" }]
    },
    {
      "Name": "Muscle Pro Nutrition",
      "image": "/assets/images/certi12.png",
      "brand": [{ "brandname": "Muscletech", "brandid": 11, "slug": "kevin-levrone" }, { "brandname": "Labrada", "brandid": 12, "slug": "labrada" }, { "brandname": "Now Foods", "brandid": 16, "slug": "now-foods" }, { "brandname": "Redcon1", "brandid": 19, "slug": "redcon1" }, { "brandname": "Scitron", "brandid": 34, "slug": "scitron" }, { "brandname": "JYM", "brandid": 61, "slug": "jym" }, { "brandname": "Insane Labz", "brandid": 9, "slug": "insane-labz" }]
    },
    {
      "Name": "GH Nutrition",
      "image": "/assets/images/certi13.png",
      "brand": [{ "brandname": "1UP Nutrition", "brandid": 1, "slug": "1up-nutrition" }]
    },
    {
      "Name": "Fast & UP",
      "image": "/assets/images/FASTUP.jpeg",
      "brand": [{ "brandname": "Fast & UP", "brandid": 37, "slug": "fast-up" }]
    },
    {
      "Name": "ZEULX",
      "image": "/assets/images/ZEULX.jpeg",
      "brand": [{ "brandname": "ZEULX", "brandid": 62, "slug": "zeulx" }]
    }
  ]

  public metaTagData$ = this.srs.getCurrent();
  public currentHost = `${window.location.href.split('/')[0]}/${window.location.href.split('/')[1]}/${window.location.href.split('/')[2]}`;

  constructor(
    private titleService: Title,
    private metaTagService: Meta,
    private common: DomainService,
    private srs: ScullyRoutesService,
    @Inject(DOCUMENT) private dom) { }

  setMetaTages(title, data) {
    this.titleService.setTitle(title);
    this.metaTagService.addTags(data);
  }

  updateMetaTages(title, data) {
    this.metaTagData$.subscribe((b) => {
      //console.log(b.title);
      //console.log(b.description);
    });
    this.titleService.setTitle(title);
    data.forEach(ele => {
      this.metaTagService.updateTag({ name: ele.name, content: ele.content });
    });
  }

  createCanonicalURL() {
    let link: HTMLLinkElement = this.dom.createElement('link');
    link.setAttribute('rel', 'canonical');
    this.dom.head.appendChild(link);
    link.setAttribute('href', this.dom.URL);
  }

  productSEOGet() {
    return new Promise((resolve, reject) => {
      this.common.productSEOget().subscribe(res => {
        for (let index = 0; index < res['data'].length; index++) {
          const ele = res['data'][index];
          this.productSEO.push({
            id: ele.id,
            productName: ele.meta_title,
            shortDescription: ele.shortDescription,
            slug: ele.slug,
            productThumbnail: (ele.productVariantPictures.length > 0) ? ele.productVariantPictures[0].productVariantThumbnail : 'https://sourceofsupplements.com/assets/icons/SOS-Favicon.png;'
          })
        }
        resolve(true);
      }, err => {
        resolve(false);
      });
    });
  }

}

