import { Component, OnInit } from '@angular/core';
import { DomainService } from 'src/app/services/domain.service';

@Component({
  selector: 'app-all-blogs',
  templateUrl: './all-blogs.component.html',
  styleUrls: ['./all-blogs.component.scss']
})
export class AllBlogsComponent implements OnInit {

  constructor(public common :DomainService) { }

  ngOnInit() {
  }

}
