import { Component, OnInit } from '@angular/core';
import { DomainService } from 'src/app/services/domain.service';

declare var $: any;
@Component({
  selector: 'app-sort-by',
  templateUrl: './sort-by.component.html',
  styleUrls: ['./sort-by.component.scss']
})
export class SortByComponent implements OnInit {

  constructor(public common: DomainService) { }

  ngOnInit() {
    $('input[type="checkbox"]').on('change', function () {
      $('input[type="checkbox"]').not(this).prop('checked', false);
    });
  }





  gotosort(getval) {
    this.common.sid_sortbyvalue = getval;
    this.common.sidsortfunction();
  }
}
