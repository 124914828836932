import { Component, OnInit } from '@angular/core';
import { DomainService } from 'src/app/services/domain.service';
import { HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-snacks-trending',
  templateUrl: './snacks-trending.component.html',
  styleUrls: ['./snacks-trending.component.scss']
})
export class SnacksTrendingComponent implements OnInit {

  trending3: any;
  httpOptions: { headers: HttpHeaders; };

  constructor(
    public router: Router,
    public common: DomainService,
    public commService: CommonService,
    private spinner: NgxSpinnerService) { }


  ngOnInit() {
    this.spinner.show();
    this.producttrending4();
  }

  //start product trending second 
  producttrending4() {
    var data = 'all_product=1&shop_by_category=1&categoryId=7';
    this.common.productget(data).subscribe(res => {
      this.common.sid_sortby = res['data'];
      this.common.filterbyALl(data, 'brand');
      this.spinner.hide();
    }, err => { 
      this.spinner.hide();
    });
  }

  getbrand(get) {
    get.brand.brand_name = get.brand.brand_name.replace(/ /g, "-");
    this.router.navigate([`/product/${get.slug}`]);
  }

  cardClick(id){    
    if(id){
      document.getElementById(id).style.display = "block";
      setTimeout(() => {
        document.getElementById(id).style.display = "none";
      }, 300);
    }
  }

}
