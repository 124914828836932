import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule, Router } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { HttpClientModule } from '@angular/common/http';
import { ShopByCategoryComponent } from './shop-by-category/shop-by-category.component';
import { CategoryComponent } from './category/category.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { SubCategoryComponent } from './sub-category/sub-category.component';
import { ImageLazyLoadModule } from '../directive/image-lazy-load.module';
import { NgxSpinnerModule } from 'ngx-spinner';

const routes: Routes = [
  {
    path: 'shop-by-category', component: ShopByCategoryComponent
  },
  {
    path: 'category/:slug', component: CategoryComponent
  },
  {
    path: 'sub-category/:slug', component: SubCategoryComponent
  },
]

@NgModule({
  declarations: [
    ShopByCategoryComponent, 
    CategoryComponent, 
    SubCategoryComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    SharedModule,
    HttpClientModule,
    Ng2SearchPipeModule,
    ImageLazyLoadModule,
    NgxSpinnerModule
  ],
  exports: [
    RouterModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ShopByCategoryModule { 
  constructor(private router: Router) {
    this.router.errorHandler = (error: any) => {
      console.log('invalid file/route');
    }
  }
}
