<title> Checkout | SOS</title>
<app-header></app-header>

<div class="container-fluid topPadding">
    <div class="row policyBanner m-0 align-items-center text-center">
        <div class="col-12">
            <h1 class="bannerText m-2">Checkout</h1>
        </div>
    </div>

    <!-- 🚀 Material Tab For Login -->

    <mat-tab-group class="accounttab">
        <!-- 🚀 Mobile Section -->
        <mat-tab label="Log In" *ngIf="!isShowTabAfterLogin">
            <div class="row ml-3 mr-3">
                <div class="col-12 mt-3 px-3">
                    <div class="row">
                        <div class="col-12 px-3 blackSection">
                            <form [formGroup]="mobileSection" autocomplete="off" class="w-50">
                                <div class="form-group">
                                    <label class="control-label text-white" for="email">Mobile *</label>
                                    <input type="text" class="form-control" id="mobile" placeholder="Enter Mobile"
                                        name="mobile" formControlName="mobile">
                                    <div *ngIf="mobileSection.get('mobile').invalid && (mobileSection.get('mobile').dirty || mobileSection.get('mobile').touched)"
                                        class="alert mb-0">
                                        <div *ngIf="mobileSection.get('mobile').errors.required">
                                            Mobile is required.
                                        </div>
                                        <div
                                            *ngIf="mobileSection.get('mobile').errors.minlength || mobileSection.get('mobile').errors.maxlength || mobileSection.get('mobile').errors.pattern">
                                            Please enter valid phone number </div>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <input type="button" class="redBtn" value="LOGIN" (click)="mobileLogin()">
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </mat-tab>

        <!-- 🚀 Delivery Details -->
        <mat-tab label="Delivery Details" *ngIf="isShowTabAfterLogin">

            <div class="row m-0 pt-3">
                <div class="col-12">
                    <div class="col-12 sliderSection pb-3 d-block">
                        <div class="row m-0">

                            <!-- 🚀 If details are already filled then show below section for display -->

                            <ng-container *ngIf="isShowuserDetails">
                                <div class="row m-0 pt-3">
                                    <div class="col-12 mb-3">
                                        <a style="color: #e83f53;font-weight:500;cursor: pointer;"
                                            (click)="editUserDetails()">
                                            Edit
                                        </a>
                                    </div>

                                    <div class="col-12 sliderSection pb-0 d-block" *ngFor="let obj of displayDetails">
                                        <div>
                                            <label class="control-label"><strong>Name :</strong></label> {{obj.name}}
                                        </div>

                                        <div>
                                            <label class="control-label"><strong>Email :</strong></label> {{obj.email}}
                                        </div>

                                        <div>
                                            <label class="control-label"><strong>Mobile :</strong></label>
                                            {{obj.mobile_number}}
                                        </div>

                                        <div>
                                            <label class="control-label"><strong>Address :</strong></label>
                                            {{obj.address}}, {{obj.city}}, {{obj.state}} - {{obj.pinCode}}
                                        </div>
                                    </div>
                                </div>
                            </ng-container>

                            <!-- 🚀 If details are already filled then show below section for Edit -->

                            <ng-container *ngIf="!isShowuserDetails">
                                <div class="col-12 sliderSection mt-3" style="justify-content: center;">
                                    <div class="row pt-3">
                                        <div class="col-12 mb-3" *ngIf="isCancelShowonCondition">
                                            <a style="color: #e83f53;font-weight:500;cursor: pointer;"
                                                (click)="editUserDetails()">
                                                Cancel
                                            </a>
                                        </div>

                                        <div class="col-12 pl-3">
                                            <form [formGroup]="userDetailsForm" autocomplete="off">
                                                <div class="row">
                                                    <div class="col-6 pt-2 sliderSection">
                                                        <div class="form-group w-100">
                                                            <label class="labelTag">Name *</label>
                                                            <div class="append-icon">
                                                                <input class="inputTagEdit" type="text"
                                                                    placeholder="Name" formControlName="name">
                                                                <div *ngIf="userDetailsForm.get('name').invalid && (userDetailsForm.get('name').dirty || userDetailsForm.get('name').touched)"
                                                                    class="alert mb-0">
                                                                    <div
                                                                        *ngIf="userDetailsForm.get('name').errors.required">
                                                                        Name is required.
                                                                    </div>
                                                                    <div
                                                                        *ngIf="userDetailsForm.get('name').errors.minlength || userDetailsForm.get('name').errors.maxlength || userDetailsForm.get('name').errors.pattern">
                                                                        Please enter valid Name of minimum 3 characters
                                                                        to maximum 50 characters
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-6 pt-2 sliderSection">
                                                        <div class="form-group w-100">
                                                            <label class="labelTag">Email *</label>
                                                            <div class="append-icon">
                                                                <input class="inputTagEdit" type="email"
                                                                    placeholder="Email" formControlName="email">
                                                                <div *ngIf="userDetailsForm.get('email').invalid && (userDetailsForm.get('email').dirty || userDetailsForm.get('email').touched)"
                                                                    class="alert mb-0">
                                                                    <div
                                                                        *ngIf="userDetailsForm.get('email').errors.required">
                                                                        Email is required.
                                                                    </div>
                                                                    <div
                                                                        *ngIf="userDetailsForm.get('email').errors.pattern">
                                                                        Please Enter Valid Email ID.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-6 pt-2 sliderSection">
                                                        <div class="form-group w-100">
                                                            <label class="labelTag">Phone *</label>
                                                            <div class="append-icon">
                                                                <input class="inputTagEdit" type="text"
                                                                    placeholder="Mobile" formControlName="mobileNumber"
                                                                    readonly>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-6 pt-2 sliderSection">
                                                        <div class="form-group w-100">
                                                            <label class="control-label">PIN Code *</label>
                                                            <div class="append-icon">
                                                                <input type="text" class="inputTagEdit"
                                                                    placeholder="Enter PIN Code"
                                                                    formControlName="pinCode"
                                                                    (blur)="getUserDetailBasedOnPINCode()"
                                                                    (keypress)="numberOnly($event)" minlength="6"
                                                                    maxlength="6">
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-12 col-sm-12 pt-2">
                                                        <label class="labelTag">Address *</label><br>
                                                        <textarea placeholder="Address" class="inputTagEdit"
                                                            maxlength="90" formControlName="address"></textarea>
                                                        <div *ngIf="userDetailsForm.get('address').invalid && (userDetailsForm.get('address').dirty || userDetailsForm.get('address').touched)"
                                                            class="alert mb-0">
                                                            <div *ngIf="userDetailsForm.get('address').errors.required">
                                                                Address is required.
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-6 pt-2 sliderSection">
                                                        <div class="form-group w-100">
                                                            <label class="control-label">City *</label>
                                                            <div class="append-icon">
                                                                <input type="text" class="inputTagEdit"
                                                                    placeholder="Enter City" formControlName="city"
                                                                    readonly>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-6 pt-2 sliderSection">
                                                        <div class="form-group w-100">
                                                            <label class="control-label">State *</label>
                                                            <div class="append-icon">
                                                                <input type="text" class="inputTagEdit"
                                                                    placeholder="Enter State" formControlName="state"
                                                                    readonly>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-6 pt-2 sliderSection">
                                                        <div class="form-group w-100">
                                                            <label class="control-label">Country *</label>
                                                            <div class="append-icon">
                                                                <input type="text" class="inputTagEdit"
                                                                    placeholder="Enter Country"
                                                                    formControlName="country" readonly>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-12 pt-2 sliderSection d-flex justify-content-end">
                                                        <button type="button" class="btn btn-sm"
                                                            style="background-color: #d31d28;color: white;"
                                                            (click)="saveOrUpdateMyAccountDetails()">
                                                            Save
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>

                        </div>
                    </div>
                </div>
            </div>

        </mat-tab>
    </mat-tab-group>

    <div class="row m-0 pt-2">
        <div class="col-12 onlyForMobile1">
            <div class="row m-0">
                <div class="col-12 d-flex
                        justify-content-center align-items-center sideSectionOrder p-2">
                    <h1 class="textSection">Order Summary</h1>
                </div>
                <div class="col-12 p-0 sliderSectionOrder">
                    <div class="row m-0 w-100">
                        <div class="col-12 p-2"
                            style="overflow-y: scroll; overflow-x: hidden;  height: auto; max-height: 410px;">
                            <!-- <div class="row py-3 m-0 onlyForMobile" *ngFor="let i of common.cartdetails;let j = index">
                                <div class="col-12 p-0">
                                    <div class="row m-0">
                                        <div class="col-12 pb-3">
                                            <div class="row m-0">
                                                <div class="col-5 p-0">
                                                    <img class="img-fluid" src="{{i.productVariantThumbnail}}">
                                                </div>
                                                <div class="col-7">
                                                    <span class="productDetail">
                                                        {{i.variantName}}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 ">
                                            <div class="row text-center m-0">
                                                <div class="col-xs-3 col p-0">
                                                    <p class="productDetail">Price</p>
                                                    <p class="productDetail" style="color: black;">
                                                        ₹{{i.variantPrice * i.quantity}}</p>
                                                </div>
                                                <div class="col-xs-3 col p-0">
                                                    <p class="productDetail">Qty</p>

                                                    <input type="text" class="qunatitytxt text-center"
                                                        value="{{i.quantity}}" readonly>

                                                </div>
                                                <div class="col-xs-3 col p-0">
                                                    <p class="productDetail">Total</p>

                                                    <p class="productDetail" style="color: black;"
                                                        *ngIf="common.userdetails && common.userdetails['isPrimeMember']; else showVariantPrice">
                                                        ₹{{i.primePrice?i.primePrice * i.quantity:i.variantSalePrice *
                                                        i.quantity | number:'1.0-0'}}
                                                    </p>

                                                    <ng-template #showVariantPrice>
                                                        <p class="productDetail" style="color: black;"
                                                            *ngIf="i.discount <= 0">
                                                            ₹{{i.variantSalePrice * i.quantity | number:'1.0-0' }}
                                                        </p>
                                                    </ng-template>

                                                    <p class="productDetail" style="color: black;"
                                                        *ngIf="i.discount > 0">
                                                        ₹{{(i.variantSalePrice - (i.variantSalePrice * i.discount / 100
                                                        )) * i.quantity | number:'1.0-0' }}
                                                    </p>
                                                </div>
                                                <div class="col-xs-3 col p-0">
                                                    <p class="productDetail">Flavour</p>
                                                    <select class="flavour"
                                                        *ngIf="common.cartdetails[j]['flavourList'] != ''"
                                                        [(ngModel)]="i.productFlavour"
                                                        (change)="selectflavour(i.cartID,$event)">
                                                        <option
                                                            *ngFor="let fl of common.cartdetails[j]['flavourList'];let in = index"
                                                            value="{{fl.flavour_name}}">
                                                            {{fl.flavour_name}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                            <div class="row py-3"
                                [style]="(common.cartdetails.length==j+1)?'border-bottom:1px solid white':'border-bottom:1px solid rgba(0,0,0,.125)'"
                                *ngFor="let i of common.cartdetails;let j = index">
                                <div class="col-4 text-center">
                                    <img src="{{i.productVariantThumbnail}}" class="img-fluid">
                                </div>

                                <div class="col-8 text-start pl-0">
                                    <p class="fw-bold" style=" font-size: 13px;">{{i.variantName}}</p>
                                    <select class="flavour mb-3 w-100"
                                        *ngIf="common.cartdetails[j]['flavourList'] != ''"
                                        [(ngModel)]="i.productFlavour" (change)="selectflavour(i.cartID,$event)">
                                        <option *ngFor="let fl of common.cartdetails[j]['flavourList'];let in = index"
                                            value="{{fl.flavour_name}}">
                                            {{fl.flavour_name}}</option>
                                    </select>
                                    <div class="d-flex align-items-center">
                                        <label for="exampleFormControlInput1" class="form-label mr-3 mb-0">Price:
                                        </label>
                                        <p class="mb-0">₹{{i.variantPrice * i.quantity}}</p>
                                    </div>
                                    <div class="d-flex align-items-center">
                                        <label for="exampleFormControlInput1" class="form-label mr-3 mb-0">Quantity:
                                        </label>
                                        <!-- <input type="number" class="qunatitytxt mb-0" value="{{i.quantity}}" readonly> -->
                                        <span class="qunatitytxt mb-0">{{i.quantity}}</span>
                                    </div>

                                    <div class="d-flex align-items-center fw-bold">
                                        <label for="exampleFormControlInput1" class="form-label mr-3 mb-0">Total Price:
                                        </label>
                                        <!-- <p class="mb-0">₹{{i.primePrice?i.primePrice * i.quantity:i.variantSalePrice * i.quantity | number:'1.0-0'}}</p> -->
                                        <p class="mb-0">₹{{i.variantSalePrice ? i.variantSalePrice * i.quantity :
                                            i.variantPrice * i.quantity | number:'1.0-0'}}</p>
                                    </div>
                                    <!-- 🚀 Prime price -->
                                    <!-- <p class="card-text variantName1 text-center">
                                    <img src="assets/icons/crown.png" height="25" width="25">
                                    <strong>Prime Price: ₹{{i.primePrice?i.primePrice*i.quantity:'0'}} </strong>
                                  </p> -->
                                    <!-- 🚀 Prime price end-->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <!-- Shipping Method start-->

                <!-- <div class="col-12 pb mb pt mt policyBanner">
                    <h4 class="mb-3"><strong>Shipping Method</strong></h4>
                    <div class="custom-control custom-radio list-group-item d-flex justify-content-between">
                        <div class="col-9 col-md-10">
                            <input type="radio" id="customRadio1" name="customRadio" class="custom-control-input "
                                checked>
                            <label class="custom-control-label " for="customRadio1"><span>Pay Online
                                    (UPI/Cards/NetBanking)</span>
                            </label>
                        </div>
                        <div class="col-3 col-md-2 text-right">
                            <strong>₹{{getdiscountAmount}}</strong>
                        </div>
                    </div>

                    <div class="custom-control custom-radio list-group-item d-flex justify-content-between">
                        <div class="col-9 col-md-10">
                            <input type="radio" id="customRadio2" name="customRadio" class="custom-control-input"
                                checked>
                            <label class="custom-control-label" for="customRadio2"><span>Cash on Delivery</span>
                            </label>
                        </div>
                        <div class="col-3 col-md-2 text-right">
                            <strong>₹{{getdiscountAmount}}</strong>
                        </div>
                    </div>
                </div> -->

                <!-- Shipping Method end-->

                <div class="col-12 mb pt mt policyBanner">
                    <div class="form-group">
                        <h6>Order Notes (Optional)</h6>
                        <textarea class="form-control" placeholder="Notes about your order.." rows="4"
                            [(ngModel)]="orderNote" maxlength="100" #maxLengthForCharacters></textarea>
                        <div class="text-danger mt-2" *ngIf="maxLengthForCharacters.value.length > 0">*Max 100
                            characters only.</div>
                    </div>

                </div>

                <div class="col-12 p-0">
                    <div class="border p-3"
                        *ngIf="common.websiteConfig && common.findConfig('product_page_static_line').length>0 && common.findConfig('product_page_static_line')[0].param.trim().length>0">
                        <div class="d-flex" [innerHTML]="common.findConfig('product_page_static_line')[0].param">
                            <!-- <img src="assets/images/gift.svg" class="img-fluid" width="20px" />
                            <h6 class="ml-2 mb-0 pt-1">Free <a
                                    href="https://sourceofsupplements.com/product/trunativ-fiber-100gms-16-serv"
                                    target="_blank" style="color: #3f5e82;">TruNativ Fiber</a>
                                with cart above Rs. 8000</h6> -->
                        </div>
                    </div>
                </div>


                <div class="col-12 p-0 blackSectionCart" style="margin-top: 1rem;margin-bottom: 1rem;">
                    <div class="row m-0 w-100">
                        <div class="col-12 pt-2">
                            <h1 class="cartHead">Cart Total</h1>
                        </div>


                        <div class="col-12" style="padding: 1rem 1rem;">
                            <!-- <p class="m-0 text-white">*Price included of shipping, handling & GST</p>
                            <p class="m-0 text-white">*Safe payment options & easy returns.</p>
                            <p class="text-white">*100% authentic products</p>
                            <p class="m-0 text-white">Read our T&Cs and Privacy Policy
                                <a routerLink="/policies" target="_blank" style="color: blue;">here.</a>
                            </p> -->


                            <!-- <mat-checkbox class="m-0 text-white" (change)="checkPrepaidInput($event.checked)"
                                [checked]="isPrepaid"
                                *ngIf="!(prepaidData && (getdiscountAmount > prepaidData.minAmount ? false : true))">
                                {{prepaidData && prepaidData.discountPercentage ? prepaidData.discountPercentage : ''}}%
                                off with ONLINE PAYMENT.
                            </mat-checkbox> -->
                        </div>
                    </div>

                    <div class="col-lg-12 mb-2">
                        <!-- For Production -->

                        <form (ngSubmit)="payuOldGateWay($event)" #f="ngForm" action="https://secure.payu.in/_payment" method="post">
                            <input type="hidden" name="key" [(ngModel)]="payUObj.key" />
                            <input type="hidden" name="txnid" [(ngModel)]="payUObj.txnid" />
                            <input type="hidden" name="productinfo" [(ngModel)]="payUObj.productinfo" />
                            <input type="hidden" name="amount" [(ngModel)]="payUObj.amount" />
                            <input type="hidden" name="email" [(ngModel)]="payUObj.email" />
                            <input type="hidden" name="firstname" [(ngModel)]="payUObj.firstname" />
                            <input type="hidden" name="lastname" [(ngModel)]="payUObj.lastname" />
                            <input type="hidden" name="surl" [(ngModel)]="payUObj.surl" />
                            <input type="hidden" name="furl" [(ngModel)]="payUObj.furl" />
                            <input type="hidden" name="phone" [(ngModel)]="payUObj.phone" />
                            <input type="hidden" name="hash" [(ngModel)]="payUObj.hash" />

                            <input type="submit" id="payuSubmit" value="PAY WITH PAYU" hidden class="payBtn mb-1" />
                            <div class="payBtn d-flex justify-content-between">
                                <div (click)="payuSubmit()">
                                    <span>PAY NOW</span>
                                    <span>₹{{totalPayable.prepaid}}</span>
                                </div>
                                <div data-toggle="collapse" data-target="#paynow">
                                    <i class="fa fa-info-circle" aria-hidden="true"></i>
                                </div>
                            </div>
                        </form>

                        <!-- For Test action="https://test.payu.in/_payment"-->
                        <!-- <form (ngSubmit)="payuOldGateWay($event)" #f="ngForm" action="https://test.payu.in/_payment" method="post">
                            <input type="hidden" name="key" [(ngModel)]="payUObj.key" />
                            <input type="hidden" name="txnid" [(ngModel)]="payUObj.txnid" />
                            <input type="hidden" name="productinfo" [(ngModel)]="payUObj.productinfo" />
                            <input type="hidden" name="amount" [(ngModel)]="payUObj.amount" />
                            <input type="hidden" name="email" [(ngModel)]="payUObj.email" />
                            <input type="hidden" name="firstname" [(ngModel)]="payUObj.firstname" />
                            <input type="hidden" name="lastname" [(ngModel)]="payUObj.lastname" />
                            <input type="hidden" name="surl" [(ngModel)]="payUObj.surl" />
                            <input type="hidden" name="furl" [(ngModel)]="payUObj.furl" />
                            <input type="hidden" name="phone" [(ngModel)]="payUObj.phone" />
                            <input type="hidden" name="hash" [(ngModel)]="payUObj.hash" />

                            <input type="submit" id="payuSubmit" value="PAY WITH PAYU" hidden class="payBtn mb-1" />
                            <div class="payBtn d-flex justify-content-between">
                                <div (click)="payuSubmit()">
                                    <span>PAY NOW</span>
                                    <span>₹{{totalPayable.prepaid}}</span>
                                </div>
                                <div data-toggle="collapse" data-target="#paynow">
                                    <i class="fa fa-info-circle" aria-hidden="true"></i>
                                </div>
                            </div>
                        </form> -->


                        <!-- <button type="submit" class="payBtn d-flex justify-content-between">
                            <div (click)="payuGateWay()">
                                <span>PAY NOW</span>
                                <span>₹{{totalPayable.prepaid}}</span>
                            </div>
                            <div data-toggle="collapse" data-target="#paynow">
                                <i class="fa fa-info-circle" aria-hidden="true"></i>
                            </div>
                        </button> -->
                        <div class="collapse" id="paynow">
                            <div class="col-12" style="background: #272727;">
                                <table class="table table-borderless" style="margin:0">
                                    <tbody class="font-weight-light text-white">
                                        <tr>
                                            <td style="padding:25px 12px 0px 12px;"><b>Subtotal</b></td>
                                            <td class="text-right" style="padding:25px 12px 0px 12px;">
                                                <b>₹{{gettpriceamont}}</b>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td style="padding-top: 10px;"><b>Logistics Cost </b></td>
                                            <td class="text-right" style="padding-top: 10px;"><b>₹{{prepaidData ?
                                                    prepaidData.prepaid_logisticAmount:
                                                    0}}</b></td>
                                        </tr>

                                        <tr *ngIf="yoursaving">
                                            <td style="padding-top: 10px;"><b>Coupon Discount</b></td>
                                            <td class="text-right" style="padding-top: 10px;">
                                                <b>₹{{!prepaidAmount?(gettpriceamont-getdiscountAmount):(gettpriceamont-getdiscountAmount-prepaidAmount)}}</b>
                                            </td>
                                        </tr>

                                        <!-- <tr *ngIf="coupondiscount">
                                            <td style="padding-top: 10px;"><b>Coupon Flat</b></td>
                                            <td class="text-right" style="padding-top: 10px;"><b>₹{{coupondiscount}}</b></td>
                                        </tr> -->

                                        <tr>
                                            <td style="padding-top: 10px;"><b>Prepaid Discount</b></td>
                                            <!--  {{prepaidData.discountPercentage}}% -->
                                            <td class="text-right" style="padding-top: 10px;"><b>-
                                                    ₹{{prepaidAmount}}</b>
                                            </td>
                                        </tr>

                                        <tr style="cursor: pointer;" (click)="payuGateWay()">
                                            <td style="padding-bottom: 25px;"><b>Total Payable</b></td>
                                            <td class="text-right" style="padding-bottom: 25px;">
                                                <b>₹{{totalPayable.prepaid}}</b>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 mb-3" *ngIf="prepaidData">
                        <!-- <input type="button" (click)="cashonDelivery()" value="CASH ON DELIVERY" class="payBtn mb-3"
                            [disabled]="isPrepaid==false && getdiscountAmount > prepaidData.minAmount && getdiscountAmount < prepaidData.maxAmount && isCODActiveForSpecificUsers ? false : true"> -->

                        <button type="button" class="payBtn d-flex justify-content-between"
                            [disabled]="getdiscountAmount > prepaidData.minAmount && getdiscountAmount < prepaidData.maxAmount && isCODActiveForSpecificUsers ? false : true">
                            <div (click)="cashonDelivery()">
                                <span>CASH ON DELIVERY</span>
                                <span>₹{{totalPayable.cod}}</span>
                                <!-- + (prepaidData ?
                                    prepaidData.cod_logisticAmount:
                                    0) -->
                            </div>
                            <div data-toggle="collapse" data-target="#cod">
                                <i class="fa fa-info-circle" aria-hidden="true"></i>
                            </div>
                        </button>
                        <div class="collapse" id="cod">
                            <div class="col-12" style="background: #272727;">
                                <table class="table table-borderless" style="margin:0">
                                    <tbody class="font-weight-light text-white">
                                        <tr>
                                            <td style="padding:25px 12px 0px 12px;"><b>Subtotal</b></td>
                                            <td class="text-right" style="padding:25px 12px 0px 12px;">
                                                <b>₹{{gettpriceamont}}</b>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td style="padding-top: 10px;"><b>Logistics Cost </b></td>
                                            <td class="text-right" style="padding-top: 10px;"><b>₹{{prepaidData ?
                                                    prepaidData.cod_logisticAmount:
                                                    0}}</b></td>
                                        </tr>

                                        <tr *ngIf="yoursaving">
                                            <td style="padding-top: 10px;"><b>Coupon Discount</b></td>
                                            <td class="text-right" style="padding-top: 10px;">
                                                <b>₹{{!prepaidAmount?(gettpriceamont-getdiscountAmount):(gettpriceamont-getdiscountAmount-prepaidAmount)}}</b>
                                            </td>
                                        </tr>

                                        <!-- <tr *ngIf="coupondiscount">
                                        <td style="padding-top: 10px;"><b>Coupon Flat</b></td>
                                        <td class="text-right" style="padding-top: 10px;"><b>₹{{coupondiscount}}</b></td>
                                    </tr> -->


                                        <tr style="cursor: pointer;" (click)="cashonDelivery()">
                                            <td style="padding-bottom: 25px;"><b>Total Payable</b></td>
                                            <td class="text-right" style="padding-bottom: 25px;">
                                                <b>₹{{totalPayable.cod}}</b>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-lg-12 mb-0" *ngIf="isPrepaid==false && getdiscountAmount > prepaidData.minAmount && getdiscountAmount < prepaidData.maxAmount && isCODActiveForSpecificUsers ? true : false">
                        <input type="button" (click)="cashonDelivery()" value="CASH ON DELIVERY" class="payBtn mb-3" [disabled]="!isCODActiveForSpecificUsers">
                    </div> -->
                    <ng-container *ngIf="!isCODActiveForSpecificUsers; else amountCondition">
                        <div class="col-lg-12 mb-3">
                            <span class="text-danger">
                                COD isn't available for your account, please try a PrePaid Method
                            </span>
                        </div>
                    </ng-container>

                    <ng-template #amountCondition>
                        <div class="col-lg-12 mb-3"
                            *ngIf="prepaidData && (getdiscountAmount > prepaidData.minAmount ? false : true)">
                            <span class="text-danger">
                                COD isn't available on Orders below Rs. {{prepaidData.minAmount}}
                            </span>
                        </div>

                        <div class="col-lg-12 mb-3"
                            *ngIf="prepaidData && (getdiscountAmount < prepaidData.maxAmount ? false : true)">
                            <span class="text-danger">
                                COD isn't available on Orders above Rs. {{prepaidData.maxAmount}}
                            </span>
                        </div>
                    </ng-template>


                    <div class="col-12" style="padding: 1rem 1rem;">
                        <p class="m-0 text-white">*Price included of shipping, handling & GST</p>
                        <p class="m-0 text-white">*Safe payment options & easy returns.</p>
                        <p class="text-white">*100% authentic products</p>
                        <p class="m-0 text-white">Read our T&Cs and Privacy Policy
                            <a routerLink="/policies" target="_blank" style="color: blue;">here.</a>
                        </p>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>

<!-- 🚀 PopUp for Cash On Delivery  -->

<div class="modal fade" bsModal #otpDialog="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
    aria-labelledby="dialog-static-name" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">OTP</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="otpDialog.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" style="text-align: center;">
                <ng-otp-input (onInputChange)="onOtpChange($event)" [config]="config"></ng-otp-input>
                <div *ngIf="timerlimit == true" style="margin-top: 10px;">
                    <p class="text-grey4 px-3">Your verification code will expire in <span style="color: red;"><span
                                *ngIf="timerCount!=undefined">{{timerCount}}</span> seconds</span></p>
                </div>
                <div *ngIf="timerlimit == false" style="margin-top: 10px;">
                    <label style="color: blue;float: left;cursor: pointer;" (click)="resendOTP()">Resend OTP</label>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn" style="background-color: #d31d28;color: white;"
                    (click)="verifyOTP()">Submit</button>
            </div>
        </div>
    </div>
</div>

<!-- 🚀 Mobile OTP Modal PopUp -->

<div class="modal fade" bsModal #mobileOtp="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
    aria-labelledby="dialog-static-name">
    <div class="modal-dialog modal-md modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 id="dialog-static-name" class="modal-title pull-left">OTP</h5>
                <button type="button" class="close pull-right" aria-label="Close" (click)="hideOtpModal()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body text-center">
                <ng-otp-input (onInputChange)="onOtpChangeMobileLogin($event)" [config]="config" #ngOtpInput>
                </ng-otp-input>

                <!-- 🚀 This will show up -->
                <div *ngIf="timerlimit == true" style="margin-top: 10px;">
                    <p class="text-grey4 px-3">Your verification code will expire in <span style="color: red;"><span
                                *ngIf="timerCount!=undefined">{{timerCount}}</span> seconds</span></p>
                </div>

                <!-- 🚀 This will show up when Timer over -->
                <div *ngIf="timerlimit == false" style="margin-top: 10px;">
                    <label style="color: blue;float: left;cursor: pointer;" (click)="resendOTPForMobile()">Resend
                        OTP</label>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn" style="background-color: #d31d28;color: white;"
                    (click)="submitOtp()">Submit</button>
            </div>
        </div>
    </div>
</div>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.9)" size="medium" color="#fff" type="ball-atom">
    <!-- [fullScreen] = "true" -->
    <p style="color: white">
        Please wait
        <!-- while we securely place your order. -->
    </p>
</ngx-spinner>