import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DomainService } from 'src/app/services/domain.service';
import { HttpHeaders } from '@angular/common/http';
import { CommonFunctions } from 'src/app/common.function';
import { CommonService } from 'src/app/services/common.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss']
})
export class CategoryComponent implements OnInit, OnDestroy {

  public sortBy: boolean;
  public filter: boolean;
  public catID: any;
  public slug: string;
  public particularCat: any;
  public catName: string;
  public catDescription: string;
  httpOptions: { headers: HttpHeaders; };


  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public common: DomainService,
    public commService: CommonService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    this.spinner.show();
    this.route.params.subscribe((params) => {
      this.slug = params.slug;
      this.categorySEOGet().finally(() => {
        this.categoryShopping();
      });
    });
  }

  categorySEO() {
    let categoryIndex = CommonFunctions.findKeyValueIndex(this.commService.categorySEO, 'id', this.catID);

    if (categoryIndex) {
      this.commService.updateMetaTages(this.commService.categorySEO[categoryIndex]['title'], [
        { name: 'og:title', content: this.commService.categorySEO[categoryIndex]['title'] },
        { name: 'title', content: this.commService.categorySEO[categoryIndex]['title'] },
        { name: 'og:description', content: this.commService.categorySEO[categoryIndex]['description'] },
        { name: 'description', content: this.commService.categorySEO[categoryIndex]['description'] },
        { name: 'og:image:secure_url', content: this.commService.categorySEO[categoryIndex]['img'] },
        { name: 'image', content: this.commService.categorySEO[categoryIndex]['img'] },
      ]);
    } else {
      this.commService.updateMetaTages(this.catName, [
        { name: 'og:title', content: this.catName },
        { name: 'title', content: this.catName },
        { name: 'og:description', content: this.catName },
        { name: 'description', content: this.catName },
        { name: 'og:image:secure_url', content: 'https://sourceofsupplements.com/assets/icons/SOS-Favicon.png' },
        { name: 'image', content: 'https://sourceofsupplements.com/assets/icons/SOS-Favicon.png' },
      ]);
    }

    this.commService.createCanonicalURL();

  }

  categorySEOGet() {
    return new Promise((resolve, reject) => {
      this.common.categorySEOget().subscribe(res => {
        for (let index = 0; index < res['data'].length; index++) {
          const ele = res['data'][index];
          this.commService.categorySEO.push({
            id: ele.id,
            category: ele.categoryName,
            title: ele.meta_title,
            description: ele.categoryDescription,
            img: ele.category_image
          })
        }
        resolve(true);
      }, err => {
        resolve(false);
      });
    });
  }

  ngOnDestroy() {
    this.common.filterby = null;
  }

  showFilterBox() {
    this.filter = this.filter ? false : true;
    this.sortBy = false;
  }

  showSortBy() {
    this.sortBy = this.sortBy ? false : true;
    this.filter = false;
  }

  paticularCategory() {
    var data = 'all_product=1&shop_by_category=1&categoryId=' + this.catID;
    this.common.filterbyALl(data, 'cat');
  }

  getbrand(get, categoryName) {
    get.brand.brand_name = get.brand.brand_name.replace(/ /g, "-");
    // this.router.navigate([`/product/${get.slug}`]);
  }

  categoryShopping() {
    this.common.shopbyCat(`?slug=${this.slug}`).subscribe(res => {
      if (res['data'].length > 0) {
        let a = res['data'][0];
        try {
          this.catID = a.id;
          this.common.selectedCatID = this.catID;
          this.catName = a.categoryName;
          this.catDescription = a.pageDescription;
          this.paticularCategory();
          this.categorySEO();
        } catch (error) { 
          this.spinner.hide();
        }
      } else {
        this.router.navigate(["/"]);
      }
      this.spinner.hide();
    }, err => { 
      this.spinner.hide();
    })
  }

  cardClick(id){    
    if(id){
      document.getElementById(id).style.display = "block";
      setTimeout(() => {
        document.getElementById(id).style.display = "none";
      }, 300);
    }
  }
}
