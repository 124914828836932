import { Component, OnInit } from '@angular/core';
import { DomainService } from 'src/app/services/domain.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-shop-by-category',
  templateUrl: './shop-by-category.component.html',
  styleUrls: ['./shop-by-category.component.scss']
})
export class ShopByCategoryComponent implements OnInit {

  public catShop: any;
  constructor(public common: DomainService, public router: Router) { }

  ngOnInit() {
    this.categoryShopping();
  }

  categoryShopping() {
    this.common.shopbyCat().subscribe(res => {
      this.catShop = res['data']
    }, err => { })
  }

  catNameID(get) {
    this.router.navigate([`/category/${get.slug}`])
  }

}
