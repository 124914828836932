<div *ngIf="common.showcat && loader">
  <div class="sidebar-title">Filter by Brands:</div>
  <ul class="listItem" *ngIf="common.getbrandvalue">
    <li *ngFor="let i of common.getbrandvalue;let index=index">
      <input type="checkbox" id="brand{{index}}" (change)="filterbrand(i,index)" [(ngModel)]="brandList[index].status"
        [value]="i.brand_name">
      <label class="checkLabel" for="brand{{index}}">{{i.brand_name}}</label>
    </li>
  </ul>
  <div class="sidebar-title">Shop by SubCategory:</div>
  <ul class="listItem" *ngIf="subCategories && subCategoriesList.length>0">
    <li *ngFor="let i of subCategories;let index=index;">
      <input type="checkbox" id="subCat{{index}}" (change)="gotofilter(i.subCategoryName,index)"
        [(ngModel)]="subCategoriesList[index].status" [value]="i.subCategoryName">
      <label class="checkLabel" for="subCat{{index}}"> {{i.subCategoryName}}</label>
    </li>
  </ul>
</div>

<!-- start -->
<div *ngIf="common.showbrand && loader">
  <div class="sidebar-title">Filter by Categories:</div>
  <ul class="listItem" *ngIf="common.getcatevalue">
    <li *ngFor="let i of common.getcatevalue;let index=index">
      <input type="checkbox" id="category{{index}}" (change)="filterSubCategory(i,index)" *ngIf="categoryList[index]"
        [(ngModel)]="categoryList[index].status" [value]="i.categoryName">
      <label class="checkLabel" for="category{{index}}">{{i.categoryName}}</label>
    </li>
  </ul>
  <div class="sidebar-title">Shop by Sub Categories:</div>
  <ul class="listItem" *ngIf="subCategories && subCategoriesList.length>0">
    <li *ngFor="let i of subCategories;let index=index;">
      <input type="checkbox" id="subCat{{index}}" (change)="gotofilter(i.subCategoryName,index)"
        [(ngModel)]="subCategoriesList[index].status" [value]="i.subCategoryName">
      <label class="checkLabel" for="subCat{{index}}">{{i.subCategoryName}}</label>
    </li>
  </ul>
</div>