import { Component, OnInit } from '@angular/core';
import { DomainService } from 'src/app/services/domain.service';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-popular-brands',
  templateUrl: './popular-brands.component.html',
  styleUrls: ['./popular-brands.component.scss']
})
export class PopularBrandsComponent implements OnInit {
  pop1: any;

  constructor(public router:Router,public common:DomainService,public commService: CommonService) { }

  ngOnInit() {
  this.productpopular1();
  }
  
  productpopular1() {
    var data = '';
    this.common.brandget(data).subscribe(res => {
      this.pop1 = res['data'];
      
    }, err => { });
  }

  // getbrand(get){
  //   this.router.navigate([`/brand/${get.slug}`]);
  // }

  cardClick(id){    
    if(id){
      document.getElementById(id).style.display = "block";
      setTimeout(() => {
        document.getElementById(id).style.display = "none";
      }, 300);
    }
  }
}
