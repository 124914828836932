<div class="container-fluid px-0 mt-4" style="position: relative;top: 43px; z-index: 9;" (click)="common.closeNav()">
	<div class="row px-3 m-0 align-items-center">
		<!-- <div class="col-lg-12">
			<div class="row">
				<div class="col-md-12 col-lg-12 whySOSback">
					<h2 class="bigTile">Why SOS?</h2>
					<p>Source Of Supplements&nbsp;is the only online &amp; offline supplement store in India that can
						truly guarantee
						authenticity. In fact, we are so confident about it that we claim if any purchase is made by a
						customer and he/she
						does not get a bill on the spot then the product will be completely free and full refund will be
						made or the same
						product will be given for free. When purchasing from our website or our store, you never have to
						worry about getting
						a&nbsp;harmful substance and be 100% confident on what you get.&nbsp;</p>
				</div>
			</div>
		</div> -->

		<!-- <div class="col-lg-12 mt-3 p-0 subscribeRed">
			<div class="row p-4">
				<div class="col-lg-12">
					<h3 class="interestedText">Interested in special offers?</h3>
					<p class="promotext">Stay updated with amazing discounts, offers and promotions.</p>
					<input type="button" value="SUBSCRIBE" class="subscribeBtn" (click)="subscribe.show()">
				</div>
			</div>
			<div class="socialMedia w-100 text-center">
				<a href="https://instagram.com/sourceofsupplements?igshid=1aite8nlufq6c" target="blank"><i
						class="socialIcons fa fa-instagram"></i></a>
				<a href="mailto:support@sourceofsupplements.com"><i class="socialIcons fa fa-envelope"></i></a>
			</div>
		</div> -->

	</div>
</div>

<div class="container-fluid px-0" style="background-color: #0e0d0d!important;" (click)="common.closeNav()">
	<div class="row px-5 pt-4 justify-content-between">
		<div class="col-lg-7 col-md-7 col-sm-7 col-xs-12 p-0">
			<h5 class="footHeading">CONTACT US</h5>
			<p class="footerText">
				<a class="text-white" href="mailto:support@sourceofsupplements.com">support@sourceofsupplements.com</a>
			</p>
			<p class="footerText">
				<!-- Unit No. 2, Diamoda Gold,
				<span class="d-block">Tanvi Complex,</span>
				<span class="d-block">Besides HP Petrol Pump,</span>
				<span class="d-block">Swami
					Vivekananda Marg,</span>
				<span class="d-block">Dahisar East, Mumbai,</span>
				<span class="d-block">Maharashtra - 400068</span> -->

				<a href="https://instagram.com/sourceofsupplements?igshid=1aite8nlufq6c" title="Instagram" target="blank"><i
					class="socialIcons fa fa-instagram"></i></a>

				<a href="https://www.facebook.com/sourceofsupplementsindia/" title="Facebook" target="blank">
					<i class="socialIcons fa fa-facebook"></i>
				</a>

				<a href="mailto:support@sourceofsupplements.com" title="Mail"><i class="socialIcons fa fa-envelope"></i></a>


			</p>
		</div>

		<div class="col-lg-5 col-md-5 col-sm-5 col-xs-12 p-0 show-underline">
			<h5 class="footHeading">CUSTOMER SERVICE</h5>
			<a class="footerText mb-2 d-block mt-0" routerLink="/partner-with-sos"><span>Partner with SOS</span></a>
			<a class="footerText mb-2 d-block mt-0" routerLink="/store-locator"><span>Store Locator</span></a>
			<a class="footerText mb-2 d-block mt-0" routerLink="/policies"><span>Refund & Return</span></a>
			<a class="footerText mb-2 d-block mt-0" routerLink="/contact-us"><span>Contact Us</span></a>
			<a class="footerText mb-2 d-block mt-0" routerLink="/blogs"><span>Blogs</span></a>
			<a class="footerText mb-2 d-block mt-0" routerLink="/faq"><span>FAQ</span></a>
		</div>

		<div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-0 show-underline">
			<h5 class="footHeading">INFORMATION</h5>
			<!-- <a class="footerText mb-2 d-block mt-0" routerLink="/about-us"><span>About Us</span></a> -->
			<a class="footerText mb-2 d-block mt-0" routerLink="/certificates"><span>Certificates</span></a>
			<a class="footerText mb-2 d-block mt-0" routerLink="/policies"><span>Privacy Policy</span></a>
			<a class="footerText mb-2 d-block mt-0" routerLink="/policies"><span>Terms & Conditions</span></a>
			<a class="footerText mb-2 d-block mt-0" routerLink="/sold-out-products"><span>Sold Out</span></a>
		</div>

		<div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 copyRightSOS">
			<p class="footCompanyName ">&copy; Source of Supplements</p>
		</div>

		<div class="col-lg-l2 col-md-12 col-sm-12 col-xs-12 text-center">
				<img src="assets/images/startupIndia.png" height="60" />
		</div>
	</div>
</div>


<!-- Newsletter Subscribe -->

<div class="modal fade" bsModal #subscribe="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
	aria-labelledby="dialog-static-name">
	<div class="modal-dialog modal-md">
		<div class="modal-content">
			<form [formGroup]="newsletterForm" class="w-100" (ngSubmit)="onSubmit()">
				<div class="modal-header">
					<h4 id="dialog-static-name" class="modal-title pull-left">Newsletter</h4>
					<button type="button" class="close pull-right" aria-label="Close" (click)="subscribe.hide()">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body">
					<div class="row">
						<div class="col-12">
							<div class="form-group">
								<label class="control-label">Name</label>
								<div class="append-icon">
									<input type="text" class="form-control" placeholder="Enter Name"
										formControlName="name">
								</div>
							</div>
						</div>

						<div class="col-12">
							<div class="form-group">
								<label class="control-label">Email</label>
								<div class="append-icon">
									<input type="email" class="form-control" placeholder="Enter Email"
										formControlName="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$">
								</div>
							</div>
						</div>

						<div class="col-12">
							<div class="form-group">
								<label class="control-label">Phone</label>
								<div class="append-icon">
									<input type="text" class="form-control" placeholder="Enter Phone"
										formControlName="phone" minlength="10" maxlength="10"
										(keypress)="numberOnly($event)" pattern="[0-9]{10}">
								</div>
							</div>
						</div>

						<div class="col-12">
							<input type="checkbox"> From time to time, SOS would like to send you offers and
							information
							we think you'd be interested in. If you <strong>do</strong> wish to receive these,
							please untick the box.
						</div>
					</div>
				</div>
				<div class="modal-footer">
					<button class="btn btn-danger">SUBMIT</button>
				</div>
			</form>
		</div>
	</div>
</div>