import { Component, OnInit } from '@angular/core';
import { DomainService } from 'src/app/services/domain.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

declare var $: any;
// declare var Razorpay: any;

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss']
})
export class MyAccountComponent implements OnInit {
  public editAccount: boolean;
  public editAddress: boolean;
  // httpOptions: { headers: HttpHeaders; };
  getdata: any;
  showedit: any;
  Addressname: any;
  mobilenum: any;
  house_number: any;
  street_address: any;
  area: any;
  city: any;
  pincode: any;
  state: any;
  country: any;
  Addressid: any;
  public orderget: any[] = [];

  // public razorpay_payment_id: any;
  // public razorpaystore: any
  orderdatetime: any = [];
  orderid: any;
  amt: any;
  selectdata: string[];

  /* New Ui Changes Variable */

  myAccountDetails: FormGroup;
  myAccountAddress: FormGroup;

  primeMonth: any;
  activeFrom: any;
  activeTo: any;
  primePrice: any;

  /* 🚀 New Ui Changes Variable */

  constructor(
    public common: DomainService,
    public router: Router,
    public toastr: ToastrService,
    public sidservice: CommonService,
    public formBuilder: FormBuilder
  ) { }

  ngOnInit() {
    this.getMyAccountDetailsInitialize();
    this.common.setHeader().finally(() => {
      this.getsos();
      this.selectdata = this.sidservice.states;
    })

  }

  getToday(): string {
    return new Date().toISOString().split('T')[0]
  }

  getsos() {
    if (this.common.userdetails) {
      this.getorderapi();
      this.getUserDetails();
    }
  }


  getorderapi() {
    this.common.getorder(this.common.httpOptions).subscribe(res => {

      this.orderget = res['data'];

      for (let order of this.orderget) {
        for (let product of order.order_products) {
          product.createdAt = new Date(product.createdAt).toLocaleDateString(undefined, { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });
        }
      }
      this.orderget = this.orderget.reverse();
    }, err => { })

  }


  /* 🚀 New Changes */

  /* 🚀 This Function will initialize form */
  getMyAccountDetailsInitialize() {
    this.myAccountDetails = this.formBuilder.group({
      mobileNumber: new FormControl(''),
      name: new FormControl('', [Validators.minLength(3), Validators.maxLength(50), Validators.required]),
      email: new FormControl('', Validators.compose([Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")])),
      dob: new FormControl('', [Validators.required])
    });

    this.myAccountAddress = this.formBuilder.group({
      address: new FormControl('', [Validators.required]),
      pinCode: new FormControl('', [Validators.required]),
      city: new FormControl('', [Validators.required]),
      state: new FormControl('', [Validators.required]),
      country: new FormControl('INDIA', [Validators.required])
    });
  }

  /* 🚀 This Function will save or update user detail and shipping address */
  saveOrUpdateMyAccountDetails(value) {

    if (value == 'myAccount') {
      if (this.myAccountDetails.status == "INVALID") {
        this.toastr.error('Fields is Required');
        return false;
      } else {
        let body = {
          name: this.myAccountDetails.value.name,
          email: this.myAccountDetails.value.email,
          dateOfBirth: this.myAccountDetails.value.dob,
          address: this.myAccountAddress.value.address || null,
          pinCode: this.myAccountAddress.value.pinCode || null,
          city: this.myAccountAddress.value.city || null,
          state: this.myAccountAddress.value.state || null,
          id: this.common.userdetails.userID
        }

        if (this.common.userdetails) {
          this.common.saveOrUpdateMyAccount(body, this.common.httpOptions).subscribe(res => {
            if (res) {
              this.common.userDetailsObserver.next({
                'userID': this.common.userdetails.userID,
                'name': this.myAccountDetails.value.name,
                'token': this.common.userdetails.token,
                'email': this.myAccountDetails.value.email,
                'isPrimeMember': this.common.userdetails.isPrimeMember
              })
              this.toastr.success(res['message']);
              this.getUserDetails();
            }
          });
        }
      }
    } else {

      if (this.myAccountAddress.status == "INVALID") {
        this.toastr.error('Fields is Required');
        return false;
      } else {
        let body = {
          name: this.myAccountDetails.value.name || null,
          email: this.myAccountDetails.value.email || null,
          dateOfBirth: this.myAccountDetails.value.dob || null,
          address: this.myAccountAddress.value.address,
          pinCode: this.myAccountAddress.value.pinCode,
          city: this.myAccountAddress.value.city,
          state: this.myAccountAddress.value.state,
          id: this.common.userdetails.userID
        }

        if (this.common.userdetails) {
          this.common.saveOrUpdateMyAccount(body, this.common.httpOptions).subscribe(res => {
            if (res) {
              this.toastr.success(res['message']);
              this.getUserDetails();
            }
          });
        }
      }
    }
  }

  /* 🚀 This Function will get City and State based on user Pin code */
  getUserDetailBasedOnPINCode() {
    var data = {
      'pincode': this.myAccountAddress.value.pinCode
    }
    this.common.pincode(data).subscribe(res => {
      if (res) {
        this.myAccountAddress.patchValue({
          city: res['result']['data'][this.myAccountAddress.value.pinCode].city_name,
          state: res['result']['data'][this.myAccountAddress.value.pinCode].state_name
        })
      }
    }, err => { });
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  getUserDetails() {
    this.common.getUserAccountDetails().then(res => {
      if (res) {
        this.myAccountDetails.patchValue({
          mobileNumber: res['data']['mobile_number'],
          name: res['data']['name'],
          email: res['data']['email'],
          dob: res['data']['dateOfBirth']
        })

        this.myAccountAddress.patchValue({
          address: res['data']['address'],
          pinCode: res['data']['pinCode'],
          city: res['data']['city'],
          state: res['data']['state']
        });

        if(res['data']['sosPrimeOrders'].length>0 && res['data']['sosPrimeOrders'][0]['sosPrimeProduct']){
          this.primeMonth = res['data']['sosPrimeOrders'][0]['sosPrimeProduct']['primeMonth'];
          this.primePrice = res['data']['sosPrimeOrders'][0]['sosPrimeProduct']['primePrice'];
          this.activeFrom = res['data']['sosPrimeOrders'][0]['startDate'];
          this.activeTo = res['data']['sosPrimeOrders'][0]['endDate'];
        }
      }
    });
  }

  /* 🚀 New Changes */

}
