import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mobilefilter',
  templateUrl: './mobilefilter.component.html',
  styleUrls: ['./mobilefilter.component.scss']
})
export class MobilefilterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  reset(){
    location.reload();
  }

}
