import { Component, OnInit, ViewChild } from '@angular/core';
import { DomainService } from 'src/app/services/domain.service';
import { ToastrService } from 'ngx-toastr';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
declare var $: any;
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  @ViewChild('subscribe') subscribe: ModalDirective;

  newsletterForm: FormGroup;

  constructor(public common: DomainService, public toastr: ToastrService, public formBuilder: FormBuilder) { }

  ngOnInit() {
    this.formValidationInit();
  }

  formValidationInit() {
    this.newsletterForm = this.formBuilder.group({
      name: [''],
      email: [''],
      phone: ['']
    })
  }

  onSubmit() {

    if (this.newsletterForm.value.email == '' && this.newsletterForm.value.phone == '') {
      this.toastr.error('Field is required');
      return;
    }

    if (this.newsletterForm.valid) {
      let obj = { name: this.newsletterForm.value.name, emailID: this.newsletterForm.value.email, phoneNumber: this.newsletterForm.value.phone, active: 1 }

      this.common.newsLetterSubscribe(obj).subscribe(res => {
        if (res['message'] == 'Congratulations! You have been registered onto our newsletter.') {
          this.toastr.success(res['message']);
          this.newsletterForm.reset();
          this.subscribe.hide();
          this.formValidationInit();
        }
      }, err => {
        this.toastr.error('Something Went Wrong');
      });
    } else {
      this.toastr.error('Check Email or Phone');
    }
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

}
