import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { AuthModule } from './auth/auth.module';
import { SharedModule } from './shared/shared.module';
import { ShopModule } from './shop/shop.module';
import { BlogModule } from './blog/blog.module';
import { ShopByBrandModule } from './shop-by-brand/shop-by-brand.module';
import { ShopByCategoryModule } from './shop-by-category/shop-by-category.module';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TrendingInModule } from './trending-in/trending-in.module';
import { AgmCoreModule } from '@agm/core';
import { ProductPageComponent } from './product-page/product-page.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { MatSelectModule } from '@angular/material/select';
import { NgxSpinnerModule } from "ngx-spinner";
import { CommonModule } from '@angular/common';
// import { TransferHttpCacheModule } from '@nguniversal/common';
import { HttpClientModule } from '@angular/common/http';
// import { NgtUniversalModule } from '@ng-toolkit/universal';
import { ScullyLibModule } from '@scullyio/ng-lib';
// import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { PinchZoomModule } from 'ngx-pinch-zoom';
import { BlogDetaliComponent } from './blog-detali/blog-detali.component';
import { ImageLazyLoadModule } from './directive/image-lazy-load.module';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgxStarsModule } from 'ngx-stars';
import { NgxUsefulSwiperModule } from 'ngx-useful-swiper';
import { MyJsonLdComponent } from './SEO/my-json-ld.component';
import { InfluencerComponent } from './influencer/influencer.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ProductPageComponent,
    BlogDetaliComponent,
    MyJsonLdComponent,
    InfluencerComponent,
  ],
  imports: [
    MatSelectModule,
    Ng2SearchPipeModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    FormsModule,
    AuthModule,
    SharedModule,
    ReactiveFormsModule,
    ShopModule,
    BlogModule,
    ShopByBrandModule,
    ShopByCategoryModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 2500,
      preventDuplicates: true,
      disableTimeOut: false,
    }),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBSsNL_RP0GhjkdBXefAlLIAw3yuJ6J2A8'
    }),
    TrendingInModule,
    NgxSpinnerModule,
    CommonModule,
    // TransferHttpCacheModule,
    HttpClientModule,
    // NgtUniversalModule,
    ScullyLibModule,
    PinchZoomModule,
    ImageLazyLoadModule,
    ModalModule.forRoot(),
    NgxStarsModule,
    NgxUsefulSwiperModule
    /* ServiceWorkerModule.register("/ngsw-worker.js", {
      enabled: environment.production,
      registrationStrategy: "registerImmediately"
    }) */
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    { provide: 'googleTagManagerId', useValue: 'GTM-57RVS7D' },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
