<app-header></app-header>

<div class="container-fluid" (click)="common.closeNav()">
  <!-- top banner start -->
  <div class="row">
    <div class="col-12 p-0">
      <swiper [config]="config">
        <div class="swiper-wrapper">
          <div *ngFor="let i of silder" class="swiper-slide" (click)="sliderClick(i.id,i.link)">
            <!-- <a  target="_blank" class="w-100"> -->
            <img width="600" height="270" [src]="i.slider_path" onerror="this.src='assets/images/notfound.jpg'"
              class="img-fluid" alt="{{i.altText}}">
            <!-- </a> -->
          </div>
        </div>
        <!-- Add Pagination -->
        <div class="swiper-pagination"></div>
      </swiper>
    </div>
  </div>
  <!-- top banner end -->

  <!--Trending in Protein start-->
  <div class="row">
    <div class="col-12 pt-2 px-1">
      <h1 class="trendingInCSS pl-2 pt-2">Trending in Protein</h1>
      <p class="viewallCSS pl-2" (click)="routingProtein()" style="margin-bottom: 0;height: 20px;">
        <span>View All <i class="fa fa-caret-right fa-lg" aria-hidden="true"></i>
        </span>
      </p>
    </div>

    <div class="col-12">
      <swiper [config]="commonConfig">
        <div class="swiper-wrapper">
          <div class="swiper-slide" *ngFor="let i of trending">
            <div class="card border-0">
              <div class="full-width-link" [id]="i.id"></div>
              <div class="card-body p-0" (click)="cardClick(i.id)">
                <a href="{{commService.currentHost}}/product/{{i.slug}}">
                  <div class="discountCircleHome">
                    <p class="discountText">
                      {{(((i.variantPrice - i.variantSalePrice) / i.variantPrice) * 100).toFixed(0)}}%
                    </p>
                  </div>

                  <img width="230" height="230" class="img-fluid" appLazyLoad
                    src="{{i.productVariantPictures.length>0?i.productVariantPictures[0].productVariantThumbnail:'assets/loader/200.gif'}}"
                    onerror="this.src='assets/loader/200.gif'">
                </a>
              </div>
              <div class="card-footer w-100 p-0 bg-white border-0">
                <div class="variantNamecontain">
                  <p class="card-text variantName text-ellipsis" style="margin-bottom: 0px; font-size: 14px;">
                    {{i.variantName}}</p>
                </div>
                <p class="card-text">
                  <del style="color: black;"><b>₹{{i.variantPrice}}</b></del>
                  &nbsp;
                  <b style="color: #d31d28;">₹{{i.variantSalePrice}}</b>
                  <input *ngIf="i.activeVariant" type="button" class="addCart" value="Add to Cart"
                    (click)="common.addtocart(i.id, i.slug)">


                  <!-- <p class="card-text variantName1 prime">
                  <img src="assets/icons/crown.png" height="25" width="25">
                  <strong>Prime Price: ₹{{i.primePrice?i.primePrice:'0'}} </strong>
                </p> -->


                <p *ngIf="!i.activeVariant" class="soldOut">Sold Out</p>
              </div>

            </div>
          </div>
        </div>
        <div class="swiper-button-next"></div>
        <div class="swiper-button-prev"></div>
      </swiper>
    </div>
  </div>
  <!--Trending in Protein end-->

  <!-- Popular Brands strt -->
  <div class="row">
    <div class="col-12 pt-2 px-1">
      <h1 class="trendingInCSS pl-2 pt-2">Popular Brands</h1>
      <p class="viewallCSS pl-2 mb-0" (click)="routingPopularBrands()" style="height: 20px;">
        <span>View All <i class="fa fa-caret-right fa-lg" aria-hidden="true"></i>
        </span>
      </p>
    </div>

    <div class="col-12">
      <div class="row">
        <div class="col-4" *ngFor="let j of pop1">
          <div class="card border-0" (click)="cardClick(j.id)">
            <div class="full-width-link" [id]="j.id"></div>
            <a href="{{commService.currentHost}}/brand/{{j.slug}}">
              <div class="card-body p-1 p-md-4">
                <img width="122" height="122" class="card-img-top img-fluid" appLazyLoad [src]="j.brand_image"
                  onerror="this.src='assets/loader/200.gif'">
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Popular Brands end -->

  <!-- banner1 img -->
  <div class="row text-center" *ngIf="banners && banners.length && banners[0]">
    <div class="col-12 p-0" (click)="bannerClick(banners[0].id, banners[0].link)">
      <!-- <a href="{{banners[0].link}}" target="_blank"> -->
      <img width="600" height="270" [src]="banners[0].mobile_banner_photo" appLazyLoad
        onerror="this.src='assets/images/notfound.jpg'" class="img-fluid cursor" alt="{{banners[0].altText}}">
      <!-- </a> -->
    </div>
  </div>
  <!-- banner1 img -->

  <!-- Trending in Intra-Workouts start-->
  <div class="row">
    <div class="col-12 pt-2 px-1">
      <h1 class="trendingInCSS pl-2 pt-2">Trending in Intra-Workouts</h1>
      <p class="viewallCSS pl-2" (click)="routingIntraworkouts()">
        <span>View All <i class="fa fa-caret-right fa-lg" aria-hidden="true"></i>
        </span>
      </p>
    </div>

    <div class="col-12">
      <swiper [config]="commonConfig">
        <div class="swiper-wrapper">
          <div class="swiper-slide" *ngFor="let t1 of trending1">
            <div class="card border-0">
              <div class="full-width-link" [id]="t1.id"></div>
              <div class="card-body p-0" (click)="cardClick(t1.id)">
                <a href="{{commService.currentHost}}/product/{{t1.slug}}">
                  <div class="discountCircleHome">
                    <p class="discountText">
                      {{(((t1.variantPrice - t1.variantSalePrice) / t1.variantPrice) * 100).toFixed(0)}}%</p>
                  </div>

                  <img width="230" height="230" class="img-fluid"
                    src="{{t1.productVariantPictures.length>0?t1.productVariantPictures[0].productVariantThumbnail:'assets/loader/200.gif'}}"
                    appLazyLoad onerror="this.src='assets/loader/200.gif'">
                </a>

              </div>
              <div class="card-footer w-100 p-0 bg-white border-0">
                <p class="card-text variantName text-ellipsis" style="margin-bottom: 0px; font-size: 14px;">
                  {{t1.variantName}}</p>
                <p class="card-text">
                  <del style="color: black;"><b>₹{{t1.variantPrice}}</b></del>&nbsp;
                  <b style="color: #d31d28;">₹{{t1.variantSalePrice}}</b>
                  <input *ngIf="t1.activeVariant" type="button" class="addCart" value="Add to Cart"
                    (click)="common.addtocart(t1.id, t1.slug)">
                </p>

                <!-- 🚀 Prime price -->
                <!-- <p class="card-text variantName1 prime">
                  <img src="assets/icons/crown.png" height="25" width="25">
                  <strong>Prime Price: ₹{{t1.primePrice?t1.primePrice:'0'}} </strong>
                </p> -->
                <!-- 🚀 Prime price end-->
                <p *ngIf="!t1.activeVariant" class="soldOut">Sold Out</p>
              </div>
            </div>
          </div>
        </div>
        <!-- Add Arrows -->

        <div class="swiper-button-next"></div>
        <div class="swiper-button-prev"></div>

      </swiper>
    </div>

  </div>
  <!-- Trending in Intra-Workouts end-->

  <!-- Trending in Gainers start-->
  <div class="row">
    <div class="col-12 pt-2 px-1">
      <h1 class="trendingInCSS pl-2 pt-2">Trending in Gainers</h1>
      <p class="viewallCSS pl-2" (click)="routingGainers()">
        <span>View All <i class="fa fa-caret-right fa-lg" aria-hidden="true"></i>
        </span>
      </p>
    </div>

    <div class="col-12">
      <swiper [config]="commonConfig">
        <div class="swiper-wrapper">
          <div class="swiper-slide" *ngFor="let t2 of trending2">
            <div class="card border-0">
              <div class="full-width-link" [id]="t2.id"></div>
              <div class="card-body p-0" (click)="cardClick(t2.id)">
                <a href="{{commService.currentHost}}/product/{{t2.slug}}">
                  <div class="discountCircleHome">
                    <p class="discountText">
                      {{(((t2.variantPrice - t2.variantSalePrice) / t2.variantPrice) * 100).toFixed(0)}}%</p>
                  </div>
                  <img width="230" height="230" class="img-fluid"
                    src="{{t2.productVariantPictures.length>0?t2.productVariantPictures[0].productVariantThumbnail:'assets/loader/200.gif'}}"
                    appLazyLoad onerror="this.src='assets/loader/200.gif'">
                </a>

              </div>
              <div class="card-footer w-100 p-0 bg-white border-0">
                <p class="card-text variantName text-ellipsis" style="margin-bottom: 0px; font-size: 14px;">
                  {{t2.variantName}}</p>
                <p class="card-text">
                  <del style="color: black;"><b>₹{{t2.variantPrice}}</b></del>&nbsp;
                  <b style="color: #d31d28;">₹{{t2.variantSalePrice}}</b>
                  <input *ngIf="t2.activeVariant" type="button" class="addCart" value="Add to Cart"
                    (click)="common.addtocart(t2.id, t2.slug)">

                  <!-- 🚀 Prime price -->
                  <!-- <p class="card-text variantName1 prime">
                  <img src="assets/icons/crown.png" height="25" width="25">
                  <strong>Prime Price: ₹{{t2.primePrice?t2.primePrice:'0'}} </strong>
                </p> -->
                  <!-- 🚀 Prime price end-->
                <p *ngIf="!t2.activeVariant" class="soldOut" style="margin-bottom: 10px;">Sold Out</p>
              </div>
            </div>
          </div>
        </div>
        <!-- Add Arrows -->

        <div class="swiper-button-next"></div>
        <div class="swiper-button-prev"></div>

      </swiper>
    </div>

  </div><br />
  <!-- Trending in Gainers end-->


  <!-- banner2 bnner img -->
  <div class="row text-center" *ngIf="banners && banners.length && banners[1]">
    <div class="col-12 p-0" (click)="bannerClick(banners[1].id, banners[1].link)">
      <!-- <a href="{{banners[1].link}}" target="_blank"> -->
      <img width="600" height="270" [src]="banners[1].mobile_banner_photo" appLazyLoad
        onerror="this.src='assets/images/notfound.jpg'" class="img-fluid cursor">
      <!-- </a> -->
    </div>

  </div>
  <!-- banner2 banner img -->

  <!-- Trending in Pre-Workouts start-->
  <div class="row">
    <div class="col-12 pt-2 px-1">
      <h1 class="trendingInCSS pl-2 pt-2">Trending in Pre-Workouts</h1>
      <p class="viewallCSS pl-2" (click)="routingPreworkouts()">
        <span>View All <i class="fa fa-caret-right fa-lg" aria-hidden="true"></i>
        </span>
      </p>
    </div>

    <div class="col-12">
      <swiper [config]="commonConfig">
        <div class="swiper-wrapper">
          <div class="swiper-slide" *ngFor="let t3 of trending3">
            <div class="card border-0">
              <div class="full-width-link" [id]="t3.id"></div>
              <div class="card-body p-0" (click)="cardClick(t3.id)">
                <a href="{{commService.currentHost}}/product/{{t3.slug}}">
                  <div class="discountCircleHome">
                    <p class="discountText">
                      {{(((t3.variantPrice - t3.variantSalePrice) / t3.variantPrice) * 100).toFixed(0)}}%</p>
                  </div>
                  <img width="230" height="230" class="img-fluid"
                    src="{{t3.productVariantPictures.length>0?t3.productVariantPictures[0].productVariantThumbnail:'assets/loader/200.gif'}}"
                    appLazyLoad onerror="this.src='assets/loader/200.gif'">
                </a>

              </div>
              <div class="card-footer w-100 p-0 bg-white border-0">
                <p class="card-text variantName text-ellipsis" style="margin-bottom: 0px; font-size: 14px;">
                  {{t3.variantName}}</p>
                <p class="card-text">
                  <del style="color: black;"><b>₹{{t3.variantPrice}}</b></del>&nbsp;
                  <b style="color: #d31d28;">₹{{t3.variantSalePrice}}</b>
                  <input *ngIf="t3.activeVariant" type="button" class="addCart" value="Add to Cart"
                    (click)="common.addtocart(t3.id, t3.slug)">

                  <!-- 🚀 Prime price -->
                  <!-- <p class="card-text variantName1 prime">
                  <img src="assets/icons/crown.png" height="25" width="25">
                  <strong>Prime Price: ₹{{t3.primePrice?t3.primePrice:'0'}} </strong>
                </p> -->
                  <!-- 🚀 Prime price end-->
                <p *ngIf="!t3.activeVariant" class="soldOut">Sold Out</p>
              </div>
            </div>
          </div>
        </div>
        <!-- Add Arrows -->

        <div class="swiper-button-next"></div>
        <div class="swiper-button-prev"></div>

      </swiper>
    </div>
  </div>
  <!-- Trending in Pre-Workouts end-->


  <!-- Trending in Vitamins & Health start-->
  <div class="row">
    <div class="col-12 pt-2 px-1">
      <h1 class="trendingInCSS pl-2 pt-2">Trending in Vitamins & Health</h1>
      <p class="viewallCSS pl-2" (click)="routingVitamins()">
        <span>View All <i class="fa fa-caret-right fa-lg" aria-hidden="true"></i>
        </span>
      </p>
    </div>

    <div class="col-12">
      <swiper [config]="commonConfig">
        <div class="swiper-wrapper">
          <div class="swiper-slide" *ngFor="let t4 of trending4">
            <div class="card border-0">
              <div class="full-width-link" [id]="t4.id"></div>
              <div class="card-body p-0" (click)="cardClick(t4.id)">
                <a href="{{commService.currentHost}}/product/{{t4.slug}}">
                  <div class="discountCircleHome">
                    <p class="discountText">
                      {{(((t4.variantPrice - t4.variantSalePrice) / t4.variantPrice) * 100).toFixed(0)}}%</p>
                  </div>
                  <img width="230" height="230" class="img-fluid"
                    src="{{t4.productVariantPictures.length>0?t4.productVariantPictures[0].productVariantThumbnail:'assets/loader/200.gif'}}"
                    appLazyLoad onerror="this.src='assets/loader/200.gif'">
                </a>

              </div>
              <div class="card-footer w-100 p-0 bg-white border-0">
                <p class="card-text variantName text-ellipsis" style="margin-bottom: 0px; font-size: 14px;">
                  {{t4.variantName}}</p>
                <p class="card-text">
                  <del style="color: black;"><b>₹{{t4.variantPrice}}</b></del>&nbsp;
                  <b style="color: #d31d28;">₹{{t4.variantSalePrice}}</b>
                  <input *ngIf="t4.activeVariant" type="button" class="addCart" value="Add to Cart"
                    (click)="common.addtocart(t4.id, t4.slug)">

                  <!-- 🚀 Prime price -->
                  <!-- <p class="card-text variantName1 prime">
                  <img src="assets/icons/crown.png" height="25" width="25">
                  <strong>Prime Price: ₹{{t4.primePrice?t4.primePrice:'0'}} </strong>
                </p> -->
                  <!-- 🚀 Prime price end-->
                <p *ngIf="!t4.activeVariant" class="soldOut">Sold Out</p>
              </div>
            </div>
          </div>
        </div>
        <!-- Add Arrows -->

        <div class="swiper-button-next"></div>
        <div class="swiper-button-prev"></div>

      </swiper>
    </div>

  </div> <br />
  <!-- Trending in Vitamins & Health end-->

  <!-- banner3 img -->
  <div class="row text-center" *ngIf="banners && banners.length && banners[2]">

    <div class="col-12 p-0" (click)="bannerClick(banners[2].id, banners[2].link)">
      <!-- <a href="{{banners[2].link}}" target="_blank"> -->
      <img width="600" height="270" src="{{banners[2].mobile_banner_photo}}" appLazyLoad class="img-fluid cursor">
      <!-- </a> -->
    </div>

  </div>
  <!-- banner3 img -->



  <!--Cutler Nutrition-->
  <div class="row" *ngIf="findBrandByName('Cutler Nutrition').length>0">
    <div class="col-12 pt-2 px-1">
      <h1 class="trendingInCSS pl-2 pt-2">Cutler Nutrition</h1>
      <p class="viewallCSS pl-2" (click)="routingBrands(findBrandByName('Cutler Nutrition')[0].brandid.slug)">
        <span>View All <i class="fa fa-caret-right fa-lg" aria-hidden="true"></i>
        </span>
      </p>
    </div>
    <div class="col-12">
      <swiper [config]="commonConfig">
        <div class="swiper-wrapper">
          <div class="swiper-slide" *ngFor="let i of findBrandByName('Cutler Nutrition')[0].data">
            <div class="card border-0">
              <div class="full-width-link" [id]="i.id"></div>
              <div class="card-body p-0" (click)="cardClick(i.id)">
                <a href="{{commService.currentHost}}/product/{{i.slug}}">
                  <div class="discountCircleHome">
                    <p class="discountText">
                      {{(((i.variantPrice - i.variantSalePrice) / i.variantPrice) * 100).toFixed(0)}}%</p>
                  </div>

                  <img width="230" height="230" class="img-fluid"
                    src="{{i.productVariantPictures.length>0?i.productVariantPictures[0].productVariantThumbnail:'assets/loader/200.gif'}}"
                    onerror="this.src='assets/loader/200.gif'">
                </a>
              </div>
              <div class="card-footer  w-100 p-0 bg-white border-0">
                <div class="variantNamecontain">
                  <p class="card-text variantName text-ellipsis" style="margin-bottom: 0px; font-size: 14px;">
                    {{i.variantName}}</p>
                </div>
                <p class="card-text">
                  <del style="color: black;"><b>₹{{i.variantPrice}}</b></del>&nbsp;
                  <b style="color: #d31d28;">₹{{i.variantSalePrice}}</b>
                  <input *ngIf="i.activeVariant" type="button" class="addCart" value="Add to Cart"
                    (click)="common.addtocart(i.id, i.slug)">

                  <!-- 🚀 Prime price -->
                  <!-- <p class="card-text variantName1 prime">
                  <img src="assets/icons/crown.png" height="25" width="25">
                  <strong>Prime Price: ₹{{i.primePrice?i.primePrice:'0'}} </strong>
                </p> -->
                  <!-- 🚀 Prime price end-->
                <p *ngIf="!i.activeVariant" class="soldOut">Sold Out</p>
              </div>
            </div>
          </div>
        </div>

        <div class="swiper-button-next"></div>
        <div class="swiper-button-prev"></div>

      </swiper>
    </div>
  </div>
  <!--Cutler Nutrition-->

  <!--Muscletech-->
  <div class="row" *ngIf="findBrandByName('Muscle Tech').length>0">
    <div class="col-12 pt-2 px-1">
      <h1 class="trendingInCSS pl-2 pt-2">Muscletech</h1>
      <p class="viewallCSS pl-2" (click)="routingBrands(findBrandByName('Muscle Tech')[0].brandid.slug)">
        <span>View All <i class="fa fa-caret-right fa-lg" aria-hidden="true"></i>
        </span>
      </p>
    </div>
    <div class="col-12">
      <swiper [config]="commonConfig">
        <div class="swiper-wrapper">
          <div class="swiper-slide" *ngFor="let i of findBrandByName('Muscle Tech')[0].data">
            <div class="card border-0">
              <div class="full-width-link" [id]="i.id"></div>
              <div class="card-body p-0" (click)="cardClick(i.id)">
                <a href="{{commService.currentHost}}/product/{{i.slug}}">
                  <div class="discountCircleHome">
                    <p class="discountText">
                      {{(((i.variantPrice - i.variantSalePrice) / i.variantPrice) * 100).toFixed(0)}}%</p>
                  </div>

                  <img width="230" height="230" class="img-fluid"
                    src="{{i.productVariantPictures.length>0?i.productVariantPictures[0].productVariantThumbnail:'assets/loader/200.gif'}}"
                    onerror="this.src='assets/loader/200.gif'">
                </a>
              </div>
              <div class="card-footer  w-100 p-0 bg-white border-0">
                <div class="variantNamecontain">
                  <p class="card-text variantName text-ellipsis" style="margin-bottom: 0px; font-size: 14px;">
                    {{i.variantName}}</p>
                </div>
                <p class="card-text">
                  <del style="color: black;"><b>₹{{i.variantPrice}}</b></del>&nbsp;
                  <b style="color: #d31d28;">₹{{i.variantSalePrice}}</b>
                  <input *ngIf="i.activeVariant" type="button" class="addCart" value="Add to Cart"
                    (click)="common.addtocart(i.id, i.slug)">

                  <!-- 🚀 Prime price -->
                  <!-- <p class="card-text variantName1 prime">
                <img src="assets/icons/crown.png" height="25" width="25">
                <strong>Prime Price: ₹{{i.primePrice?i.primePrice:'0'}} </strong>
              </p> -->
                  <!-- 🚀 Prime price end-->
                <p *ngIf="!i.activeVariant" class="soldOut">Sold Out</p>
              </div>
            </div>
          </div>
        </div>

        <div class="swiper-button-next"></div>
        <div class="swiper-button-prev"></div>

      </swiper>
    </div>
  </div>
  <!--Muscletech-->

  <!--  banner4 img -->
  <div class="row text-center" *ngIf="banners && banners.length && banners[3]">
    <div class="col-12 p-0" (click)="bannerClick(banners[3].id, banners[3].link)">
      <!-- <a href="{{banners[2].link}}" target="_blank"> -->
      <img width="600" height="270" src="{{banners[3].mobile_banner_photo}}" appLazyLoad class="img-fluid cursor">
      <!-- </a> -->
    </div>

  </div>
  <!--  banner4 img -->

  <!--IN2 Nutrition-->
  <div class="row" *ngIf="findBrandByName('IN2 Nutrition').length>0">
    <div class="col-12 pt-2 px-1">
      <h1 class="trendingInCSS pl-2 pt-2">IN2 Nutrition</h1>
      <p class="viewallCSS pl-2" (click)="routingBrands(findBrandByName('IN2 Nutrition')[0].brandid.slug)">
        <span>View All <i class="fa fa-caret-right fa-lg" aria-hidden="true"></i>
        </span>
      </p>
    </div>
    <div class="col-12">
      <swiper [config]="commonConfig">
        <div class="swiper-wrapper">
          <div class="swiper-slide" *ngFor="let i of findBrandByName('IN2 Nutrition')[0].data">
            <div class="card border-0">
              <div class="full-width-link" [id]="i.id"></div>
              <div class="card-body p-0" (click)="cardClick(i.id)">
                <a href="{{commService.currentHost}}/product/{{i.slug}}">
                  <div class="discountCircleHome">
                    <p class="discountText">
                      {{(((i.variantPrice - i.variantSalePrice) / i.variantPrice) * 100).toFixed(0)}}%</p>
                  </div>

                  <img width="230" height="230" class="img-fluid"
                    src="{{i.productVariantPictures.length>0?i.productVariantPictures[0].productVariantThumbnail:'assets/loader/200.gif'}}"
                    onerror="this.src='assets/loader/200.gif'">
                </a>
              </div>
              <div class="card-footer  w-100 p-0 bg-white border-0">
                <div class="variantNamecontain">
                  <p class="card-text variantName text-ellipsis" style="margin-bottom: 0px; font-size: 14px;">
                    {{i.variantName}}</p>
                </div>
                <p class="card-text">
                  <del style="color: black;"><b>₹{{i.variantPrice}}</b></del>&nbsp;
                  <b style="color: #d31d28;">₹{{i.variantSalePrice}}</b>
                  <input *ngIf="i.activeVariant" type="button" class="addCart" value="Add to Cart"
                    (click)="common.addtocart(i.id, i.slug)">

                  <!-- 🚀 Prime price -->
                  <!-- <p class="card-text variantName1 prime">
                  <img src="assets/icons/crown.png" height="25" width="25">
                  <strong>Prime Price: ₹{{i.primePrice?i.primePrice:'0'}} </strong>
                </p> -->
                  <!-- 🚀 Prime price end-->
                <p *ngIf="!i.activeVariant" class="soldOut">Sold Out</p>
              </div>
            </div>
          </div>
        </div>

        <div class="swiper-button-next"></div>
        <div class="swiper-button-prev"></div>

      </swiper>
    </div>
  </div>
  <!--IN2 Nutrition-->

  <!--Labrada Nutrition-->
  <div class="row" *ngIf="findBrandByName('Labrada').length>0">
    <div class="col-12 pt-2 px-1">
      <h1 class="trendingInCSS pl-2 pt-2">Labrada Nutrition</h1>
      <p class="viewallCSS pl-2" (click)="routingBrands(findBrandByName('Labrada')[0].brandid.slug)">
        <span>View All <i class="fa fa-caret-right fa-lg" aria-hidden="true"></i>
        </span>
      </p>
    </div>
    <div class="col-12">
      <swiper [config]="commonConfig">
        <div class="swiper-wrapper">
          <div class="swiper-slide" *ngFor="let i of findBrandByName('Labrada')[0].data">
            <div class="card border-0">
              <div class="full-width-link" [id]="i.id"></div>
              <div class="card-body p-0" (click)="cardClick(i.id)">
                <a href="{{commService.currentHost}}/product/{{i.slug}}">
                  <div class="discountCircleHome">
                    <p class="discountText">
                      {{(((i.variantPrice - i.variantSalePrice) / i.variantPrice) * 100).toFixed(0)}}%</p>
                  </div>

                  <img width="230" height="230" class="img-fluid"
                    src="{{i.productVariantPictures.length>0?i.productVariantPictures[0].productVariantThumbnail:'assets/loader/200.gif'}}"
                    onerror="this.src='assets/loader/200.gif'">
                </a>
              </div>
              <div class="card-footer  w-100 p-0 bg-white border-0">
                <div class="variantNamecontain">
                  <p class="card-text variantName text-ellipsis" style="margin-bottom: 0px; font-size: 14px;">
                    {{i.variantName}}</p>
                </div>
                <p class="card-text">
                  <del style="color: black;"><b>₹{{i.variantPrice}}</b></del>&nbsp;
                  <b style="color: #d31d28;">₹{{i.variantSalePrice}}</b>
                  <input *ngIf="i.activeVariant" type="button" class="addCart" value="Add to Cart"
                    (click)="common.addtocart(i.id, i.slug)">

                  <!-- 🚀 Prime price -->
                  <!-- <p class="card-text variantName1 prime">
                  <img src="assets/icons/crown.png" height="25" width="25">
                  <strong>Prime Price: ₹{{i.primePrice?i.primePrice:'0'}} </strong>
                </p> -->
                  <!-- 🚀 Prime price end-->
                <p *ngIf="!i.activeVariant" class="soldOut">Sold Out</p>
              </div>
            </div>
          </div>
        </div>

        <div class="swiper-button-next"></div>
        <div class="swiper-button-prev"></div>

      </swiper>
    </div>
  </div>
  <!--Labrada Nutrition-->

  <!--  banner5 img -->
  <div class="row text-center" *ngIf="banners && banners.length && banners[4]">
    <div class="col-12 p-0" (click)="bannerClick(banners[4].id, banners[4].link)">
      <!-- <a href="{{banners[2].link}}" target="_blank"> -->
      <img width="600" height="270" src="{{banners[4].mobile_banner_photo}}" appLazyLoad class="img-fluid cursor">
      <!-- </a> -->
    </div>

  </div>
  <!--  banner5 img -->

  <!--bpi Sports-->
  <div class="row" *ngIf="findBrandByName('bpi Sports').length>0">
    <div class="col-12 pt-2 px-1">
      <h1 class="trendingInCSS pl-2 pt-2">Bpi Sports</h1>
      <p class="viewallCSS pl-2" (click)="routingBrands(findBrandByName('bpi Sports')[0].brandid.slug)">
        <span>View All <i class="fa fa-caret-right fa-lg" aria-hidden="true"></i>
        </span>
      </p>
    </div>
    <div class="col-12">
      <swiper [config]="commonConfig">
        <div class="swiper-wrapper">
          <div class="swiper-slide" *ngFor="let i of findBrandByName('bpi Sports')[0].data">
            <div class="card border-0">
              <div class="full-width-link" [id]="i.id"></div>
              <div class="card-body p-0" (click)="cardClick(i.id)">
                <a href="{{commService.currentHost}}/product/{{i.slug}}">
                  <div class="discountCircleHome">
                    <p class="discountText">
                      {{(((i.variantPrice - i.variantSalePrice) / i.variantPrice) * 100).toFixed(0)}}%</p>
                  </div>

                  <img width="230" height="230" class="img-fluid"
                    src="{{i.productVariantPictures.length>0?i.productVariantPictures[0].productVariantThumbnail:'assets/loader/200.gif'}}"
                    onerror="this.src='assets/loader/200.gif'">
                </a>
              </div>
              <div class="card-footer  w-100 p-0 bg-white border-0">
                <div class="variantNamecontain">
                  <p class="card-text variantName text-ellipsis" style="margin-bottom: 0px; font-size: 14px;">
                    {{i.variantName}}</p>
                </div>
                <p class="card-text">
                  <del style="color: black;"><b>₹{{i.variantPrice}}</b></del>&nbsp;
                  <b style="color: #d31d28;">₹{{i.variantSalePrice}}</b>
                  <input *ngIf="i.activeVariant" type="button" class="addCart" value="Add to Cart"
                    (click)="common.addtocart(i.id, i.slug)">

                  <!-- 🚀 Prime price -->
                  <!-- <p class="card-text variantName1 prime">
                <img src="assets/icons/crown.png" height="25" width="25">
                <strong>Prime Price: ₹{{i.primePrice?i.primePrice:'0'}} </strong>
              </p> -->
                  <!-- 🚀 Prime price end-->
                <p *ngIf="!i.activeVariant" class="soldOut">Sold Out</p>
              </div>
            </div>
          </div>
        </div>

        <div class="swiper-button-next"></div>
        <div class="swiper-button-prev"></div>

      </swiper>
    </div>
  </div>
  <!--bpi Sports-->

  <!--One Science-->
  <div class="row" *ngIf="findBrandByName('One Science').length>0">
    <div class="col-12 pt-2 px-1">
      <h1 class="trendingInCSS pl-2 pt-2">One Science</h1>
      <p class="viewallCSS pl-2" (click)="routingBrands(findBrandByName('One Science')[0].brandid.slug)">
        <span>View All <i class="fa fa-caret-right fa-lg" aria-hidden="true"></i>
        </span>
      </p>
    </div>
    <div class="col-12">
      <swiper [config]="commonConfig">
        <div class="swiper-wrapper">
          <div class="swiper-slide" *ngFor="let i of findBrandByName('One Science')[0].data">
            <div class="card border-0">
              <div class="full-width-link" [id]="i.id"></div>
              <div class="card-body p-0" (click)="cardClick(i.id)">
                <a href="{{commService.currentHost}}/product/{{i.slug}}">
                  <div class="discountCircleHome">
                    <p class="discountText">
                      {{(((i.variantPrice - i.variantSalePrice) / i.variantPrice) * 100).toFixed(0)}}%</p>
                  </div>

                  <img width="230" height="230" class="img-fluid"
                    src="{{i.productVariantPictures.length>0?i.productVariantPictures[0].productVariantThumbnail:'assets/loader/200.gif'}}"
                    onerror="this.src='assets/loader/200.gif'">
                </a>
              </div>
              <div class="card-footer  w-100 p-0 bg-white border-0">
                <div class="variantNamecontain">
                  <p class="card-text variantName text-ellipsis" style="margin-bottom: 0px; font-size: 14px;">
                    {{i.variantName}}</p>
                </div>
                <p class="card-text">
                  <del style="color: black;"><b>₹{{i.variantPrice}}</b></del>&nbsp;
                  <b style="color: #d31d28;">₹{{i.variantSalePrice}}</b>
                  <input *ngIf="i.activeVariant" type="button" class="addCart" value="Add to Cart"
                    (click)="common.addtocart(i.id, i.slug)">

                  <!-- 🚀 Prime price -->
                  <!-- <p class="card-text variantName1 prime">
                <img src="assets/icons/crown.png" height="25" width="25">
                <strong>Prime Price: ₹{{i.primePrice?i.primePrice:'0'}} </strong>
              </p> -->
                  <!-- 🚀 Prime price end-->
                <p *ngIf="!i.activeVariant" class="soldOut">Sold Out</p>
              </div>
            </div>
          </div>
        </div>

        <div class="swiper-button-next"></div>
        <div class="swiper-button-prev"></div>

      </swiper>
    </div>
  </div>
  <!--One Science-->

  <!--  banner6 img -->
  <div class="row text-center" *ngIf="banners && banners.length && banners[5]">
    <div class="col-12 p-0" (click)="bannerClick(banners[5].id, banners[5].link)">
      <!-- <a href="{{banners[2].link}}" target="_blank"> -->
      <img width="600" height="270" src="{{banners[5].mobile_banner_photo}}" appLazyLoad class="img-fluid cursor">
      <!-- </a> -->
    </div>

  </div>
  <!--  banner6 img -->

  <!--"The Dr Choice"-->
  <div class="row" *ngIf="findBrandByName('The Dr Choice').length>0">
    <div class="col-12 pt-2 px-1">
      <h1 class="trendingInCSS pl-2 pt-2">The Dr Choice</h1>
      <p class="viewallCSS pl-2" (click)="routingBrands(findBrandByName('The Dr Choice')[0].brandid.slug)">
        <span>View All <i class="fa fa-caret-right fa-lg" aria-hidden="true"></i>
        </span>
      </p>
    </div>
    <div class="col-12">
      <swiper [config]="commonConfig">
        <div class="swiper-wrapper">
          <div class="swiper-slide" *ngFor="let i of findBrandByName('The Dr Choice')[0].data">
            <div class="card border-0">
              <div class="full-width-link" [id]="i.id"></div>
              <div class="card-body p-0" (click)="cardClick(i.id)">
                <a href="{{commService.currentHost}}/product/{{i.slug}}">
                  <div class="discountCircleHome">
                    <p class="discountText">
                      {{(((i.variantPrice - i.variantSalePrice) / i.variantPrice) * 100).toFixed(0)}}%</p>
                  </div>

                  <img width="230" height="230" class="img-fluid"
                    src="{{i.productVariantPictures.length>0?i.productVariantPictures[0].productVariantThumbnail:'assets/loader/200.gif'}}"
                    onerror="this.src='assets/loader/200.gif'">
                </a>
              </div>
              <div class="card-footer  w-100 p-0 bg-white border-0">
                <div class="variantNamecontain">
                  <p class="card-text variantName text-ellipsis" style="margin-bottom: 0px; font-size: 14px;">
                    {{i.variantName}}</p>
                </div>
                <p class="card-text">
                  <del style="color: black;"><b>₹{{i.variantPrice}}</b></del>&nbsp;
                  <b style="color: #d31d28;">₹{{i.variantSalePrice}}</b>
                  <input *ngIf="i.activeVariant" type="button" class="addCart" value="Add to Cart"
                    (click)="common.addtocart(i.id, i.slug)">

                  <!-- 🚀 Prime price -->
                  <!-- <p class="card-text variantName1 prime">
                <img src="assets/icons/crown.png" height="25" width="25">
                <strong>Prime Price: ₹{{i.primePrice?i.primePrice:'0'}} </strong>
              </p> -->
                  <!-- 🚀 Prime price end-->
                <p *ngIf="!i.activeVariant" class="soldOut">Sold Out</p>
              </div>
            </div>
          </div>
        </div>

        <div class="swiper-button-next"></div>
        <div class="swiper-button-prev"></div>

      </swiper>
    </div>
  </div>
  <!--"The Dr Choice"-->

  <!--Bubble Up Nutrition-->
  <div class="row" *ngIf="findBrandByName('Bubble Up Nutrition').length>0">
    <div class="col-12 pt-2 px-1">
      <h1 class="trendingInCSS pl-2 pt-2">Bubble Up Nutrition</h1>
      <p class="viewallCSS pl-2" (click)="routingBrands(findBrandByName('Bubble Up Nutrition')[0].brandid.slug)">
        <span>View All <i class="fa fa-caret-right fa-lg" aria-hidden="true"></i>
        </span>
      </p>
    </div>
    <div class="col-12">
      <swiper [config]="commonConfig">
        <div class="swiper-wrapper">
          <div class="swiper-slide" *ngFor="let i of findBrandByName('Bubble Up Nutrition')[0].data">
            <div class="card border-0">
              <div class="full-width-link" [id]="i.id"></div>
              <div class="card-body p-0" (click)="cardClick(i.id)">
                <a href="{{commService.currentHost}}/product/{{i.slug}}">
                  <div class="discountCircleHome">
                    <p class="discountText">
                      {{(((i.variantPrice - i.variantSalePrice) / i.variantPrice) * 100).toFixed(0)}}%</p>
                  </div>

                  <img width="230" height="230" class="img-fluid"
                    src="{{i.productVariantPictures.length>0?i.productVariantPictures[0].productVariantThumbnail:'assets/loader/200.gif'}}"
                    onerror="this.src='assets/loader/200.gif'">
                </a>
              </div>
              <div class="card-footer  w-100 p-0 bg-white border-0">
                <div class="variantNamecontain">
                  <p class="card-text variantName text-ellipsis" style="margin-bottom: 0px; font-size: 14px;">
                    {{i.variantName}}</p>
                </div>
                <p class="card-text">
                  <del style="color: black;"><b>₹{{i.variantPrice}}</b></del>&nbsp;
                  <b style="color: #d31d28;">₹{{i.variantSalePrice}}</b>
                  <input *ngIf="i.activeVariant" type="button" class="addCart" value="Add to Cart"
                    (click)="common.addtocart(i.id, i.slug)">

                  <!-- 🚀 Prime price -->
                  <!-- <p class="card-text variantName1 prime">
                <img src="assets/icons/crown.png" height="25" width="25">
                <strong>Prime Price: ₹{{i.primePrice?i.primePrice:'0'}} </strong>
              </p> -->
                  <!-- 🚀 Prime price end-->
                <p *ngIf="!i.activeVariant" class="soldOut">Sold Out</p>
              </div>
            </div>
          </div>
        </div>

        <div class="swiper-button-next"></div>
        <div class="swiper-button-prev"></div>

      </swiper>
    </div>
  </div>
  <!--Bubble Up Nutrition-->

  <!--  banner7 img -->
  <div class="row text-center" *ngIf="banners && banners.length && banners[6]">
    <div class="col-12 p-0" (click)="bannerClick(banners[6].id, banners[6].link)">
      <!-- <a href="{{banners[2].link}}" target="_blank"> -->
      <img width="600" height="270" src="{{banners[6].mobile_banner_photo}}" appLazyLoad class="img-fluid cursor">
      <!-- </a> -->
    </div>

  </div>
  <!--  banner7 img -->

  <!--SOS Clothing & Accessories-->
  <div class="row" *ngIf="findBrandByName('Source Of Supplements').length>0">
    <div class="col-12 pt-2 px-1">
      <h1 class="trendingInCSS pl-2 pt-2">Source Of Supplements</h1>
      <p class="viewallCSS pl-2" (click)="routingBrands(findBrandByName('Source Of Supplements')[0].brandid.slug)">
        <span>View All <i class="fa fa-caret-right fa-lg" aria-hidden="true"></i>
        </span>
      </p>
    </div>
    <div class="col-12">
      <swiper [config]="commonConfig">
        <div class="swiper-wrapper">
          <div class="swiper-slide" *ngFor="let i of findBrandByName('Source Of Supplements')[0].data">
            <div class="card border-0">
              <div class="full-width-link" [id]="i.id"></div>
              <div class="card-body p-0" (click)="cardClick(i.id)">
                <a href="{{commService.currentHost}}/product/{{i.slug}}">
                  <div class="discountCircleHome">
                    <p class="discountText">
                      {{(((i.variantPrice - i.variantSalePrice) / i.variantPrice) * 100).toFixed(0)}}%</p>
                  </div>

                  <img width="230" height="230" class="img-fluid"
                    src="{{i.productVariantPictures.length>0?i.productVariantPictures[0].productVariantThumbnail:'assets/loader/200.gif'}}"
                    onerror="this.src='assets/loader/200.gif'">
                </a>
              </div>
              <div class="card-footer  w-100 p-0 bg-white border-0">
                <div class="variantNamecontain">
                  <p class="card-text variantName text-ellipsis" style="margin-bottom: 0px; font-size: 14px;">
                    {{i.variantName}}</p>
                </div>
                <p class="card-text">
                  <del style="color: black;"><b>₹{{i.variantPrice}}</b></del>&nbsp;
                  <b style="color: #d31d28;">₹{{i.variantSalePrice}}</b>
                  <input *ngIf="i.activeVariant" type="button" class="addCart" value="Add to Cart"
                    (click)="common.addtocart(i.id, i.slug)">

                  <!-- 🚀 Prime price -->
                  <!-- <p class="card-text variantName1 prime">
                  <img src="assets/icons/crown.png" height="25" width="25">
                  <strong>Prime Price: ₹{{i.primePrice?i.primePrice:'0'}} </strong>
                </p> -->
                  <!-- 🚀 Prime price end-->
                <p *ngIf="!i.activeVariant" class="soldOut">Sold Out</p>
              </div>
            </div>
          </div>
        </div>

        <div class="swiper-button-next"></div>
        <div class="swiper-button-prev"></div>

      </swiper>
    </div>
  </div>
  <!--SOS Clothing & Accessories-->

  <!-- Trending in Protein Snacks-->
  <!-- <div class="row">
    <div class="col-12 pt-2 px-1">
      <h1 class="trendingInCSS pl-2 pt-2">Trending in Protein Snacks</h1>
      <p class="viewallCSS pl-2" (click)="routingSnacksTrending()">
        <span>View All <i class="fa fa-caret-right fa-lg" aria-hidden="true"></i>
        </span>
      </p>
    </div>

    <div class="col-12">
      <swiper [config]="commonConfig">
        <div class="swiper-wrapper">
          <div class="swiper-slide" *ngFor="let t5 of trending5">
            <div class="card border-0">
              <div class="full-width-link" [id]="t5.id"></div>
              <div class="card-body p-0" (click)="cardClick(t5.id)">
                <a href="{{commService.currentHost}}/product/{{t5.slug}}">
                  <div class="discountCircleHome">
                    <p class="discountText">
                      {{(((t5.variantPrice - t5.variantSalePrice) / t5.variantPrice) * 100).toFixed(0)}}%</p>
                  </div>

                  <img width="230" height="230" class="img-fluid"
                    src="{{t5.productVariantPictures.length>0?t5.productVariantPictures[0].productVariantThumbnail:'assets/loader/200.gif'}}"
                    appLazyLoad onerror="this.src='assets/loader/200.gif'">
                </a>

              </div>
              <div class="card-footer w-100 p-0 bg-white border-0">
                <p class="card-text variantName text-ellipsis" style="margin-bottom: 0px; font-size: 14px;">
                  {{t5.variantName}}</p>
                <p class="card-text">
                  <del style="color: black;"><b>₹{{t5.variantPrice}}</b></del>&nbsp;
                  <b style="color: #d31d28;">₹{{t5.variantSalePrice}}</b>
                  <input *ngIf="t5.activeVariant" type="button" class="addCart" value="Add to Cart"
                    (click)="common.addtocart(t5.id, t5.slug)"> -->

                  <!-- 🚀 Prime price -->
                  <!-- <p class="card-text variantName1 prime">
                  <img src="assets/icons/crown.png" height="25" width="25">
                  <strong>Prime Price: ₹{{t5.primePrice?t5.primePrice:'0'}} </strong>
                </p> -->
                  <!-- 🚀 Prime price end-->
                <!-- <p *ngIf="!t5.activeVariant" class="soldOut">Sold Out</p>
              </div>
            </div>
          </div>
        </div> -->
        <!-- Add Arrows -->

        <!-- <div class="swiper-button-next"></div>
        <div class="swiper-button-prev"></div>

      </swiper>
    </div>
  </div> -->
  <!-- Trending in Protein Snacks-->


  <!--  banner8 img -->
  <!-- <div class="row text-center" *ngIf="banners && banners.length && banners[7]">
    <div class="col-12 p-0" (click)="bannerClick(banners[7].id, banners[7].link)"> -->
      <!-- <a href="{{banners[2].link}}" target="_blank"> -->
      <!-- <img width="600" height="270" src="{{banners[7].mobile_banner_photo}}" appLazyLoad class="img-fluid cursor"> -->
      <!-- </a> -->
    <!-- </div>

  </div> -->
  <!--  banner8 img -->


  <!--New launched start-->
  <!-- <div class="row">
    <div class="col-12 pt-2 px-1">
      <h1 class="trendingInCSS pl-2 pt-2">Newly Launched</h1>
      <p class="viewallCSS pl-2" (click)="routingNewlyLauched()">
        <span>View All <i class="fa fa-caret-right fa-lg" aria-hidden="true"></i>
        </span>
      </p>
    </div>
    <div class="col-12">
      <swiper [config]="commonConfig">
        <div class="swiper-wrapper">
          <div class="swiper-slide" *ngFor="let i of newlyLaunched">
            <div class="card border-0">
              <div class="full-width-link" [id]="i.id"></div>
              <div class="card-body p-0" (click)="cardClick(i.id)">
                <a href="{{commService.currentHost}}/product/{{i.slug}}">
                  <div class="discountCircleHome">
                    <p class="discountText">
                      {{(((i.variantPrice - i.variantSalePrice) / i.variantPrice) * 100).toFixed(0)}}%</p>
                  </div>

                  <img width="230" height="230" class="img-fluid"
                    src="{{i.productVariantPictures.length>0?i.productVariantPictures[0].productVariantThumbnail:'assets/loader/200.gif'}}"
                    appLazyLoad onerror="this.src='assets/loader/200.gif'">
                </a>
              </div>
              <div class="card-footer  w-100 p-0 bg-white border-0">
                <div class="variantNamecontain">
                  <p class="card-text variantName text-ellipsis" style="margin-bottom: 0px; font-size: 14px;">
                    {{i.variantName}}</p>
                </div>
                <p class="card-text">
                  <del style="color: black;"><b>₹{{i.variantPrice}}</b></del>&nbsp;
                  <b style="color: #d31d28;">₹{{i.variantSalePrice}}</b>
                  <input *ngIf="i.activeVariant" type="button" class="addCart" value="Add to Cart"
                    (click)="common.addtocart(i.id, i.slug)"> -->

  <!-- 🚀 Prime price -->
  <!-- <p class="card-text variantName1 prime">
                  <img src="assets/icons/crown.png" height="25" width="25">
                  <strong>Prime Price: ₹{{i.primePrice?i.primePrice:'0'}} </strong>
                </p> -->
  <!-- 🚀 Prime price end-->
  <!-- <p *ngIf="!i.activeVariant" class="soldOut">Sold Out</p>
              </div>
            </div>
          </div>
        </div> -->
  <!-- Add Arrows -->
  <!-- 
        <div class="swiper-button-next"></div>
        <div class="swiper-button-prev"></div>

      </swiper>
    </div>
  </div> -->
  <!--New launched end-->

  <!--Stack Deals-->
  <!--  <div class="row">
    <div class="col-12 pt-2 px-1">
      <h1 class="trendingInCSS pl-2 pt-2">Stack Deals</h1>
      <p class="viewallCSS pl-2" (click)="routingStackDeals()">
        <span>View All <i class="fa fa-caret-right fa-lg" aria-hidden="true"></i>
        </span>
      </p>
    </div>
    <div class="col-12">
      <swiper [config]="commonConfig">
        <div class="swiper-wrapper">
          <div class="swiper-slide" *ngFor="let i of stackDeals">
            <div class="card border-0">
              <div class="full-width-link" [id]="i.id"></div>
              <div class="card-body p-0" (click)="cardClick(i.id)">
                <a href="{{commService.currentHost}}/product/{{i.slug}}">
                  <div class="discountCircleHome">
                    <p class="discountText">
                      {{(((i.variantPrice - i.variantSalePrice) / i.variantPrice) * 100).toFixed(0)}}%</p>
                  </div>

                  <img width="230" height="230" class="img-fluid"
                    src="{{i.productVariantPictures.length>0?i.productVariantPictures[0].productVariantThumbnail:'assets/loader/200.gif'}}"
                    onerror="this.src='assets/loader/200.gif'">
                </a>
              </div>
              <div class="card-footer  w-100 p-0 bg-white border-0">
                <div class="variantNamecontain">
                  <p class="card-text variantName text-ellipsis" style="margin-bottom: 0px; font-size: 14px;">
                    {{i.variantName}}</p>
                </div>
                <p class="card-text">
                  <del style="color: black;"><b>₹{{i.variantPrice}}</b></del>&nbsp;
                  <b style="color: #d31d28;">₹{{i.variantSalePrice}}</b>
                  <input *ngIf="i.activeVariant" type="button" class="addCart" value="Add to Cart"
                    (click)="common.addtocart(i.id, i.slug)">-->

  <!-- 🚀 Prime price -->
  <!-- <p class="card-text variantName1 prime">
                  <img src="assets/icons/crown.png" height="25" width="25">
                  <strong>Prime Price: ₹{{i.primePrice?i.primePrice:'0'}} </strong>
                </p> -->
  <!-- 🚀 Prime price end-->
  <!--<p *ngIf="!i.activeVariant" class="soldOut">Sold Out</p>
              </div>
            </div>
          </div>
        </div>

        <div class="swiper-button-next"></div>
        <div class="swiper-button-prev"></div>

      </swiper>
    </div>
  </div> -->
  <!--Stack Deals-->

</div>

<app-footer></app-footer>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.9)" size="medium" color="#fff" type="ball-atom">
  <p style="color: white"> Please wait... </p>
</ngx-spinner>