import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonFunctions } from 'src/app/common.function';
import { CommonService } from 'src/app/services/common.service';
import { DomainService } from 'src/app/services/domain.service';

@Component({
  selector: 'app-sub-category',
  templateUrl: './sub-category.component.html',
  styleUrls: ['./sub-category.component.scss']
})
export class SubCategoryComponent implements OnInit {

  slug: string;
  id: string;
  subcatName: any[] = [];
  showFilter = false;
  subcatDescription: any;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public commService: CommonService,
    public common: DomainService,
    private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.spinner.show();
    this.route.paramMap.subscribe((params) => {
      this.slug = params.get('slug');
      this.subCategorySEOGet().finally(() => {
        this.showSubCatProduct()
      });
    });
  }

  subCategorySEO() {
    let subCatIndex = CommonFunctions.findKeyValueIndex(this.commService.subCategorySEO, 'id', this.id);
    console.log(subCatIndex, this.commService.subCategorySEO[subCatIndex]);
    if (subCatIndex) {
      this.commService.updateMetaTages(this.commService.subCategorySEO[subCatIndex]['title'], [
        { name: 'og:title', content: this.commService.subCategorySEO[subCatIndex]['title'] },
        { name: 'title', content: this.commService.subCategorySEO[subCatIndex]['title'] },
        { name: 'og:description', content: this.commService.subCategorySEO[subCatIndex]['description'] },
        { name: 'description', content: this.commService.subCategorySEO[subCatIndex]['description'] },
        { name: 'og:image:secure_url', content: 'https://sourceofsupplements.com/assets/icons/SOS-Favicon.png' },
        { name: 'image', content: 'https://sourceofsupplements.com/assets/icons/SOS-Favicon.png' },
      ]);
    } else {
      this.commService.updateMetaTages(this.subcatName, [
        { name: 'og:title', content: this.subcatName },
        { name: 'title', content: this.subcatName },
        { name: 'og:description', content: this.subcatName },
        { name: 'description', content: this.subcatName },
        { name: 'og:image:secure_url', content: 'https://sourceofsupplements.com/assets/icons/SOS-Favicon.png' },
        { name: 'image', content: 'https://sourceofsupplements.com/assets/icons/SOS-Favicon.png' },
      ]);
    }

    this.commService.createCanonicalURL();

  }

  subCategorySEOGet() {
    return new Promise((resolve, reject) => {
      this.common.suCatSEOget().subscribe(res => {
        for (let index = 0; index < res['data'].length; index++) {
          const ele = res['data'][index];
          this.commService.subCategorySEO.push({
            id: ele.id,
            name: ele.subCategoryName,
            title: ele.meta_title,
            description: ele.subCategoryDescription
          })
        }
        resolve(true);
      }, err => {
        resolve(false);
      });
    });
  }

  showSubCatProduct() {
    this.showFilter = false;
    var data = 'slug=' + this.slug;
    this.common.subCatProduct(data).subscribe(res => {
      try {
        this.id = res['data'][0].id;
        this.showFilterData(res['data'][0].id, res['data'][0].categoryId);
        this.subcatName = res['data'][0].subCategoryName;
        this.subcatDescription = res['data'][0].pageDescription;
        this.common.sid_sortby = res['data'][0].productVariants;
        this.subCategorySEO();
      } catch (error) {
        this.spinner.hide();
        this.subcatName = [];
        this.common.toastr.error("Sub-category data not available");
        this.router.navigate(["/"]);
      }
      this.spinner.hide();
    }, err => {
      console.log(err.error);
      this.spinner.hide();
    });
  }

  getbrand(get) {
    get.brand.brand_name = get.brand.brand_name.replace(/ /g, "-");
    // this.router.navigate([`/product/${get.slug}`]);
  }

  showFilterData(subCategoryId, CategoryId) {
    var data = `all_product=1&shop_by_category=1&categoryId=${CategoryId}&subCategoryId=${subCategoryId}`;
    this.common.filterbyALl(data, 'cat');
    this.showFilter = true;
  }

  cardClick(id){    
    if(id){
      document.getElementById(id).style.display = "block";
      setTimeout(() => {
        document.getElementById(id).style.display = "none";
      }, 300);
    }
  }

}
