<!-- <title>IN2 Nutrition | SOS</title> -->

<app-header></app-header>

<link rel="canonical" href="sourceofsupplements.com/brand/in2-nutrition">

<div class="container-fluid p-0" (click)="common.closeNav()">
    <div class="row m-0">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0 text-center">
            <img width="600" height="80" class="img-fluid onlyForLaptop" src="assets/images/SOS_in2_pc_1.jpg" alt=""
                appLazyLoad onerror="this.src='assets/images/notfound.jpg'">
            <img width="600" height="80" class="img-fluid onlyForMobile" src="assets/images/SOS_in2_mobile_1.jpg" alt=""
                appLazyLoad onerror="this.src='assets/images/notfound.jpg'">
        </div>
    </div>
    <div class="container-fluid topPadding">
        <div class="row policyBanner m-0 align-items-center text-center mt-4">
            <div class="col-12 my-lg-1">
                <h1 class="bannerText m-2">IN2 Nutrition</h1>
            </div>
        </div>

        <!-- for laptop -->
        <div class="row">
            <div class="col-12">
                <div class="row pt-2">

                    <div class="col-6" *ngFor="let i of common.filterby ">

                        <div class="card d-flex text-center border-0">
                            <div class="full-width-link" [id]="i.id"></div>
                            <div class="card-body p-0"  (click)="cardClick(i.id)">
                                <a href="{{commService.currentHost}}/product/{{i.slug}}">
                                    <div class="discountCircle">
                                        <p class="discountText">
                                            {{(((i.variantPrice - i.variantSalePrice) / i.variantPrice) *
                                            100).toFixed(0)}}%
                                        </p>
                                    </div>
                                    <img width="270" height="270" class="card-img-top img-fluid"
                                        src="{{i.productVariantPictures[0].productVariantThumbnail}}" appLazyLoad
                                        onerror="this.src='assets/loader/200.gif'">
                                </a>
                            </div>
                            <div class="card-footer w-100 p-0 bg-white border-0">
                                <p class="card-text variantName text-ellipsis" style="margin-bottom: 0px;">
                                    {{i.variantName}}</p>
                                <p class="card-text">
                                    <del style="color: black;"><b>₹{{i.variantPrice}}</b></del>&nbsp;
                                    <b style="color: #d31d28;">₹{{i.variantSalePrice}}</b>
                                    <input *ngIf="i.activeVariant" type="button" class="addCart" value="Add to Cart"
                                        (click)="common.addtocart(i.id, i.slug)">

                                    <!-- 🚀 Prime price -->
                                <!-- <p class="card-text variantName1 prime mb"><img src="assets/icons/crown.png" height="25"
                                        width="25">
                                    <strong>Prime Price: ₹{{i.primePrice?i.primePrice:'0'}} </strong></p> -->
                                <!-- 🚀 Prime price end-->
                                <p *ngIf="!i.activeVariant" class="soldOut">Sold Out</p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    </div>
    <app-mobilefilter></app-mobilefilter>

</div>

<app-footer></app-footer>