import { Component, OnInit } from '@angular/core';
import { DomainService } from 'src/app/services/domain.service';
import { StorageService as newStorage } from 'src/app/services/newstorage.service';
import { CommonFunctions } from 'src/app/common.function';
import { HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { CommonService } from 'src/app/services/common.service';
import Analytics from 'analytics';

declare var gtag_view_cart: Function;
declare var gtag_add_to_cart: Function;
declare var gtag_remove_from_cart: Function;
declare var gtag_begin_checkout: Function;

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit {

  analytics = Analytics({});

  math = Math;
  httpOptions: { headers: HttpHeaders; };
  public gettpriceamont: any;
  promocodeget: any;
  yoursaving: boolean;
  getcoupon: any = 0;
  getcouponcode: any;
  getdiscountAmount: number;
  getflavour: any;
  public flavourList: any = [];
  discount: number;
  coupondiscount: any;
  codtype: any;
  public productVAriantID: any;
  totalamount: any;
  public removeVariantID: any = [];
  showcart: any;
  gtagCardData = {
    value: 0,
    items: []
  };

  constructor(
    public common: DomainService,
    public newStorage: newStorage,
    public toastr: ToastrService,
    public router: Router,
    public commService: CommonService,
    private spinner: NgxSpinnerService,
  ) { }

  ngOnInit() {
    this.common.setHeader().finally(() => {
      this.CARTSEO();
      this.spinner.show();
      this.getcartall();
      this.CartShow()
      window.history.forward();
      this.newStorage.deleteItem("PDA");
      this.newStorage.deleteItem("PDP");
    });
  }

  CARTSEO() {
    this.commService.updateMetaTages(this.commService.carTSEO[0]['Title'], [
      { name: 'og:title', content: this.commService.carTSEO[0]['Title'] }
    ]);
    this.commService.createCanonicalURL();
  }

  gotoProduct(get) {
    get.brand.brand_name = get.brand.brand_name.replace(/ /g, "-");
    this.router.navigate([`/product/${get.slug}`]);
  }

  getcartall() {
    // console.log('called...')

    var data = 'deviceIdentifier=' + this.common.useriddefault;
    this.common.cartget(data, this.common.httpOptions).subscribe(res => {
      this.common.cartdetails = res['data'];
      this.gtagViewToCart(res['data']);
      this.gettpriceamont = 0;
      this.totalamount = 0;
      this.common.counterproduct = res['totalQuantity'];
      let getamount;
      this.coupondiscount = 0;
      let get_coupon_array = [];
      let getProbuctVarientId = [];

      for (let i = 0; i <= this.common.cartdetails.length; i++) {
        if (this.common.cartdetails.length > i) {

          if (this.common.userdetails && this.common.userdetails['isPrimeMember'] == true) {
            getamount = this.common.cartdetails[i].primePrice != null ? this.common.cartdetails[i].primePrice * this.common.cartdetails[i].quantity : this.common.cartdetails[i].variantSalePrice * this.common.cartdetails[i].quantity; //Price X Item
            this.totalamount += getamount;
          } else {
            getamount = this.common.cartdetails[i].variantSalePrice * this.common.cartdetails[i].quantity; //Price X Item
            this.totalamount += getamount;
          }

          get_coupon_array.push(this.common.cartdetails[i].productVariantId);

          if (this.common.cartdetails[i].discount > 0) {
            this.yoursaving = true;
            this.codtype = this.common.cartdetails[i].codeType;
            if (this.common.cartdetails[i].codeType == 'FLAT') {
              if (this.coupondiscount == 0) {
                this.coupondiscount = this.common.cartdetails[i].discount;
                getamount = getamount - this.common.cartdetails[i].discount;
              }
              console.log(getamount);

              // this.common.cartdetails[i].variantSalePrice = getamount;
            } else {
              this.coupondiscount = this.common.cartdetails[i].discount;
              getamount = getamount - getamount * this.common.cartdetails[i].discount / 100;
              // this.common.cartdetails[i].variantSalePrice = getamount;
            }
            getProbuctVarientId.push(this.common.cartdetails[i].productVariantId)
            // coupon sum end 
          } else {
            this.yoursaving = false;
          }
          this.gettpriceamont += Math.round(getamount);
          this.analytics.track('cartItems', {
            item: this.common.cartdetails[i]['variantName'],
            price: this.common.cartdetails[i]['variantSalePrice']
          })
        }
      }

      this.newStorage.updateItem('removeproductId', getProbuctVarientId);
      this.spinner.hide();

    }, err => { })
  }

  gtagViewToCart(cartDetails: any) {
    this.gtagCardData = {
      value: 0,
      items: []
    };

    for (let i = 0; i < cartDetails.length; i++) {
      this.gtagCardData.items.push({
        item_id: cartDetails[i].productSKU,
        item_name: cartDetails[i].variantName,
        discount: (cartDetails[i].variantPrice - cartDetails[i].variantSalePrice),
        price: cartDetails[i].variantPrice,
        quantity: cartDetails[i].quantity
      });
      this.gtagCardData.value += cartDetails[i].variantSalePrice;
    }
    // console.log(cartDetails);
    gtag_view_cart(this.gtagCardData);
  }

  cartremove(cartid) {
    let gtagCardData = {
      value: 0,
      items: []
    };
    const item = this.common.cartdetails.filter(f => cartid == f.cartID);
    gtagCardData.items.push({
      item_id: item[0].productSKU,
      item_name: item[0].variantName,
      discount: (item[0].variantPrice - item[0].variantSalePrice),
      price: item[0].variantPrice,
      quantity: item[0].quantity
    });
    gtagCardData.value = item[0].variantSalePrice;

    this.common.httpOptions["body"] = { 'deviceIdentifier': this.common.useriddefault, 'id': cartid };
    this.common.cartdelete(this.common.httpOptions).subscribe(res => {
      this.toastr.success(res['message']);
      gtag_remove_from_cart(gtagCardData)
      this.getcartall();
    }, err => { })
  }

  // start Update Qunatity
  updateQunatity(cartid, events) {
    var qunatityinput = events.target.value;
    var data = { "id": cartid, "quantity": qunatityinput, 'deviceIdentifier': this.common.useriddefault }
    this.common.cartupdate(data, this.common.httpOptions).subscribe(res => {
      this.getcartall();
    }, err => { })
  }

  //start add the quantity
  updateQunatityaddbtn(cartid, qunatityinput) {
    var quantityadd = Number(qunatityinput) + 1;
    var data = { "id": cartid, "quantity": quantityadd, 'deviceIdentifier': this.common.useriddefault }
    this.common.cartupdate(data, this.common.httpOptions).subscribe(res => {
      this.getcartall();
    }, err => { })
  }
  //end add the quantity


  //start substract the quantity
  updateQunatitysubbtn(cartid, qunatityinput) {
    if (qunatityinput != 1) {
      var quantityadd = qunatityinput - 1;
      var data = { "id": cartid, "quantity": quantityadd, 'deviceIdentifier': this.common.useriddefault }
      this.common.cartupdate(data, this.common.httpOptions).subscribe(res => {
        this.getcartall();
      }, err => { })
    }
    else if (qunatityinput == 1) {
      this.cartremove(cartid)
    }
  }
  //end substract the quantity

  selectflavour(cartid, getid) {
    var data = { "id": cartid, "productFlavour": getid.target.value, 'deviceIdentifier': this.common.useriddefault };
    this.common.cartupdate(data, this.common.httpOptions).subscribe(res => {
      this.toastr.success(res['message']);
      this.getcartall();
    }, err => { })
  }

  checkout() {
    if (this.common.cartdetails.length > 0) {
      gtag_begin_checkout(this.gtagCardData);
      this.router.navigate(['/checkout']);
    } else {
      this.toastr.error("Your Cart Is Currently Empty");
    }
  }

  // coupon code api
  couponcode() {
    let getProbuctVarientId = [];
    if (this.promocodeget != '' && this.promocodeget != undefined) {
      /* this.getcouponcode.forEach(element => {
        if (element.coupan_code != null) {
          if (this.promocodeget.toUpperCase() == element.coupan_code.coupan_code) {
            getProbuctVarientId.push(element.productVariantId);
          }
        }
      }); */

      for (let i = 0; i < this.common.cartdetails.length; i++) {
        const item = this.common.cartdetails[i];
        getProbuctVarientId.push(item.productVariantId);
      }
      // this.common.cartdetails[i].productVariantId

      this.newStorage.updateItem('removeproductId', getProbuctVarientId);
      if (getProbuctVarientId.length == 0) {
        return this.toastr.error('Coupon code is not applicable');
      }

      var data = { 'coupanCodeId': this.promocodeget.toUpperCase(), 'productVariantId': getProbuctVarientId, 'deviceIdentifier': this.common.useriddefault };

      this.common.verifycouponcode(data).subscribe(res => {
        this.spinner.show();
        if (res) {
          setTimeout(() => {
            this.getcartall();
            this.spinner.hide();
            this.toastr.success(res['message']);
          }, 1500);
        }
      }, err => {
        this.spinner.hide();
        console.log(err.error['message']);
        if (err.error['message'] == 'Coupan code is not applicable') {
          this.toastr.error('Coupon code is not applicable');
        } else {
          if (typeof err.error['message'] == 'string') {
            this.toastr.error(err.error['message']);
          } else {
            this.toastr.error(err.error['message'][0].msg);
          }
        }
      });
    }
  }
  // end coupon api

  // coupon code api
  removeCouponCode() {
    this.newStorage.getItem('removeproductId').then((res) => {
      if (res) {
        this.removeVariantID = res;
        this.yoursaving = false;
        this.promocodeget = '';
        var data = { 'productVariantId': this.removeVariantID };
        this.common.removeCoupanCode(data).subscribe(res => {
          if (res['message'] == 'coupan code removed') {
            this.getcartall();
            this.spinner.hide();
            this.toastr.success('Coupon code removed');
          }
        }, err => { });
      }
    });
  }
  // end coupon api

  CartShow() {
    this.common.showincart().subscribe(res => {
      this.showcart = res['data'];
    }, err => { });
  }

  addToCart(row:any) {
    this.common.addtocart(row.id, row.slug).finally(async () => {
      await this.common.particularProduct(row.slug);
      let gtagAddCardData = {
        value: this.common.getproductfetch.variantSalePrice,
        items: [{
          item_id: this.common.getproductfetch.productSKU,
          item_name: this.common.getproductfetch.variantName,
          discount: (this.common.getproductfetch.variantPrice - this.common.getproductfetch.variantSalePrice),
          price: this.common.getproductfetch.variantPrice,
          quantity: this.common.getproductfetch.quantity
        }]
      };

      gtag_add_to_cart(gtagAddCardData);
      this.getcartall();
      this.CartShow();
    });
  }
}



