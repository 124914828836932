<app-header></app-header>

<div class="container-fluid" (click)="common.closeNav()">
    <div class="row pt-0 m-0">
        <div class="col-12 p-0 onlyForMobile">
            <div class="row">
                <div class="discountCirclePRO" *ngIf="common.getproductfetch">
                    <p class="discountTextPRO">
                        {{(((common.getproductfetch.variantPrice - common.getproductfetch.variantSalePrice) /
                        common.getproductfetch.variantPrice) * 100).toFixed(0)}}%
                    </p>
                    <div class="discountCircleHome1">
                        <img *ngIf="common.getproductfetch.veg==true" class="img-fluid" src="assets/images/veg.jpeg">
                        <img *ngIf="common.getproductfetch.veg==false" class="img-fluid"
                            src="assets/images/nonveg.jpeg">
                    </div>
                </div>

                <div class="col-12">
                    <swiper [config]="config">
                        <div class="swiper-wrapper">
                            <div class="swiper-slide" *ngFor="let i of common.productVariantPictures">
                                <div class="card-body p-0">
                                    <img width="510" height="510" class="img-fluid" src="{{i.productVariantPhoto}}"
                                        data-toggle="modal" data-target="#exampleModal" alt="{{i.altText}}">
                                </div>
                            </div>
                        </div>
                        <!-- Add Pagination -->
                        <div class="swiper-pagination"></div>
                    </swiper>
                </div>


                <!-- Modal -->
                <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog"
                    aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-md" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <swiper [config]="config">
                                    <div class="swiper-wrapper">
                                        <div class="swiper-slide" *ngFor="let i of common.productVariantPictures">
                                            <div class="card-body p-0">
                                                <pinch-zoom>
                                                    <img class="img-fluid" style="width: 85%;"
                                                        src="{{i.productVariantPhoto}}">
                                                </pinch-zoom>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Add Pagination -->
                                    <div class="swiper-pagination"></div>
                                </swiper>
                            </div>
                            <div class="modal-footer">

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="col-12" *ngIf="common.getproductfetch">
            <h1 class="productTitle mt-3">{{common.getproductfetch.variantName}}</h1>
            <p class="productSubtitle"><span style="cursor: pointer;"
                    (click)="RedirectBrand()">{{common.getproductfetch.brand.brand_name}}</span> |
                <span style="cursor: pointer;"
                    (click)="RedirectCategory()">{{common.getproductfetch.category.categoryName}}</span>
            </p>

            <div class="priceANDflavour">
                <p class="productPrice  d-flex justify-content-between">
                    <span>
                        <del>₹{{common.getproductfetch.variantPrice}} </del>
                        <span style="color:#d31d28;padding-left: 0.7rem;">
                            ₹{{common.getproductfetch.variantSalePrice}}
                        </span>
                    </span>


                    <select class="text-center flavour" *ngIf="common.flavourName!= ''" id="getflavour">
                        <option *ngFor="let i of common.flavourName" value="{{i.flavour_name}}">{{i.flavour_name}}
                        </option>
                    </select>
                </p>


            </div>

            <input *ngIf="common.getproductfetch.activeVariant && particularID" type="button" value="Add to Cart"
                class="cartBtn mobilebtn" (click)="addtocart(particularID,common.getproductfetch.slug)">

            <!-- 🚀 Prime price -->
            <!-- <p class="card-text variantName1 prime"><img src="assets/icons/crown.png" height="25" width="25">
                <strong>Prime Price: ₹{{common.getproductfetch.primePrice?common.getproductfetch.primePrice:'0'}} </strong></p> -->
            <!-- 🚀 Prime price end-->
            <p *ngIf="!common.getproductfetch.activeVariant" class="soldOut pt-3">Sold out! Back in stock soon.</p>

            <div class="row mt-3"
                *ngIf="common.websiteConfig && common.findConfig('product_page_static_line').length>0 && common.findConfig('product_page_static_line')[0].param.trim().length>0">
                <div class="col-12">
                    <div class="border p-3">
                        <div class="d-flex" [innerHTML]="common.findConfig('product_page_static_line')[0].param">
                            <!-- <img src="assets/images/gift.svg" class="img-fluid" width="20px" />
                            <h6 class="ml-2 mb-0 pt-1">Free <a href="https://sourceofsupplements.com/product/trunativ-fiber-100gms-16-serv" target="_blank" style="color: #3f5e82;">TruNativ Fiber</a> 
                            with cart above Rs. 8000</h6> -->
                        </div>
                    </div>
                </div>
            </div>

            <div class="border mt-3 rounded">
                <div class="row p-2">
                    <div class="col-2 col-md-4 col-lg-3 p-0">
                        <!-- <img width="150" height="140" class="img-fluid" src="assets/icons/Shop.png" alt="" appLazyLoad
                            onerror="this.src='assets/loader/200.gif'"> -->

                        <img width="150" height="150" class="img-fluid" src="assets/icons/percentage.png" alt=""
                            appLazyLoad onerror="this.src='assets/loader/200.gif'">
                    </div>
                    <div class="col-10 col-md-8 col-lg-9 d-flex align-items-center">
                        <p class="mb-3">
                            <!-- <strong>Dispatch:</strong> From your nearest Source Of Supplements<span
                                style="position: relative;bottom: 5px;">&reg;</span> - Store -->

                            <strong>Discount:</strong> Upto <strong>3%</strong> Off On Prepaid Orders <br>
                            Get Upto Rs.750 Cashback Paid via <strong>Mobikwik</strong> Wallet
                        </p>
                    </div>
                    <div class="col-2 col-md-4 col-lg-3 p-0">
                        <img width="150" height="140" class="img-fluid" src="assets/icons/Delivery.png" alt=""
                            appLazyLoad onerror="this.src='assets/loader/200.gif'">
                    </div>
                    <div class="col-10 col-md-8 col-lg-9 d-flex align-items-center">
                        <p>
                            <strong>Average time:</strong> 1- 3 days for Cities with SOS Stores, 3- 6 days for rest of
                            India
                        </p>
                    </div>
                    <div class="col-2 col-md-4 col-lg-3 p-0 d-flex align-items-center">
                        <img width="150" height="140" class="img-fluid" src="assets/icons/Premium Product.png" alt=""
                            appLazyLoad onerror="this.src='assets/loader/200.gif'">
                    </div>
                    <div class="col-10 col-md-8 col-lg-9 d-flex align-items-center">
                        <p>
                            <strong>Genuine Products:</strong> All our products are far from expiry, and
                            procured directly from the brand or authorized importers of the brand.

                            <!-- <strong>Indian Brands:</strong> All our products are procured directly from the Brand. -->

                            <br>
                            <strong>You can check our certificates <a routerLink="/certificates"
                                    style="color: #d31d28;">here</a></strong>
                        </p>
                    </div>
                </div>
            </div>

            <div class="card mt-3" id="accordion">
                <div class="card-header">
                    <h5 class="card-title text-center" data-toggle="collapse" data-target="#collapseOne">
                        <strong>Description</strong>
                    </h5>
                </div>

                <div id="collapseOne" class="panel-collapse collapse">
                    <div class="card-body">
                        <p class="productDesp" [innerHTML]="common.getproductfetch.productDescription"></p>
                    </div>
                </div>
            </div>
            <!-- #reviews & rating start-->
            <div class="card mt-3" id="accordion">
                <div class="card-header">
                    <h5 class="card-title text-center" data-toggle="collapse" data-target="#collapseTwo">
                        <strong>Reviews & Ratings</strong>
                    </h5>
                </div>

                <div id="collapseTwo" class="panel-collapse collapse">
                    <div class="card-body">
                        <div class="d-flex justify-content-between">
                            <span class="d-flex newlineMobile" *ngFor="let obj of overallCounting">
                                <span class="d-block align-item-center">
                                    <ngx-stars [readonly]="true" [size]="2" [maxStars]="5"
                                        [initialStars]="obj.TotalAverageRating">
                                    </ngx-stars>
                                </span>
                            </span>

                            <button type="button" class="rateBtn rounded widthForMobile"
                                (click)="reviewRatingOpenPopup()" *ngIf="isReviewAlreadyExits">
                                Rate Product
                            </button>
                        </div>

                        <hr>

                        <div *ngFor="let obj of allCustomersReviews; let i = index;">
                            <div class="row">
                                <div class="col-12">
                                    <p class="d-flex justify-content-between mb-0">
                                        {{obj.client.name}}

                                        <span class="h-25"
                                            *ngIf="this.common.userdetails && this.common.userdetails['userID']">
                                            <button type="button" class="rateBtn h-25 rounded"
                                                *ngIf="obj.clientId == this.common['userdetails']['userID'] ? true : false"
                                                (click)="editReview(obj)">
                                                <i class="fa fa-pencil text-white"></i>
                                            </button>
                                        </span>

                                    </p>

                                    <ngx-stars [readonly]="true" [size]="0" [maxStars]="5"
                                        [initialStars]="obj.ratingStar">
                                    </ngx-stars>

                                    <small class="text-muted">Reviewed on {{ obj.createdAt | date:'longDate'}}</small>

                                    <p class="word-break">{{obj.reviewDescription}}</p>

                                    <div class="border-left-reply rounded pl-3 pb-1 pr-3 ml-3"
                                        *ngIf="obj.sosReply != null">
                                        <small class="text-danger">Reply From Source of Supplements</small>
                                        <p class="pt-1">
                                            <small [innerHTML]="obj.sosReply"></small>
                                        </p>
                                    </div>

                                    <hr>
                                </div>
                            </div>
                        </div>
                        <button type="button" class="rateBtn rounded btn-sm mt" (click)="loadMoreReview()"
                            *ngIf="totlaCountRating == allCustomersReviews.length ? false : true">
                            Load More Reviews
                        </button>
                    </div>
                </div>
            </div>
            <!-- Seller Information  -->
            <div class="card mt-3" id="accordion">
                <div class="card-header">
                    <h5 class="card-title text-center" data-toggle="collapse" data-target="#collapseThree">
                        <strong>Seller Information</strong>
                    </h5>
                </div>

                <div id="collapseThree" class="panel-collapse collapse">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-2 col-md-4 col-lg-3 p-0">
                                <img width="150" height="150" class="img-fluid" src="assets/icons/sold_by.png" alt=""
                                    appLazyLoad onerror="this.src='assets/loader/200.gif'">
                            </div>
                            <div class="col-10 col-md-8 col-lg-9 d-flex align-items-center">
                                <p class="m-0">
                                    <strong>Sold By:</strong> {{common.getproductfetch && common.getproductfetch.soldBy ?
                                    common.getproductfetch.soldBy : ''}}
                                </p>
                            </div>
                            <div class="col-2 col-md-4 col-lg-3 p-0">
                                <img width="150" height="140" class="img-fluid" src="assets/icons/Manufacturer.png" alt=""
                                    appLazyLoad onerror="this.src='assets/loader/200.gif'">
                            </div>
                            <div class="col-10 col-md-8 col-lg-9 d-flex align-items-center">
                                <p>
                                    <strong>Manufacturer:</strong> {{common.getproductfetch &&
                                    common.getproductfetch.manufacturer ? common.getproductfetch.manufacturer : '' }}
                                </p>
                            </div>
                            <div class="col-2 col-md-4 col-lg-3 p-0 d-flex align-items-center">
                                <img width="150" height="140" class="img-fluid" src="assets/icons/Support_Details.png"
                                    alt="" appLazyLoad onerror="this.src='assets/loader/200.gif'">
                            </div>
                            <div class="col-10 col-md-8 col-lg-9 d-flex align-items-center">
                                <p>
                                    <strong>Support Details:</strong> {{common.getproductfetch &&
                                    common.getproductfetch.supportDetails ? common.getproductfetch.supportDetails : ''}}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <!-- #reviews & rating end-->

        <!-- Seller Information -->

        <!-- <div class="col-12">
            <div class="card mt-3">
                <div class="card-header">
                    <h5><strong>Seller Information</strong></h5>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-2 col-md-4 col-lg-3 p-0">
                            <img width="150" height="150" class="img-fluid" src="assets/icons/sold_by.png" alt=""
                                appLazyLoad onerror="this.src='assets/loader/200.gif'">
                        </div>
                        <div class="col-10 col-md-8 col-lg-9 d-flex align-items-center">
                            <p class="m-0">
                                <strong>Sold By:</strong> {{common.getproductfetch && common.getproductfetch.soldBy ?
                                common.getproductfetch.soldBy : ''}}
                            </p>
                        </div>
                        <div class="col-2 col-md-4 col-lg-3 p-0">
                            <img width="150" height="140" class="img-fluid" src="assets/icons/Manufacturer.png" alt=""
                                appLazyLoad onerror="this.src='assets/loader/200.gif'">
                        </div>
                        <div class="col-10 col-md-8 col-lg-9 d-flex align-items-center">
                            <p>
                                <strong>Manufacturer:</strong> {{common.getproductfetch &&
                                common.getproductfetch.manufacturer ? common.getproductfetch.manufacturer : '' }}
                            </p>
                        </div>
                        <div class="col-2 col-md-4 col-lg-3 p-0 d-flex align-items-center">
                            <img width="150" height="140" class="img-fluid" src="assets/icons/Support_Details.png"
                                alt="" appLazyLoad onerror="this.src='assets/loader/200.gif'">
                        </div>
                        <div class="col-10 col-md-8 col-lg-9 d-flex align-items-center">
                            <p>
                                <strong>Support Details:</strong> {{common.getproductfetch &&
                                common.getproductfetch.supportDetails ? common.getproductfetch.supportDetails : ''}}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->

        <!-- /Seller Information -->


        <div class="col-12 mt-3" *ngIf="bannerOne" (click)="bannerClick(bannerOne.id, bannerOne.link)">
            <img width="600" height="270" [src]="bannerOne.mobile_banner_photo" appLazyLoad
                onerror="this.src='assets/images/notfound.jpg'" class="img-fluid cursor rounded"
                alt="{{bannerOne.altText}}">
        </div>

        <div class="col-12 mt-3" *ngIf="bannerTwo" (click)="bannerClick(bannerTwo.id, bannerTwo.link)">
            <img width="600" height="270" [src]="bannerTwo.mobile_banner_photo" appLazyLoad
                onerror="this.src='assets/images/notfound.jpg'" class="img-fluid cursor rounded"
                alt="{{bannerTwo.altText}}">
        </div>

        <div class="col-lg-12" style="padding-top: 1rem;">
            <hr>
            <p class="relatedText">Similar Products</p>
            <div class="row pt-5 m-0">
                <div class="col-lg-12 p-0">

                    <swiper [config]="commonConfig">
                        <div class="swiper-wrapper">
                            <div class="swiper-slide" *ngFor="let z of common.similar_product">
                                <div class="discountCircleSIM">
                                    <p class="discountTextSIM">
                                        {{(((z.variantPrice - z.variantSalePrice) / z.variantPrice) * 100).toFixed(0)}}%
                                    </p>
                                </div>
                                <div class="card d-flex text-center border-0">
                                    <div class="full-width-link" [id]="z.id"></div>
                                    <div class="card-body p-0" (click)="similarproduct(z); cardClick(z.id)">
                                        <img width="230" height="230" appLazyLoad
                                            onerror="this.src='assets/loader/200.gif'"
                                            *ngIf="z.productVariantPictures && z.productVariantPictures[0]"
                                            [src]="'https://api.sourceofsupplements.com/product-variant-pic/'+z.productVariantPictures[0].productVariantPhoto"
                                            class="img-fluid">

                                    </div>
                                    <div class="card-footer w-100 p-0 bg-white border-0">
                                        <p class="card-text variantName text-ellipsis"
                                            style="margin-bottom: 0px; font-size: 14px;">
                                            {{z.variantName}}
                                        </p>
                                        <p class="card-text">
                                            <del style="color: black;"><b>{{z.variantPrice}}</b></del>&nbsp;
                                            <b style="color: #d31d28;">{{z.variantSalePrice}}</b>
                                            <input *ngIf="z.activeVariant" type="button" class="addCart"
                                                value="Add to Cart" (click)="addtocart(z.id, z.slug)">

                                            <!-- 🚀 Prime price -->
                                            <!-- <p class="card-text variantName2 prime"><img src="assets/icons/crown.png"
                                                height="25" width="25">
                                            <strong>Prime Price: ₹{{z.primePrice?z.primePrice:'0'}} </strong></p> -->
                                            <!-- 🚀 Prime price end-->
                                        <p *ngIf="!z.activeVariant" class="soldOut">Sold Out</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Add Arrows -->

                        <div class="swiper-button-next"></div>
                        <div class="swiper-button-prev"></div>

                    </swiper>
                </div>
            </div>
        </div>

        <!-- new code -->

        <!--Beyond Gummies-->
        <div class="col-lg-12 d-none" style="padding-top: 1rem;">
            <hr>
            <div class="row" *ngIf="findBrandByName('Beyond Gummies').length>0">
                <div class="col-12 pt-2 px-1">
                    <h1 class="trendingInCSS pl-2 pt-2">Beyond Gummies</h1>
                    <p class="viewallCSS pl-2"
                        (click)="routingBrands(findBrandByName('Beyond Gummies')[0].brandid.slug)">
                        <span>View All <i class="fa fa-caret-right fa-lg" aria-hidden="true"></i>
                        </span>
                    </p>
                </div>
                <div class="col-12">
                    <swiper [config]="commonConfig">
                        <div class="swiper-wrapper">
                            <div class="swiper-slide" *ngFor="let i of findBrandByName('Beyond Gummies')[0].data">
                                <div class="card border-0">
                                    <div class="full-width-link" [id]="i.id"></div>
                                    <div class="card-body p-0" (click)="cardClick(i.id)">
                                        <a href="{{commService.currentHost}}/product/{{i.slug}}">
                                            <div class="discountCircleHome">
                                                <p class="discountText">
                                                    {{(((i.variantPrice - i.variantSalePrice) / i.variantPrice) *
                                                    100).toFixed(0)}}%</p>
                                            </div>

                                            <img width="230" height="230" class="img-fluid"
                                                src="{{i.productVariantPictures.length>0?i.productVariantPictures[0].productVariantThumbnail:'assets/loader/200.gif'}}"
                                                onerror="this.src='assets/loader/200.gif'">
                                        </a>
                                    </div>
                                    <div class="card-footer  w-100 p-0 bg-white border-0">
                                        <div class="variantNamecontain">
                                            <p class="card-text variantName text-ellipsis"
                                                style="margin-bottom: 0px; font-size: 14px;">
                                                {{i.variantName}}</p>
                                        </div>
                                        <p class="card-text">
                                            <del style="color: black;"><b>₹{{i.variantPrice}}</b></del>&nbsp;
                                            <b style="color: #d31d28;">₹{{i.variantSalePrice}}</b>
                                            <input *ngIf="i.activeVariant" type="button" class="addCart"
                                                value="Add to Cart" (click)="common.addtocart(i.id, i.slug)">

                                            <!-- 🚀 Prime price -->
                                            <!-- <p class="card-text variantName1 prime">
                <img src="assets/icons/crown.png" height="25" width="25">
                <strong>Prime Price: ₹{{i.primePrice?i.primePrice:'0'}} </strong>
              </p> -->
                                            <!-- 🚀 Prime price end-->
                                        <p *ngIf="!i.activeVariant" class="soldOut">Sold Out</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="swiper-button-next"></div>
                        <div class="swiper-button-prev"></div>

                    </swiper>
                </div>
            </div>
        </div>
        <!--Beyond Gummies-->

        <!--Bubble Up Nutrition-->
        <div class="col-lg-12" style="padding-top: 1rem;" *ngIf="findBrandByName('Bubble Up Nutrition').length>0">
            <hr>
            <div class="row" *ngIf="findBrandByName('Bubble Up Nutrition').length>0">
                <div class="col-12 pt-2 px-1">
                    <h1 class="trendingInCSS pl-2 pt-2">Bubble Up Nutrition</h1>
                    <p class="viewallCSS pl-2"
                        (click)="routingBrands(findBrandByName('Bubble Up Nutrition')[0].brandid.slug)">
                        <span>View All <i class="fa fa-caret-right fa-lg" aria-hidden="true"></i>
                        </span>
                    </p>
                </div>
                <div class="col-12">
                    <swiper [config]="commonConfig">
                        <div class="swiper-wrapper">
                            <div class="swiper-slide" *ngFor="let i of findBrandByName('Bubble Up Nutrition')[0].data">
                                <div class="card border-0">
                                    <div class="full-width-link" [id]="i.id"></div>
                                    <div class="card-body p-0" (click)="cardClick(i.id)">
                                        <a href="{{commService.currentHost}}/product/{{i.slug}}">
                                            <div class="discountCircleHome">
                                                <p class="discountText">
                                                    {{(((i.variantPrice - i.variantSalePrice) / i.variantPrice) *
                                                    100).toFixed(0)}}%</p>
                                            </div>

                                            <img width="230" height="230" class="img-fluid"
                                                src="{{i.productVariantPictures.length>0?i.productVariantPictures[0].productVariantThumbnail:'assets/loader/200.gif'}}"
                                                onerror="this.src='assets/loader/200.gif'">
                                        </a>
                                    </div>
                                    <div class="card-footer  w-100 p-0 bg-white border-0">
                                        <div class="variantNamecontain">
                                            <p class="card-text variantName text-ellipsis"
                                                style="margin-bottom: 0px; font-size: 14px;">
                                                {{i.variantName}}</p>
                                        </div>
                                        <p class="card-text">
                                            <del style="color: black;"><b>₹{{i.variantPrice}}</b></del>&nbsp;
                                            <b style="color: #d31d28;">₹{{i.variantSalePrice}}</b>
                                            <input *ngIf="i.activeVariant" type="button" class="addCart"
                                                value="Add to Cart" (click)="common.addtocart(i.id, i.slug)">

                                            <!-- 🚀 Prime price -->
                                            <!-- <p class="card-text variantName1 prime">
                            <img src="assets/icons/crown.png" height="25" width="25">
                            <strong>Prime Price: ₹{{i.primePrice?i.primePrice:'0'}} </strong>
                          </p> -->
                                            <!-- 🚀 Prime price end-->
                                        <p *ngIf="!i.activeVariant" class="soldOut">Sold Out</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="swiper-button-next"></div>
                        <div class="swiper-button-prev"></div>

                    </swiper>
                </div>
            </div>
        </div>
        <!--Bubble Up Nutrition-->

        <!--SOS Clothing & Accessories-->
        <div class="col-lg-12" style="padding-top: 1rem;" *ngIf="findBrandByName('Source Of Supplements').length>0">
            <hr>
            <div class="row" *ngIf="findBrandByName('Source Of Supplements').length>0">
                <div class="col-12 pt-2 px-1">
                    <h1 class="trendingInCSS pl-2 pt-2">Source Of Supplements</h1>
                    <p class="viewallCSS pl-2"
                        (click)="routingBrands(findBrandByName('Source Of Supplements')[0].brandid.slug)">
                        <span>View All <i class="fa fa-caret-right fa-lg" aria-hidden="true"></i>
                        </span>
                    </p>
                </div>
                <div class="col-12">
                    <swiper [config]="commonConfig">
                        <div class="swiper-wrapper">
                            <div class="swiper-slide"
                                *ngFor="let i of findBrandByName('Source Of Supplements')[0].data">
                                <div class="card border-0">
                                    <div class="full-width-link" [id]="i.id"></div>
                                    <div class="card-body p-0" (click)="cardClick(i.id)">
                                        <a href="{{commService.currentHost}}/product/{{i.slug}}">
                                            <div class="discountCircleHome">
                                                <p class="discountText">
                                                    {{(((i.variantPrice - i.variantSalePrice) / i.variantPrice) *
                                                    100).toFixed(0)}}%</p>
                                            </div>

                                            <img width="230" height="230" class="img-fluid"
                                                src="{{i.productVariantPictures.length>0?i.productVariantPictures[0].productVariantThumbnail:'assets/loader/200.gif'}}"
                                                onerror="this.src='assets/loader/200.gif'">
                                        </a>
                                    </div>
                                    <div class="card-footer  w-100 p-0 bg-white border-0">
                                        <div class="variantNamecontain">
                                            <p class="card-text variantName text-ellipsis"
                                                style="margin-bottom: 0px; font-size: 14px;">
                                                {{i.variantName}}</p>
                                        </div>
                                        <p class="card-text">
                                            <del style="color: black;"><b>₹{{i.variantPrice}}</b></del>&nbsp;
                                            <b style="color: #d31d28;">₹{{i.variantSalePrice}}</b>
                                            <input *ngIf="i.activeVariant" type="button" class="addCart"
                                                value="Add to Cart" (click)="common.addtocart(i.id, i.slug)">

                                            <!-- 🚀 Prime price -->
                                            <!-- <p class="card-text variantName1 prime">
                              <img src="assets/icons/crown.png" height="25" width="25">
                              <strong>Prime Price: ₹{{i.primePrice?i.primePrice:'0'}} </strong>
                            </p> -->
                                            <!-- 🚀 Prime price end-->
                                        <p *ngIf="!i.activeVariant" class="soldOut">Sold Out</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="swiper-button-next"></div>
                        <div class="swiper-button-prev"></div>

                    </swiper>
                </div>
            </div>
        </div>
        <!--SOS Clothing & Accessories-->

        <!-- end new code -->
    </div>
</div>

<!-- Rate Product Popup -->

<div class="modal fade" bsModal #rateProductPopUp="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
    aria-labelledby="dialog-static-name">
    <div class="modal-dialog modal-md">
        <div class="modal-content">
            <div class="modal-header">
                <h4 id="dialog-static-name" class="modal-title pull-left">Rate Product</h4>
                <button type="button" class="close pull-right" aria-label="Close" (click)="rateProductPopUp.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">

                <div class="row">
                    <div class="col-12">
                        <p>
                            <small>
                                Rate this product
                            </small>
                        </p>
                    </div>

                    <div class="col-12">
                        <ngx-stars [readonly]="false" [size]="0" [maxStars]="5" (ratingOutput)="onRatingSet($event)">
                        </ngx-stars>
                    </div>

                    <div class="col-12">
                        <p class="text-danger">
                            <small>Note: Star rating is Mandatory</small>
                        </p>
                    </div>

                    <div class="col-12">
                        <p>
                            <small>
                                Review this product
                            </small>
                        </p>

                        <div class="form-group">
                            <div class="append-icon">
                                <textarea class="form-control" rows="5" placeholder="Description..."
                                    [(ngModel)]="description" maxlength="600" #maxReviewLimt></textarea>
                                <div class="text-danger mt-2" *ngIf="maxReviewLimt.value.length > 0">*Max 600
                                    characters only.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="sumbitBtn" [ngClass]="{'disabled': (!rate)?true:false }"
                    (click)="submitReview()">
                    Submit
                </button>
            </div>
        </div>
    </div>
</div>

<!-- Edit Review Popup -->

<div class="modal fade" bsModal #rateProductEditPopUp="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1"
    role="dialog" aria-labelledby="dialog-static-name">
    <div class="modal-dialog modal-md">
        <div class="modal-content">
            <div class="modal-header">
                <h4 id="dialog-static-name" class="modal-title pull-left">Rate Product</h4>
                <button type="button" class="close pull-right" aria-label="Close" (click)="rateProductEditPopUp.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">

                <div class="row" *ngFor="let editObj of editReviewArray">
                    <div class="col-12">
                        <p>
                            <small>
                                Rate this product
                            </small>
                        </p>
                    </div>

                    <div class="col-12">
                        <ngx-stars [readonly]="false" [size]="0" [initialStars]="editObj.ratingStar" [maxStars]="5"
                            (ratingOutput)="onRatingEditSet($event)">
                        </ngx-stars>
                    </div>

                    <div class="col-12">
                        <p class="text-danger">
                            <small>Note: Star rating is Mandatory</small>
                        </p>
                    </div>

                    <div class="col-12">
                        <p>
                            <small>
                                Review this product
                            </small>
                        </p>

                        <div class="form-group">
                            <div class="append-icon">
                                <textarea class="form-control" rows="5" placeholder="Description..."
                                    [(ngModel)]="editDescription" maxlength="600" #maxReviewLimtEdit></textarea>
                                <div class="text-danger mt-2" *ngIf="maxReviewLimtEdit.value.length > 0">*Max 600
                                    characters only.</div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="modal-footer">
                <button type="button" class="sumbitBtn" (click)="updateReview()">
                    Submit
                </button>
            </div>
        </div>
    </div>
</div>

<app-footer></app-footer>


<ngx-spinner bdColor="rgba(0, 0, 0, 0.9)" size="medium" color="#fff" type="ball-atom">
    <p style="color: white"> Please wait... </p>
</ngx-spinner>