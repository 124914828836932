import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule, Router } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { HttpClientModule } from '@angular/common/http';
import { LoginAndRegisterComponent } from './login-and-register/login-and-register.component';
import { MyAccountComponent } from './my-account/my-account.component';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { ToastrModule } from 'ngx-toastr';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgOtpInputModule } from 'ng-otp-input';

const routes: Routes = [
  {
    path: 'login-register', component: LoginAndRegisterComponent
  },
  {
    path: 'my-account', component: MyAccountComponent
  },
  {
    path: 'forgot-password', component: ForgotPasswordComponent
  },
  {
    path: 'reset-password/:token', component: ResetPasswordComponent
  },
]

@NgModule({
  declarations: [
    LoginAndRegisterComponent,
    MyAccountComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    SharedModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      preventDuplicates: true,
      disableTimeOut: false,
    }),
    NgxSpinnerModule,
    ModalModule.forRoot(),
    NgOtpInputModule
  ],
  exports: [
    RouterModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AuthModule { 
  constructor(private router: Router) {
    this.router.errorHandler = (error: any) => {
      console.log('invalid file/route');
    }
  }
}
