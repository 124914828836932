    <!-- sort Modal -->
    <div class="modal fade" id="sortBy" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title text-dark" id="exampleModalLabel"><strong>Sort by </strong></h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-12">
                            <app-sort-by></app-sort-by>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn resetBtn" (click)="reset()">Reset</button>
                    <button type="button" class="btn applyBtn"  data-dismiss="modal" aria-label="Close">Apply changes</button>
                </div>
            </div>
        </div>
    </div>

    <!-- filter Modal -->
    <div class="modal fade" id="filterBy" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title text-dark" id="exampleModalLabel">Filter by</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-12">
                            <app-filter-by></app-filter-by>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn resetBtn" (click)="reset()">Reset</button>
                    <button type="button" class="btn applyBtn"  data-dismiss="modal" aria-label="Close">Apply changes</button>
                </div>
            </div>
        </div>
    </div>

    <div class="row m-0 fixed-bottom onlyMobileSortFiltr">
        <div class="col-12 p-0">
            <!-- <input type="button" value="Sort By" id="sort-mobile" class="bottomBtn" data-toggle="modal" data-target="#sortBy">
            <input type="button" value="Filter By" class="bottomBtn" data-toggle="modal" data-target="#filterBy"> -->

            <button type="button" id="sort-mobile" class="bottomBtn" data-toggle="modal" data-target="#sortBy">
                <i class="fa fa-sort-amount-desc"></i> Sort By
            </button>

            <button type="button" class="bottomBtn" data-toggle="modal" data-target="#filterBy">
                <i class="fa fa-filter" aria-hidden="true"></i> Filter By
            </button>
        </div>
    </div>