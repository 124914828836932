import { AfterViewInit, Component, OnInit } from '@angular/core';
import { StorageService as newStorage } from '../services/newstorage.service';
import { Router } from '@angular/router';
import { DomainService } from '../services/domain.service';
import { CommonService } from '../services/common.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import { ScullyRoutesService } from '@scullyio/ng-lib';
import { SwiperOptions } from 'swiper';

declare var $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit, AfterViewInit {

  config: SwiperOptions = {
    pagination: { el: '.swiper-pagination', clickable: true },
    spaceBetween: 10,
    autoplay: true,
  };

  commonConfig: SwiperOptions = {
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    spaceBetween: 10,
    slidesPerView: 2.40,
    preloadImages: false,
    pagination: {
      clickable: true,
    },
    breakpoints: {
      640: {
        slidesPerView: 2.40,
        spaceBetween: 20,
      },
      768: {
        slidesPerView: 2.40,
        spaceBetween: 40,
      },
      1024: {
        slidesPerView: 2.40,
        spaceBetween: 20,
      },
      1920: {
        slidesPerView: 2.40,
        spaceBetween: 12,
      },
      2560: {
        slidesPerView: 2.40,
        spaceBetween: 12,
      },
    },
    observer: true,
    observeParents: true,
  }

  public testArray = [{ 'name': 1 }, { 'name': 2 }, { 'name': 3 }, { 'name': 4 }]
  public apitoken: any;

  public silder: any;
  public demosilder: any = ['silder1', 'silder2', 'silder3'];

  mySwiper: any;
  pop3: any;
  pop2: any;
  pop1: any;
  trending1: any;
  trending2: any;
  trending3: any;
  trending: any;
  trending4: any;
  trending5: any;
  newlyLaunched: any[] = [];
  img: any;
  getpic: any;
  newsLetter: boolean;
  subscribeMobile: any;
  dontSubs: any;
  userdetails: any;
  siddir: any = 'dir="rtl"';
  selectedid: any;
  link: string;
  /* banner1: any;
  banner2: any;
  banner3: any; */
  banners: any;

  stackDeals: any[] = [];
  metaTagData$ = this.srs.getCurrent();
  brandProducts: any = [];
  brands: any = [];

  constructor(
    public common: DomainService,
    public newStorage: newStorage,
    public router: Router,
    private toastr: ToastrService,
    public commService: CommonService,
    private srs: ScullyRoutesService,
    private spinner: NgxSpinnerService,
  ) { }

  ngOnInit() {
    this.commService.updateMetaTages('Best Supplement Store – Buy Health Supplements & Nutrition Products Online in India', [
      { name: 'keywords', content: 'body building, cardio, exercise, fitness, gym, health, physical, weight lifting, workout, yoga' },
      { name: 'og:title', content: 'Best Supplement Store – Buy Health Supplements & Nutrition Products Online in India' },
      { name: 'title', content: 'Best Supplement Store – Buy Health Supplements & Nutrition Products Online in India' },
      { name: 'og:description', content: 'Source of Supplements is provider of genuine health supplements, whey protein and nutrition products online in India. Get free & safe delivery all over India.' },
      { name: 'description', content: 'Source of Supplements is provider of genuine health supplements, whey protein and nutrition products online in India. Get free & safe delivery all over India.' },
      { name: 'og:url', content: 'https://www.sourceofsupplements.com' },
      { name: 'og:image', content: 'https://sourceofsupplements.com/assets/icons/SOS-Favicon.png' },
      { name: 'image', content: 'https://sourceofsupplements.com/assets/icons/SOS-Favicon.png' },
      { name: "robots", content: "index, follow" },
    ]);
    this.commService.createCanonicalURL();
    this.initCall();
  }

  initCall() {
    this.newStorage.deleteItem("PDA");
    this.newStorage.deleteItem("PDP");

    this.spinner.show();
    this.producttrending1();
    this.producttrending2();
    this.producttrending3();
    this.producttrending4();
    this.producttrending5();
    this.producttrending6();
    this.producttrending7();
    this.productpopular1();
    this.sildersget();
    this.bannerget();
    this.getStackDeals();
    this.getAllBrands();

  }

  sliderClick(id, link) {
    var data = {
      id: id
    }
    this.common.slidergetclick(data, this.common.httpOptions).subscribe(res => {
      window.open(link, '_blank');
    })
  }

  bannerClick(id, link) {
    var data = {
      id: id
    }
    this.common.bannergetclick(data, this.common.httpOptions).subscribe(res => {
      window.open(link, '_blank');
    })
  }

  ngAfterViewInit() {
    // this.common.getUserAccountDetails();
  }

  sildersget() {
    this.common.silderget().subscribe(res => {
      this.silder = res['data'];
    }, err => { });
  }

  producttrending1() {
    var data = 'trending=1&categoryId=6';
    this.common.productget(data).subscribe(res => {
      this.trending = res['data'];
      // console.log(this.trending)
    }, err => { });
  }

  producttrending2() {
    var data = 'trending=1&subCategoryId=9';
    this.common.productget(data).subscribe(res => {
      this.trending1 = res['data'];
    }, err => { });
  }

  producttrending3() {
    var data = 'trending=1&categoryId=2';
    this.common.productget(data).subscribe(res => {
      this.trending2 = res['data'];
    }, err => { });
  }

  producttrending4() {
    var data = 'trending=1&subCategoryId=10';
    this.common.productget(data).subscribe(res => {
      this.trending3 = res['data'];
    }, err => { });
  }

  producttrending5() {
    var data = 'trending=1&categoryId=7';
    this.common.productget(data).subscribe(res => {
      this.trending4 = res['data'];
    }, err => { });
  }

  producttrending6() {
    this.common.newlyget().subscribe(res => {
      this.newlyLaunched = res['data'];
    }, err => { });
  }

  producttrending7() {
    var data = 'trending=1&categoryId=5';
    this.common.productget(data).subscribe(res => {
      this.trending5 = res['data'];
    }, err => { });
  }

  productpopular1() {
    var data = 'popular=1';
    this.common.brandget(data).subscribe(res => {
      this.pop1 = res['data'];
    }, err => { });
  }

  getAllBrands() {
    this.common.brandget('').subscribe(res => {
      this.brands = res['data'];
      // console.log(this.brands);
      this.getProductByBrand("Optimum Nutrition");
      this.getProductByBrand("Cutler Nutrition");
      this.getProductByBrand("Muscle Tech");
      this.getProductByBrand("Bubble Up Nutrition");
      this.getProductByBrand("The Dr Choice");
      this.getProductByBrand("IN2 Nutrition");
      this.getProductByBrand("Labrada");
      this.getProductByBrand("bpi Sports");
      this.getProductByBrand("One Science");
      this.getProductByBrand("Source Of Supplements");
      // this.getProductByBrand("SOS");
    }, err => { });
  }

  getProductByBrand(name) {
    let fdata = this.brands.filter(f => f.brand_name.toLowerCase().includes(name.toLowerCase()))
    // console.log(fdata, name);
    if (fdata.length > 0) {
      this.common.productBySKUget('all_product=1&shop_by_brand=1&brandId=' + fdata[0].id).subscribe((res: any) => {
        if (res['data'].length > 0) {
          this.brandProducts.push({ brandid: res['data'][0].brand, data: res['data'] });
        }
        // console.log(this.brandProducts,name);
      }, err => { console.log(err) });
    }
  }

  bannerget() {
    this.common.bannergetvalue().subscribe(res => {
      /* this.banner1 = res['data'][0];
      this.banner2 = res['data'][1];
      this.banner3 = res['data'][2]; */
      this.banners = res['data'];
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
    });
  }

  getbrand(get) {
    this.router.navigate([`/brand/${get.slug}`]);
  }

  gotoProduct(get) {
    get.brand.brand_name = get.brand.brand_name.replace(/ /g, "-");
    this.router.navigate([`/product/${get.slug}`]);
  }

  subsNewsletter() {
    this.subscribeMobile = $("#subscribeMobile").val();
    if (this.subscribeMobile.length == 10) {
      var data = {
        'mobile_number': this.subscribeMobile,
      }
      this.common.subscribeNL(data).subscribe(res => {
        this.toastr.success(res['message'])
        this.newStorage.updateItem('Subscribed', 2);
        this.common.newsLetter = false;
      }, err => {
        console.log(err.error);
        this.toastr.error(err.error["message"][0].msg);
      })
    } else {
      this.toastr.error('Please Enter 10-Digit Number');
    }
  }

  closeLetter() {
    this.newStorage.updateItem('Subscribed', 1);
    this.common.newsLetter = false;
  }

  getStackDeals() {
    this.common.stackDeals().subscribe(res => {
      this.stackDeals = res['data'];
    });
  }

  routingProtein() {
    let url = window.location.origin + '/trending-in-protein';
    window.open(url, "_blank");
  }

  routingPopularBrands() {
    let url = window.location.origin + '/popular-brands';
    window.open(url, "_blank");
  }

  routingIntraworkouts() {
    let url = window.location.origin + '/trending-in-intra-workouts';
    window.open(url, "_blank");
  }

  routingGainers() {
    let url = window.location.origin + '/trending-in-gainers';
    window.open(url, "_blank");
  }

  routingPreworkouts() {
    let url = window.location.origin + '/trending-in-pre-workouts';
    window.open(url, "_blank");
  }

  routingVitamins() {
    let url = window.location.origin + '/trending-in-vitamins-and-health';
    window.open(url, "_blank");
  }

  routingNewlyLauched() {
    let url = window.location.origin + '/newly-launched';
    window.open(url, "_blank");
  }

  routingSnacksTrending() {
    let url = window.location.origin + '/snacks-trending';
    window.open(url, "_blank");
  }

  routingStackDeals() {
    let url = window.location.origin + '/stack-deals';
    window.open(url, "_blank");
  }

  routingBrands(slug) {
    let url = window.location.origin + `/brand/${slug}`;
    window.open(url, "_blank");
  }

  findBrandByName(name) {
    let a = this.brandProducts.filter(f => f.brandid.brand_name.toLowerCase().includes(name.toLowerCase()));
    // console.log(a,name)
    return a;
  }

  cardClick(id) {
    if (id) {
      document.getElementById(id).style.display = "block";
      setTimeout(() => {
        document.getElementById(id).style.display = "none";
      }, 300);
    }
  }

}
