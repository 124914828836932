import { Component, OnInit } from '@angular/core';
import { DomainService } from 'src/app/services/domain.service';
import { Router } from '@angular/router';
import { CommonService } from './../../services/common.service';
import { ScullyRoutesService } from '@scullyio/ng-lib';
import { NgxSpinnerService } from 'ngx-spinner';

declare var $: any

@Component({
  selector: 'app-shop-by-brand',
  templateUrl: './shop-by-brand.component.html',
  styleUrls: ['./shop-by-brand.component.scss']
})
export class ShopByBrandComponent implements OnInit {
  brandgetall: any;
  metaTagData$ = this.srs.getCurrent();

  arrayOne(n: number): any[] {
    return Array(n)
  }
  constructor(public common: DomainService,
    public router: Router,
    public commService: CommonService,
    private srs: ScullyRoutesService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    this.spinner.show();
    this.commService.updateMetaTages('Shop Health Supplements & Nutrition Products from Leading Brands', [
      { name: 'og:title', content: 'Shop Health Supplements & Nutrition Products from Leading Brands' },
      { name: 'og:description', content: "Shop 100% genuine health, nutritional & bodybuilding supplements, Whey Proteins, Vitamins online from India's #1 supplements store SOS at best price with Free Shipping!" },
      { name: 'description', content: "Shop 100% genuine health, nutritional & bodybuilding supplements, Whey Proteins, Vitamins online from India's #1 supplements store SOS at best price with Free Shipping!" },
      { name: 'og:image', content: 'https://sourceofsupplements.com/assets/icons/SOS-Favicon.png' },
      { name: 'image', content: 'https://sourceofsupplements.com/assets/icons/SOS-Favicon.png' },
    ]);
    this.commService.createCanonicalURL();

    this.brandget();

  }

  brandget() {
    var data = '';
    this.common.brandget(data).subscribe(res => {
      this.brandgetall = res['data'];
    }, err => {
      this.spinner.hide();
    });
    setTimeout(() => {
      this.spinner.hide();
    }, 1000);    
  }


  getRouteBrand(brandname) {
    return brandname.replace(/ /g, "-");
  }

  cardClick(id){    
    if(id){
      document.getElementById(id).style.display = "block";
      setTimeout(() => {
        document.getElementById(id).style.display = "none";
      }, 300);
    }
  }

}
