import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DomainService } from 'src/app/services/domain.service';
import { ToastrService } from 'ngx-toastr';
import { CommonFunctions } from 'src/app/common.function';
import { HttpHeaders } from '@angular/common/http';
import { CommonService } from './../../services/common.service';
import { ScullyRoutesService } from '@scullyio/ng-lib';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-brand',
  templateUrl: './brand.component.html',
  styleUrls: ['./brand.component.scss']
})
export class BrandComponent implements OnInit {
  public sortBy: boolean;
  public filter: boolean;
  id: string;
  slug: string;
  brandgetall: any;
  brandname: string;
  branddescription: string;
  httpOptions: { headers: HttpHeaders; };

  metaTagData$ = this.srs.getCurrent();

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public common: DomainService,
    private toastr: ToastrService,
    public commService: CommonService,
    private srs: ScullyRoutesService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    this.spinner.show();
    this.route.params.subscribe(routeParams => {
      this.slug = routeParams.slug;
      this.brandSEOGet().finally(() => {
        this.getBrandDetails();
      });
    });

    ;
  }

  brandSEO() {
    let brandIndex = CommonFunctions.findKeyValueIndex(this.commService.brandSEO, 'id', this.id);
    if (brandIndex) {
      this.commService.updateMetaTages(this.commService.brandSEO[brandIndex]['title'], [
        { name: 'og:title', content: this.commService.brandSEO[brandIndex]['title'] },
        { name: 'title', content: this.commService.brandSEO[brandIndex]['title'] },
        { name: 'og:description', content: this.commService.brandSEO[brandIndex]['description'] },
        { name: 'description', content: this.commService.brandSEO[brandIndex]['description'] },
        { name: 'og:image:secure_url', content: this.commService.brandSEO[brandIndex]['img'] },
        { name: 'image', content: this.commService.brandSEO[brandIndex]['img'] },
      ]);
    } else {
      this.commService.updateMetaTages(this.brandname, [
        { name: 'og:title', content: this.brandname },
        { name: 'title', content: this.brandname },
        { name: 'og:description', content: this.brandname },
        { name: 'description', content: this.brandname },
        { name: 'og:image:secure_url', content: 'https://sourceofsupplements.com/assets/icons/SOS-Favicon.png' },
        { name: 'image', content: 'https://sourceofsupplements.com/assets/icons/SOS-Favicon.png' },
      ]);
    }

    this.commService.createCanonicalURL();
  }

  brandSEOGet() {
    return new Promise((resolve, reject) => {
      this.common.brandSEOget().subscribe(res => {
        for (let index = 0; index < res['data'].length; index++) {
          const ele = res['data'][index];
          this.commService.brandSEO.push({
            id: ele.id,
            brand: ele.brand_name,
            title: ele.meta_title,
            description: ele.brand_description,
            img: ele.brand_image
          })
        }
        resolve(true);
      }, err => {
        resolve(false);
      });
    });
  }

  brandget() {
    var data = 'all_product=1&shop_by_brand=1&brandId=' + this.id;
    this.common.filterbyALl(data, 'brand');
  }

  getBrandDetails() {
    let data = `slug=${this.slug}`;
    this.common.brandget(data).subscribe(res => {
      try {
        this.id = res['data'][0].id;
        this.brandname = res['data'][0].brand_name;
        this.brandname = this.brandname.replace(/-/g, " ");
        this.branddescription = res['data'][0].pageDescription;
        this.brandSEO()
        this.brandget();
      } catch (error) {
        this.spinner.hide();
        this.router.navigate(['/']);
      }
      this.spinner.hide();
    }, err => { 
      this.spinner.hide();
    });
  }

  showFilterBox() {
    this.filter = this.filter ? false : true;
    this.sortBy = false;
  }

  showSortBy() {
    this.sortBy = this.sortBy ? false : true;
    this.filter = false;
  }


  getbrand(get, brandname) {
    const slug = get.slug;
    brandname = brandname.replace(/ /g, "-");
    this.router.navigate([`/product/${slug}`]);
  }

  cardClick(id){    
    if(id){
      document.getElementById(id).style.display = "block";
      setTimeout(() => {
        document.getElementById(id).style.display = "none";
      }, 300);
    }
  }

}
