<app-header></app-header>

<title> Cart | SOS</title>

<div class="container topPadding" (click)="common.closeNav()">
  <div class="row mt-3">
    <div class="col-12 mb-4">
      <div class="card bg_boxshadow">

        <div class="card-header bg-white text-left p-4" *ngIf="common.cartdetails">
          MY CART ({{common.counterproduct}})
        </div>

        <div class="card-body bg-white" *ngIf="common.cartdetails">
          <div class="row py-3" [style]="(common.cartdetails.length==j+1)?'border-bottom:1px solid white':'border-bottom:1px solid rgba(0,0,0,.125)'" *ngFor="let i of common.cartdetails;let j = index">
            <div class="col-4 text-center">
              <img src="{{i.productVariantThumbnail}}" class="img-fluid">
            </div>

            <div class="col-8 text-start pl-0">
              <p class="fw-bold" style=" font-size: 13px;">{{i.variantName}}</p>
              <select class="flavour mb-3 w-100" *ngIf="common.cartdetails[j]['flavourList'] != ''"
                [(ngModel)]="i.productFlavour" (change)="selectflavour(i.cartID,$event)">
                <option *ngFor="let fl of common.cartdetails[j]['flavourList'];let in = index"
                  value="{{fl.flavour_name}}">
                  {{fl.flavour_name}}</option>
              </select>

              <p>
                <b>
                  <del>Rs.{{i.variantPrice * i.quantity}}</del>
                  &nbsp;

                  <span class="pricecolor">
                    Rs.{{math.round(i.variantSalePrice * i.quantity) }}
                  </span>
                </b>

                <!-- <b *ngIf="i.discount > 0">
                  <del>Rs.{{i.variantPrice * i.quantity}}</del>
                  &nbsp;
                  <span class="pricecolor">
                    Rs.{{math.round(i.variantSalePrice) }}
                  </span>
                </b> -->
              </p>
              <div class="row align-items-center">
                <div class="col-auto">
                  <div class="input-group">
                    <span class="input-group-btn">
                      <button type="button" class="btn text-black" style="border:1px solid black;"
                        (click)="updateQunatitysubbtn(i.cartID,i.quantity)">
                        <i class="fa fa-minus" aria-hidden="true"></i>
                      </button>
                    </span>

                    <input type="number" class="qunatitytxt" value="{{i.quantity}}" readonly>

                    <span class="input-group-btn">
                      <button type="button" class="btn text-black"
                        style="border:1px solid black;border-radius: 0px 5px 5px 0px;"
                        (click)="updateQunatityaddbtn(i.cartID,i.quantity)">
                        <i class="fa fa-plus" aria-hidden="true"></i>
                      </button>
                    </span>
                  </div>
                </div>
                <div class="col pl-0">
                  <!-- <button class="btn text-black btn-sm removebtn mob  d-none d-sm-block" (click)="cartremove(i.cartID)">REMOVE</button> -->
                  <button class="btn text-black btn-sm removebtn mob" (click)="cartremove(i.cartID)"><i
                      class="fa fa-trash" aria-hidden="true"></i></button>
                </div>
              </div>
              <!-- 🚀 Prime price -->
              <!-- <p class="card-text variantName1 text-center">
                <img src="assets/icons/crown.png" height="25" width="25">
                <strong>Prime Price: ₹{{i.primePrice?i.primePrice*i.quantity:'0'}} </strong>
              </p> -->
              <!-- 🚀 Prime price end-->
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-body bg-white">
            <div class="row text-center">
              <div class="col-12">
                <input type="button" mat-raised-button routerLink="/" class="btncolor" value="CONTINUE SHOPPING" />
              </div>
              <div class="col-12 mt-3">
                <input type="button" mat-raised-button (click)="checkout()" class="mobilebtn btncolor"
                  value="CHECKOUT" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
    </div>

    <div class="row w-100 ml-3 mr-3 mb-2 policyBanner m-0 align-items-center text-center">
      <div class="col-12">
        <h1 class="bannerText m-2 p-2">Additional Offers</h1>
      </div>
    </div>

    <!-- Products -->
    <div class="row pt-3 pb-3 mrg-left-right hover" *ngFor="let id of showcart">
      <div class="col-3">
        <a href="{{commService.currentHost}}/product/{{id.slug}}">
          <img class="prd" *ngIf="id.productVariantPictures && id.productVariantPictures[0]"
            src="{{id.productVariantPictures[0].productVariantThumbnail}}" alt="Card image cap"></a>
      </div>
      <div class="col-4 card-text variantNameIncreaseLine" style="margin-top: 9px; font-size: 11px;">
        <a style="color:#292b2c; text-decoration: none;"
          href="{{commService.currentHost}}/product/{{id.slug}}">{{id.variantName}} </a>
      </div>
      <div class="col-3 card-text" style="margin-top: 9px;"><del
          style="color: black;"><b>₹{{id.variantPrice}}</b></del>&nbsp;
        <b style="color: #d31d28;">₹{{id.variantSalePrice}}</b>
      </div>
      <div class="col-2 mb1"> 
        <!-- <input *ngIf="id.activeVariant" type="button" class="cart" value="Add to Cart"
          title="Add to Cart" (click)="addToCart(id.id)"> -->

          <div class="text-center" *ngIf="id.activeVariant" (click)="addToCart(id)" style="margin-top: 9px; cursor: pointer;">
            <i class="fa fa-cart-plus fa-size cart-icon-hover" aria-hidden="true"></i>
          </div>

          
      </div>
    </div>

    <div class="col-12" style="margin-top: 17px;">
      <div class="card bg_boxshadow" style="height:auto;">
        <div class="card-header bg-white text-left p-4">
          PRICE DETAILS
        </div>
        <table class="table  table-borderless" style="margin:0">

          <tbody>
            <tr>
              <td><b>PRICE </b></td>
              <td><b>Rs. {{totalamount}} </b></td>
            </tr>

            <tr class="tr_border">
              <td> <b>AMOUNT PAYABLE</b></td>
              <td> <b>Rs. {{gettpriceamont}} </b></td>

            </tr>
            <tr *ngIf="yoursaving" class="tr_border">
              <td> <b style="color:green;">COUPON DISCOUNT</b></td>
              <td *ngIf="codtype == 'FLAT'"> <b>Rs. {{coupondiscount}} off</b> </td>
              <td *ngIf="codtype != 'FLAT'"> <b>{{coupondiscount}}% off</b></td>
            </tr>
          </tbody>
        </table>

      </div>

      <div class="card" style="border: none">
        <div class="card-header bg-white text-center " style="padding: 0;">
          <div *ngIf="!yoursaving">
            <input type="text" placeholder="Coupon Code" [(ngModel)]="promocodeget"
              style="text-align: center;margin-top: 20px;padding: 10px;border: 1px solid gray; outline-color: #d31d28;text-transform: uppercase;">

            <input type="button" mat-raised-button (click)="couponcode()" value="APPLY"
              class="btncolor d-block mx-auto mt-4">
          </div>

          <div *ngIf="yoursaving" style="margin-bottom: 1em;">
            <input type="button" mat-raised-button (click)="removeCouponCode()" value="REMOVE"
              class="btncolor d-block mx-auto mt-4">
          </div>

          <h6 class="mt-5">Safe Payment Options & Easy Returns.
            <span class="d-block">100% Authentic Products.</span>
          </h6>

        </div>
      </div>

      <div class="custom-margin-bottom">
        <img class="img-fluid" src="assets/images/Payment-Methods.jpg">
      </div>

    </div>
  </div>
</div>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.9)" size="medium" color="#fff" type="ball-atom">
  <p style="color: white"> Please wait... </p>
</ngx-spinner>