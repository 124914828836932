import { Component, OnInit } from '@angular/core';
import { DomainService } from 'src/app/services/domain.service';
import { HttpHeaders } from '@angular/common/http';
import { CommonService } from 'src/app/services/common.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-intra-workouts',
  templateUrl: './intra-workouts.component.html',
  styleUrls: ['./intra-workouts.component.scss']
})
export class IntraWorkoutsComponent implements OnInit {
  trending1: any;
  httpOptions: { headers: HttpHeaders; };
  constructor(
    public router: Router,
    public common: DomainService,
    public commService: CommonService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    this.spinner.show();
    this.producttrending2();
  }

  //start product trending second 
  producttrending2() {

    var data = 'all_product=1&shop_by_category=1&categoryId=4&subCategoryId=9';
    this.common.productget(data).subscribe(res => {
      // console.log(res);
      this.common.sid_sortby = res['data'];
      this.common.filterbyALl(data, 'brand');
      this.spinner.hide();
    }, err => { 
      this.spinner.hide();
    });

  }
  //end product trending second

  getbrand(get) {
    get.brand.brand_name = get.brand.brand_name.replace(/ /g, "-");
    this.router.navigate([`/product/${get.slug}`]); //${get.brand.brand_name}/
  }

  cardClick(id){    
    if(id){
      document.getElementById(id).style.display = "block";
      setTimeout(() => {
        document.getElementById(id).style.display = "none";
      }, 300);
    }
  }

}
