<title> Login/Register | SOS</title>

<app-header></app-header>

<div class="container-fluid topPadding" (click)="common.closeNav()">
    <div class="row policyBanner m-0 align-items-center text-center">
        <div class="col-12">
            <h1 class="bannerText m-2">Login/Register</h1>
        </div>
    </div>

    <div class="row justify-content-center p-2">
        <!-- 🚀 Mobile Section  -->

        <div class="col-12 mb" style="padding: 35px">
            <h3>LOGIN</h3>
            <form [formGroup]="mobileSection" autocomplete="off">
                <div class="form-group">
                    <label for="email">Mobile *:</label>
                    <input type="text" class="form-control" id="mobile" placeholder="Enter Mobile" name="mobile"
                        formControlName="mobile">
                    <div *ngIf="mobileSection.get('mobile').invalid && (mobileSection.get('mobile').dirty || mobileSection.get('mobile').touched)"
                        class="alert mb-0">
                        <div *ngIf="mobileSection.get('mobile').errors.required">
                            Mobile is required.
                        </div>
                        <div
                            *ngIf="mobileSection.get('mobile').errors.minlength || mobileSection.get('mobile').errors.maxlength || mobileSection.get('mobile').errors.pattern">
                            Please enter valid phone number </div>
                    </div>
                </div>

                <div class="form-group form-check">
                    <input type="button" class="btnloginResgis" value="LOGIN" (click)="mobileLogin()">
                </div>
            </form>
        </div>
    </div>
</div>

<app-footer></app-footer>

<!-- 🚀 OTP Modal PopUp -->

<div class="modal fade" bsModal #mobileOtp="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
    aria-labelledby="dialog-static-name">
    <div class="modal-dialog modal-md modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 id="dialog-static-name" class="modal-title pull-left">OTP</h5>
                <button type="button" class="close pull-right" aria-label="Close" (click)="hideOtpModal()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body text-center">
                <ng-otp-input (onInputChange)="onOtpChange($event)" [config]="config" #ngOtpInput></ng-otp-input>

                <!-- 🚀 This will show up -->
                <div *ngIf="timerlimit == true" style="margin-top: 10px;">
                    <p class="text-grey4 px-3">Your verification code will expire in <span style="color: red;"><span
                                *ngIf="timerCount!=undefined">{{timerCount}}</span> seconds</span></p>
                </div>

                <!-- 🚀 This will show up when Timer over -->
                <div *ngIf="timerlimit == false" style="margin-top: 10px;">
                    <label style="color: blue;float: left;cursor: pointer;" (click)="resendOTP()">Resend OTP</label>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn" style="background-color: #d31d28;color: white;"
                    (click)="submitOtp()">Submit</button>
            </div>
        </div>
    </div>
</div>