<app-header></app-header>

<div class="container-fluid topPadding" (click)="common.closeNav()">
  <div class="row">
    <div class="col-12">
      <div class="box"></div>
    </div>
  </div>
</div>

<app-footer></app-footer>

<ngx-spinner
  bdColor="rgba(0, 0, 0, 0.9)"
  size="medium"
  color="#fff"
  type="ball-atom"
>
  <p style="color: white">please wait while we are redirect to page...</p>
</ngx-spinner>
