import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { DomainService } from "../services/domain.service";

@Component({
  selector: "app-influencer",
  templateUrl: "./influencer.component.html",
  styleUrls: ["./influencer.component.scss"],
})
export class InfluencerComponent implements OnInit {
  slug: any;

  constructor(
    public common: DomainService,
    private spinner: NgxSpinnerService,
    public route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.spinner.show();

    this.route.params.subscribe((routeParams) => {
      this.slug = routeParams.slug;
    });

    this.getRedirectURL(this.slug);
  }

  getRedirectURL(slug) {
    this.common.getRedirectURLForInfluncer(slug).subscribe((res) => {
      if (res) {
        console.log(res["data"]["redirectURL"]);

        window.location.replace(res["data"]["redirectURL"]);
      }
    });
  }
}
