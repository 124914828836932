<title> My Account | SOS</title>

<app-header></app-header>

<div class="container-fluid topPadding" *ngIf="common.userdetails" (click)="common.closeNav()">
    <div class="row policyBanner m-0 align-items-center text-center">
        <div class="col-12">
            <h1 class="bannerText m-2">Hi, {{common.userdetails.name}}</h1>
        </div>
    </div>

    <!-- 🚀 My Account Section -->
    <div class="row pt-5">
        <form [formGroup]="myAccountDetails" autocomplete="off" class="w-100">
            <div class="col-12 d-flex
                    justify-content-center align-items-center sideSection p-2">
                <h1 class="textSection">
                    <span>My Account</span>
                </h1>
            </div>

            <!-- 🚀 Name -->

            <div class="col-12 pt-3 sliderSection">
                <div class="form-group w-100">
                    <label class="control-label">Name *</label>
                    <div class="append-icon">
                        <input type="text" class="inputTagEdit" placeholder="Enter Name" formControlName="name">
                        <div *ngIf="myAccountDetails.get('name').invalid && (myAccountDetails.get('name').dirty || myAccountDetails.get('name').touched)"
                            class="alert mb-0">
                            <div *ngIf="myAccountDetails.get('name').errors.required">
                                Name is required.
                            </div>
                            <div
                                *ngIf="myAccountDetails.get('name').errors.minlength || myAccountDetails.get('name').errors.maxlength || myAccountDetails.get('name').errors.pattern">
                                Please enter valid Name of minimum 3 characters to maximum 50 characters </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- 🚀 Email -->

            <div class="col-12 pt-3 sliderSection">
                <div class="form-group w-100">
                    <label class="control-label">Email *</label>
                    <div class="append-icon">
                        <input type="email" class="inputTagEdit" placeholder="Enter Email" formControlName="email">
                        <div *ngIf="myAccountDetails.get('email').invalid && (myAccountDetails.get('email').dirty || myAccountDetails.get('email').touched)"
                            class="alert mb-0">
                            <div *ngIf="myAccountDetails.get('email').errors.required">
                                Email is required.
                            </div>
                            <div *ngIf="myAccountDetails.get('email').errors.pattern">
                                Please Enter Valid Email ID.
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- 🚀 Mobile -->

            <div class="col-12 pt-3 sliderSection">
                <div class="form-group w-100">
                    <label class="control-label">Mobile *</label>
                    <div class="append-icon">
                        <input class="inputTagEdit" type="text" placeholder="Mobile" formControlName="mobileNumber"
                            readonly>
                    </div>
                </div>
            </div>

            <!-- 🚀 Date of birth -->

            <div class="col-12 pt-3 sliderSection">
                <div class="form-group w-100">
                    <label class="control-label">Date of birth *</label>
                    <div class="append-icon">
                        <input class="inputTagEdit" type="date" placeholder="Dob" formControlName="dob"
                            [max]="getToday()">
                        <div *ngIf="myAccountDetails.get('dob').invalid && (myAccountDetails.get('dob').dirty || myAccountDetails.get('dob').touched)"
                            class="alert mb-0">
                            <div *ngIf="myAccountDetails.get('dob').errors.required">
                                Date of birth is required.
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-12 pt-2 pb-2 sliderSection d-flex justify-content-end">
                <button type="button" class="btn btn-sm" style="background-color: #d31d28;color: white;"
                    (click)="saveOrUpdateMyAccountDetails('myAccount')">
                    Save
                </button>
            </div>
        </form>
    </div>

    <!-- 🚀 Address Section -->
    <div class="row mt-3">
        <div class="col-12 d-flex
                    justify-content-center align-items-center sideSection p-2">
            <h1 class="textSection">
                <span>My Address</span>
            </h1>
        </div>

        <!-- <ng-container *ngIf="!showHideAddressSection">
            <div class="col-12 pt-3 pb-3 sliderSection">
                <a style="color: #e83f53;font-weight:500;cursor: pointer;" (click)="showAddOrEditAddressSection()">
                    {{dynamicLabelForAddress}}
                </a>
            </div>

            <div class="col-12 pt-3 pb-3 sliderSection d-block" *ngFor="let obj of displayAddress">
                <ng-container *ngIf="displayAddress.length > 0">
                    <label class="labelTag"><strong>Address: </strong></label>
                    <br>
                    <label>{{obj.address}}, {{obj.city}}, {{obj.state}} - {{obj.pinCode}}.</label>
                </ng-container>
            </div>
        </ng-container> -->

        <ng-container>
            <div class="col-12">
                <form [formGroup]="myAccountAddress" autocomplete="off" class="w-100">
                    <div class="row">
                        <!-- 🚀 Address -->
                        <div class="col-12 pt-3 sliderSection">
                            <div class="form-group w-100">
                                <label class="control-label">Address *</label>
                                <div class="append-icon">
                                    <textarea class="inputTagEdit" placeholder="Enter Address" rows="2"
                                        formControlName="address"></textarea>
                                    <div *ngIf="myAccountAddress.get('address').invalid && (myAccountAddress.get('address').dirty || myAccountAddress.get('address').touched)"
                                        class="alert mb-0">
                                        <div *ngIf="myAccountAddress.get('address').errors.required">
                                            Address is required.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- 🚀 PIN Code -->
                        <div class="col-6 pt-2 sliderSection">
                            <div class="form-group w-100">
                                <label class="control-label">PIN Code *</label>
                                <div class="append-icon">
                                    <input type="text" class="inputTagEdit" placeholder="Enter PIN Code"
                                        formControlName="pinCode" (blur)="getUserDetailBasedOnPINCode()"
                                        (keypress)="numberOnly($event)" minlength="6" maxlength="6">
                                </div>
                            </div>
                        </div>

                        <!-- 🚀 City -->
                        <div class="col-6 pt-2 sliderSection">
                            <div class="form-group w-100">
                                <label class="control-label">City *</label>
                                <div class="append-icon">
                                    <input type="text" class="inputTagEdit" placeholder="Enter City"
                                        formControlName="city" readonly>
                                </div>
                            </div>
                        </div>

                        <!-- 🚀 State -->
                        <div class="col-6 pt-2 sliderSection">
                            <div class="form-group w-100">
                                <label class="control-label">State *</label>
                                <div class="append-icon">
                                    <input type="text" class="inputTagEdit" placeholder="Enter State"
                                        formControlName="state" readonly>
                                </div>
                            </div>
                        </div>

                        <!-- 🚀 Country -->
                        <div class="col-6 pt-2 sliderSection">
                            <div class="form-group w-100">
                                <label class="control-label">Country *</label>
                                <div class="append-icon">
                                    <input type="text" class="inputTagEdit" placeholder="Enter Country"
                                        formControlName="country" readonly>
                                </div>
                            </div>
                        </div>

                        <div class="col-12 pt-2 pb-2 sliderSection d-flex justify-content-end">
                            <button type="button" class="btn btn-sm" style="background-color: #d31d28;color: white;"
                                (click)="saveOrUpdateMyAccountDetails('MyAddress')">
                                Save
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </ng-container>
    </div>

    <!-- 🚀 My Order Section -->
    <div class="row mt-3">
        <div class="col-12 d-flex
            justify-content-center align-items-center sideSection p-2">
            <h1 class="textSection">
                <span>My Orders</span>
            </h1>
        </div>

        <div class="col-12 sliderSection">
            <div class="row w-100">
                <div class="col-12 pl-4"
                    [ngClass]="orderget.length == 1 || orderget.length == 2 ? 'height-225' : 'height-645'">
                    <div class="row border-bottom pt-3 pb-3 border-dark align-items-center" *ngFor="let i of orderget">
                        <div class="col-12 py-3 pl-1">

                            <label class="labelTag">Order No: #{{i.id}}<br>Order Date: {{i.createdAt | date:
                                'yyyy-MM-dd'}}</label>

                            <br>

                            <label class="labelTag">
                                <a href="https://sourceofsupplements.ithinklogistics.co.in/postship/tracking/{{i.awb_number}}"
                                    target="_blank"> Track Your Order </a>
                            </label>

                            <br>

                            <label class="labelTag">Ordered Products:</label>
                            <ul class="list-group">
                                <!-- productVariant.variantName -->
                                <li class="list-group-item" *ngFor="let j of i.order_products"><span
                                        *ngIf="j.productVariant != null">{{(j.productName) ? j.productName : j.productVariant.variantName}}</span>
                                    - Total
                                    Amount:
                                    {{j.order_product_price}}</li>
                            </ul>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- prime membership info -->
    <!-- <div class="row mt-3" *ngIf="common.userdetails && common.userdetails['isPrimeMember']">
        <div class="col-12 d-flex
            justify-content-center align-items-center sideSection p-2">
            <h1 class="textSection">
                <span>SOS Prime Membership</span>
            </h1>
        </div>

        <div class="col-12">
            <div class="row w-100">
                <div class="col-12 pl-4 pb-4 pt-2">                   
                    <div class="row">
                        <div class="table-responsive">
                            <table class="table table border-0">
                                <thead>
                                    <tr>
                                        <th>
                                            Prime Membership Package
                                        </th>
                                        <th class="text-center">
                                            <span>
                                                <i class="fa fa-long-arrow-right"></i>
                                            </span>
                                        </th>
                                        <th>
                                            <span *ngIf="primeMonth!=null && activeFrom!=null && activeTo!=null">
                                                {{primeMonth?primeMonth:'-'}} Months Membership
                                            </span>
                                        </th>
                                    </tr>

                                    <tr>
                                        <th>
                                            Total Amount
                                        </th>
                                        <th class="text-center">
                                            <span>
                                                <i class="fa fa-long-arrow-right"></i>
                                            </span>
                                        </th>
                                        <th>
                                            <span *ngIf="primePrice!=null">
                                                ₹ {{primePrice?primePrice:'0'}}
                                            </span>
                                        </th>
                                    </tr>

                                    <tr>
                                        <th>
                                            Start Date
                                        </th>
                                        <th class="text-center">
                                            <span>
                                                <i class="fa fa-long-arrow-right"></i>
                                            </span>
                                        </th>
                                        <th>
                                            <span *ngIf="primeMonth!=null && activeFrom!=null && activeTo!=null">
                                                {{activeFrom | date: 'dd/MM/yyyy'}}
                                            </span>
                                        </th>
                                    </tr>

                                    <tr>
                                        <th>
                                            End Date
                                        </th>
                                        <th class="text-center">
                                            <span>
                                                <i class="fa fa-long-arrow-right"></i>
                                            </span>
                                        </th>
                                        <th>
                                            <span *ngIf="primeMonth!=null && activeFrom!=null && activeTo!=null">
                                                {{activeTo | date: 'dd/MM/yyyy' }}
                                            </span>
                                        </th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
</div>

<app-footer></app-footer>